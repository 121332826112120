import { useEffect, useState } from 'react';
import Sheet from 'react-modal-sheet';
import SheetClose from './SheetClose';
import { Category } from '../types/types';
import CategoryCheckboxWithImage from './CategoryCheckboxWithImage';
import Button from './Button';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { callPublicAPI } from '../utils/api';
import { QUERY_KEYS } from '../constants/queryKeys';

interface Props {
  categories: Category[];
  selectedCategoryIds: string[];
  setShowCategoryList: React.Dispatch<React.SetStateAction<boolean>>;
}

const UserSelectedCategoryListModal: React.FC<Props> = ({ categories, selectedCategoryIds, setShowCategoryList }) => {
  const queryClient = useQueryClient();
  const [categoriesMarkedSelected, setCategoriesMarkedSelected] = useState<string[]>(selectedCategoryIds);
  const [isPopupActive, setIsPopupActive] = useState(false);

  const saveSelectedCategories = useMutation({
    mutationFn: async () => {
      const response = await callPublicAPI<string[]>(
        'POST',
        '/user-selected-categories',
        null,
        categoriesMarkedSelected
      );
      return response;
    },
    onSuccess: () => {
      queryClient.setQueryData([QUERY_KEYS.GET_USER_SELECTED_CATEGORY_IDS], categoriesMarkedSelected);
      setShowCategoryList(false);
    }
  });

  const handleCategoryClick = (category: Category) => {
    if (categoriesMarkedSelected.includes(category.id)) {
      setCategoriesMarkedSelected(categoriesMarkedSelected.filter(categoryId => categoryId !== category.id));
    } else {
      setCategoriesMarkedSelected([...categoriesMarkedSelected, category.id]);
    }
  };

  const closeModal = () => {
    setShowCategoryList(false);
  };

  useEffect(() => {
    setIsPopupActive(true);
  }, []);

  return (
    <Sheet isOpen={isPopupActive} onClose={closeModal} className="offer-overlay">
      <Sheet.Container>
        <Sheet.Header />
        <Sheet.Content>
          <>
            <SheetClose closeModal={closeModal} />
            <Sheet.Scroller>
              <section className="pb-3" onClick={e => e.stopPropagation()}>
                <h3 className="ps-3 pe-3 mb-4">Kategorije</h3>
                <ul>
                  {categories.map(category => (
                    <CategoryCheckboxWithImage
                      key={category.id}
                      category={category}
                      categoriesMarkedSelected={categoriesMarkedSelected}
                      handleCategoryClick={handleCategoryClick}
                    />
                  ))}
                </ul>
                <Button className="mt-4" onClick={() => saveSelectedCategories.mutate()}>
                  Sačuvaj
                </Button>
              </section>
            </Sheet.Scroller>
          </>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop onTap={closeModal} />
    </Sheet>
  );
};

export default UserSelectedCategoryListModal;
