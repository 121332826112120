import { useNavigate } from 'react-router-dom';
import {
  authorizeWithJWEToken,
  persistAuthorizationDataToLocalStorage,
  removeUserDataFromLocalStorage
} from '../utils/auth';
import GlobalLoadingIndicator from './GlobalLoadingIndicator';

interface Props {
  jweToken: string;
  setShouldAuthorize: React.Dispatch<React.SetStateAction<boolean>>;
}

const AuthorizeWithJWEToken: React.FC<Props> = ({ jweToken, setShouldAuthorize }) => {
  const navigate = useNavigate();

  authorizeWithJWEToken(jweToken)
    .then(response => {
      removeUserDataFromLocalStorage();
      persistAuthorizationDataToLocalStorage(response.data);
    })
    .catch(_ => {
      navigate('/greska-tokom-sso-procesa');
    })
    .finally(() => {
      setShouldAuthorize(true);
    });

  return <GlobalLoadingIndicator />;
};

export default AuthorizeWithJWEToken;
