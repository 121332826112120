import { useEffect, useState } from "react";
import Button from "./Button";
import PageTitle from "./PageTitle";
import { useMutation } from "@tanstack/react-query";
import { callPublicAPI } from "../utils/api";
import { useNavigate } from "react-router-dom";
import "../scss/textarea.scss";

const SendSuggestions = () => {
  const navigate = useNavigate();
  const [feedback, setFeedback] = useState("");
  const [showError, setshowError] = useState(false);

  const sendFeedback = useMutation({
    mutationFn: async () => {
      const data = { feedback };
      const response = await callPublicAPI<undefined>("POST", "/user-feedback", null, data);
      return response;
    },
    onSuccess: () => {
      navigate("/profil/sugestije/poslato", { replace: true });
    },
    onError: () => {
      alert("Došlo je do greške prilikom slanja sugestije. Molimo Vas pokušajte ponovo kasnije.");
    }
  });

  const handleSendSuggestion = () => {
    if(feedback.length < 10) {
      setshowError(true);
      return;
    }
    
    sendFeedback.mutate();
  }

  useEffect(() => {
    if(showError && feedback.length >= 10) {
      setshowError(false);
    }
  }, [showError, feedback]);

  return (
    <>
      <PageTitle className="mb-4">Pošalji sugestiju</PageTitle>
      <p className="mb-2">Možeš nam poslati sugestiju za unapređenje Yettel Shopping aplikacije ili predložiti partnere čije popuste bi želeo/la da vidiš. Svaki komentar nam znači, hvala.</p>
      <textarea className={`textarea ${showError ? "textarea--error" : ""} mt-2 mb-2 pt-2 pb-2 ps-2 pe-2`} rows={10} value={feedback} onChange={(e) => setFeedback(e.target.value)}></textarea>
      {showError && <p className="fz-14 textarea-error">Molimo Vas da unesete sugestiju od barem 10 karaktera.</p>}
      <Button onClick={handleSendSuggestion}>Pošalji</Button>
    </>
  )
}

export default SendSuggestions;