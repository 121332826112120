import "../scss/offer-discount-label-content.scss";

interface Props {
  originalPrice: number;
  discountedPrice: number;
}

const addDotsToNumber = (number: number): string => {
  return new Intl.NumberFormat('de-DE', { style: 'decimal', minimumFractionDigits: 0 }).format(number);
}

const OfferDiscountLabelContent: React.FC<Props> = ({
  originalPrice,
  discountedPrice
}) => {
  return (
    <article className="offer-discount-label-content text-end pt-1 pb-1 ps-3 pe-2">
      <span className="offer-discount-label-content__original-price d-block">{addDotsToNumber(originalPrice)} RSD</span>
      <span className="d-block fz-18 font-yettel-light">{addDotsToNumber(discountedPrice)} RSD</span>
    </article>
  )
}

export default OfferDiscountLabelContent;