import "../scss/offer-section.scss";

interface Props {
  title?: React.ReactNode;
  className?: string;
  children: React.ReactNode;
}

const OfferSection: React.FC<Props> = ({ title = "", className, children }) => {
  return (
    <section className={["mb-4", className].join(" ")}>
      {Boolean(title) && <div className="offer-section__title font-yettel-bold mb-3">{title}</div>}
      <div className={title ? "offer-section__content-with-title" : ""}>{children}</div>
    </section>
  )
}

export default OfferSection;
