import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PopupOverlay from './PopupOverlay';
import PopupContainer from './PopupContainer';
import MapPartnerContent from './MapPartnerContent';
import '../scss/single-offer-map-popup.scss';

const MapPartnerIdOverlay = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isPopupActive, setIsPopupActive] = useState(false);
  const [isSingleOfferDisplayed, setIsSingleOfferDisplayed] = useState(false);

  const handlePopupClosure = () => {
    setIsPopupActive(false);
    setTimeout(() => {
      const urlParams = searchParams.toString();
      navigate(`/near-by?${urlParams}`, { replace: true });
    }, 300);
  };

  useEffect(() => {
    setIsPopupActive(true);
  }, []);

  return (
    <PopupOverlay
      onClick={handlePopupClosure}
      className={isSingleOfferDisplayed ? 'single-offer-map-popup' : undefined}
    >
      <PopupContainer
        contentStyle={{ height: 470, transform: 'translate3d(0, 300px, 0)' }}
        isActive={isPopupActive}
        setIsActive={setIsPopupActive}
        handlePopupClosure={handlePopupClosure}
      >
        <MapPartnerContent setIsSingleOfferDisplayed={setIsSingleOfferDisplayed} />
      </PopupContainer>
    </PopupOverlay>
  );
};

export default MapPartnerIdOverlay;
