import { PointerEvent, useEffect, useRef, useState } from 'react';
import Sheet from 'react-modal-sheet';
import YettelPetakScratchCard from './YettelPetakScratchCard';
import header from '../assets/yettelPetak/header-grebalica.png';
import grebalica_pozadina from '../assets/yettelPetak/background-grebalica.png';
import grebalica from '../assets/yettelPetak/grebalica.jpg';
import paper from '../assets/sounds/paper.mp3';
import ding from '../assets/sounds/ding.mp3';
import paper_short from '../assets/sounds/paper-short.mp3';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ScratchOffer, WinTypeYettelPetak, YSH_GTM_EVENTS } from '../types/types';
import { callPublicAPI } from '../utils/api';
import { getPrizeValue, isStringPrizeType } from '../constants/prizes';
import { throttle } from 'lodash';
import YettelPetakScratchAward from './YettelPetakScratchAward';
import { getCurrentYear } from '../utils/util';
import { pushToAnalytics } from '../utils/analytics';
import close from '../assets/images/icons/icon-close-green.png';
import { QUERY_KEYS } from '../constants/queryKeys';
import '../scss/yettel-petak-scratch-card.scss';

interface Props {
  scratchOffer: ScratchOffer;
  isModalActive: boolean;
  setIsModalActive: (value: boolean) => void;
}

const YettelPetakScratchCardModal: React.FC<Props> = ({ scratchOffer, isModalActive, setIsModalActive }) => {
  const queryClient = useQueryClient();

  const SCRATCH_WIDTH = 345;
  const SCRATCH_HEIGHT = 423;

  const [isPopupCongratulationOpen, setIsPopupCongratulationOpen] = useState(false);

  let timestamp = 0;
  let mY = 0;

  const [done, setDone] = useState(false);

  const ref = useRef<YettelPetakScratchCard>(null);
  const headerRef = useRef<HTMLImageElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const footerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if(isModalActive) {
      pushToAnalytics(YSH_GTM_EVENTS.SCRATCH_AND_WIN_OPEN, {});
    }
  }, [isModalActive]);

  const value = scratchOffer
    ? isStringPrizeType(scratchOffer!.renderType)
      ? getPrizeValue(scratchOffer!.renderType, scratchOffer!.category, scratchOffer!.value)
      : scratchOffer?.value
    : '';

  function playSound(url: string) {
    const ourAudio = document.createElement('audio'); // Create a audio element using the DOM
    ourAudio.style.display = 'none'; // Hide the audio element
    ourAudio.src = url; // Set resource to our URL
    ourAudio.autoplay = true; // Automatically play sound
    ourAudio.onended = function () {
      // @ts-expect-error we assume that this is possible and the remove function does exist.
      this.remove(); // Remove when played.
    };
    document.body.appendChild(ourAudio);
  }
  const playShort = throttle((event: PointerEvent<HTMLDivElement>, speed: number) => {
    if (!done && event.buttons && speed > 100) {
      playSound(paper_short);
    }
  }, 300);

  const scratchOfferPost = useMutation({
    mutationFn: async () => {
      const data = { id: scratchOffer!.id };
      const response = await callPublicAPI<{ data: WinTypeYettelPetak }>('POST', '/scratch-offers', null, data);

      return response.data;
    },
    onSuccess: () => {
      setIsPopupCongratulationOpen(true);
      pushToAnalytics(YSH_GTM_EVENTS.SCRATCH_AND_WIN_DONE, {});
      setQueryAsScratched();
    },
    onError: () => {
      alert('Izvinjavamo se, došlo je do greške, naš tim radi na tome.');
    }
  });

  const closePopup = () => {
    setIsModalActive(false);
  };

  const setQueryAsScratched = async () => {
    await queryClient.invalidateQueries({
      predicate: query => query.queryKey[0] === QUERY_KEYS.GET_SCRATCH_OFFERS
    });
  };

  return (
    <>
      <Sheet isOpen={isModalActive} onClose={closePopup} className="scratch-card-modal-container" disableDrag={true}>
        <Sheet.Container>
          <Sheet.Content>
            <section className="scratch-card-modal">
              <div className="content" ref={containerRef}>
                <img
                  className="close cursor-pointer"
                  width={25}
                  height={25}
                  src={close}
                  alt="close modal"
                  onClick={closePopup}
                />
                <img ref={headerRef} src={header} className="header" alt="scratch" />
                <div
                  onPointerMove={event => {
                    const now = Date.now();
                    const currentY = event.screenY;

                    const dt = now - timestamp;
                    const distance = Math.abs(currentY - mY);
                    const speed = Math.round((distance / dt) * 1000);

                    mY = currentY;
                    timestamp = now;
                    playShort(event, speed);
                  }}
                >
                  <YettelPetakScratchCard
                    width={SCRATCH_WIDTH}
                    height={SCRATCH_HEIGHT}
                    ref={ref}
                    image={grebalica}
                    finishPercent={40}
                    brushSize={40}
                    fadeOutOnComplete={true}
                    onComplete={() => {
                      setDone(true);
                      playSound(paper);
                      setTimeout(() => {
                        playSound(ding);
                      }, 1000);
                      scratchOfferPost.mutate();
                    }}
                  />

                  <>
                    <div className="price">
                      <img
                        alt="scratch card background"
                        src={grebalica_pozadina}
                        style={{
                          width: SCRATCH_WIDTH,
                          height: SCRATCH_HEIGHT,
                          position: 'absolute',
                          top: 118,
                          left: 15,
                          zIndex: 0
                        }}
                      />
                      <h3 className="font-yettel-bold">{value}</h3>
                    </div>
                  </>

                  <YettelPetakScratchAward
                    isPopupOpen={isPopupCongratulationOpen}
                    setPopupOpen={setIsPopupCongratulationOpen}
                    text={scratchOffer?.value ?? ''}
                  />
                </div>
              </div>
              <div ref={footerRef} className="footer">
                <p className="ps-2 pe-2 pt-2 ps-2 pe-2 fz-14 font-yettel-light">
                  Yettel petak je dostupan svakog petka u periodu od 01.01.{getCurrentYear()}. do 31.12.
                  {getCurrentYear()}. izmedju 08:00 - 23:59h. Za vise detalja o uslovima promocije posetite{' '}
                  <a className="text-decoration-underline" href="https://www.yettel.rs">
                    www.yettel.rs
                  </a>
                </p>
              </div>
            </section>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={closePopup} />
      </Sheet>
    </>
  );
};

export default YettelPetakScratchCardModal;
