import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useFetchOnVerticalScroll } from '../hooks/useFetchOnVerticalScroll';
import { useInfiniteFilteredOffers } from '../hooks/useInfiniteFilteredOffers';
import { ApiResponse, Offer } from '../types/types';
import { isUserLoggedIn } from '../utils/auth';
import ErrorMessage from './ErrorMessage';
import FilterListContainer from './FilterListContainer';
import FilterSection from './FilterSection';
import OfferDisplayWide from './OfferDisplayWide';
import OfferGrid from './OfferGrid';
import { SEARCH_PARAMS } from '../constants/searchParamNames';
import '../scss/offers-with-filters.scss';

const OffersPage = () => {
  const isLoggedIn = isUserLoggedIn();

  const [searchParams] = useSearchParams();

  const [isFilterContainerActive, setIsFilterContainerActive] = useState(false);
  const [totalNumber, setTotalNumber] = useState(0);

  const searchParamsForOffers = new URLSearchParams(searchParams.toString());
  searchParamsForOffers.delete(SEARCH_PARAMS.OFFER_ID);
  searchParamsForOffers.delete(SEARCH_PARAMS.SHOW_CLOSEST_STORES_MAP);
  searchParamsForOffers.delete(SEARCH_PARAMS.SHOW_SHARE_OPTIONS);

  const { data, fetchNextPage, isFetching, hasNextPage, error } = useInfiniteFilteredOffers(
    searchParamsForOffers,
    setTotalNumber
  );

  useFetchOnVerticalScroll<ApiResponse<Offer[]>>(fetchNextPage, isFetching, hasNextPage);

  return (
    <>
      {error && <ErrorMessage>{error.message}</ErrorMessage>}

      <FilterSection totalNumber={totalNumber} setIsPopupOpen={setIsFilterContainerActive} />

      <FilterListContainer
        totalNumber={totalNumber}
        isPopupOpen={isFilterContainerActive}
        setIsPopupOpen={setIsFilterContainerActive}
      />

      {data && data.pages.length > 0 && (
        <section className="full-width mb-4">
          <OfferGrid>
            {data.pages.map(
              page =>
                Boolean(page.data.length) &&
                page.data.map(offer => (
                  <OfferDisplayWide key={offer.id} offer={offer} isUserLoggedIn={isLoggedIn} displayedIn="Ponude" />
                ))
            )}
          </OfferGrid>
        </section>
      )}
    </>
  );
};

export default OffersPage;
