import { DAILY_PRIZE_MODAL_VISIBLE, END_OF_GAME_MODAL_VISIBLE, GAME_RESULTS_MODAL_VISIBLE } from '../constants/config';
import DailyPrizeModal from './DailyPrizeModal';
import { GameResultsModal } from './GameResultsModal';
import { EndOfGameModal } from './EndOfGameModal';
import YettelPetakWizardModal from './YettelPetakWizardModal';
import { useSearchParams } from 'react-router-dom';
import { SEARCH_PARAMS } from '../constants/searchParamNames';

const Modals = () => {
  const [searchParams] = useSearchParams();
  const jweToken = searchParams.get(SEARCH_PARAMS.TOKEN);

  if (jweToken) return null;

  const DailyPrizeModalVisible = DAILY_PRIZE_MODAL_VISIBLE === 'true';
  const EndOfGameModalVisible = END_OF_GAME_MODAL_VISIBLE === 'true';
  const GameResultsModalVisible = GAME_RESULTS_MODAL_VISIBLE === 'true';

  return (
    <>
      {DailyPrizeModalVisible && <DailyPrizeModal />}
      {EndOfGameModalVisible && <EndOfGameModal />}
      {GameResultsModalVisible && <GameResultsModal />}
      <YettelPetakWizardModal />
    </>
  );
};
export default Modals;
