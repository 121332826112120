import { useEffect } from 'react';

const FakeThrowError = () => {
  const throwError = () => {
    throw new Error('this is an error test');
  };

  useEffect(() => {
    throwError();
  }, []);

  return <></>;
};

export default FakeThrowError;
