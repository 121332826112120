import { MarkerEnums } from "./googleMapMarkers";

import Kancelarija from "../assets/maps/pins/categoryVisitedScaledPins/Kancelarija.png";
import TehnickaRoba from "../assets/maps/pins/categoryVisitedScaledPins/Tehnička roba.png";
import AutoMoto from "../assets/maps/pins/categoryVisitedScaledPins/Auto moto.png";
import DecijiSvet from "../assets/maps/pins/categoryVisitedScaledPins/Dečiji svet.png";
import HranaIPice from "../assets/maps/pins/categoryVisitedScaledPins/Hrana i piće.png";
import Knjizara from "../assets/maps/pins/categoryVisitedScaledPins/Knjižara.png";
import KucniLjubimci from "../assets/maps/pins/categoryVisitedScaledPins/Kućni ljubimci.png";
import LepotaINega from "../assets/maps/pins/categoryVisitedScaledPins/Lepota i nega.png";
import LepotaIZdravlje from "../assets/maps/pins/categoryVisitedScaledPins/Lepota i zdravlje.png";
import Moda from "../assets/maps/pins/categoryVisitedScaledPins/Moda.png";
import Obrazovanje from "../assets/maps/pins/categoryVisitedScaledPins/Obrazovanje.png";
import Sezona from "../assets/maps/pins/categoryVisitedScaledPins/Sezona.png";
import SportIRekreacija from "../assets/maps/pins/categoryVisitedScaledPins/Sport i rekreacija.png";
import SveZaKucu from "../assets/maps/pins/categoryVisitedScaledPins/Sve za kuću.png";
import Turizam from "../assets/maps/pins/categoryVisitedScaledPins/Turizam.png";
import Usluge from "../assets/maps/pins/categoryVisitedScaledPins/Usluge.png";
import Yettel from "../assets/maps/pins/categoryVisitedScaledPins/Yettel.png";
import ZabavaIKarte from "../assets/maps/pins/categoryVisitedScaledPins/Zabava i karte.png";
import Zdravlje from "../assets/maps/pins/categoryVisitedScaledPins/Zdravlje.png";

export const VisitedMarkerEnums: typeof MarkerEnums = {
  'Auto-moto': AutoMoto,
  'Dečiji svet': DecijiSvet,
  'Hrana i piće': HranaIPice,
  'Kancelarija': Kancelarija,
  'Knjižara': Knjizara,
  'Kućni ljubimci': KucniLjubimci,
  'Lepota i nega': LepotaINega,
  'Lepota i zdravlje': LepotaIZdravlje,
  'Moda': Moda,
  'Obrazovanje': Obrazovanje,
  'Obrazovanje i knjige': Obrazovanje,
  'Sezona': Sezona,
  'Sport i rekreacija': SportIRekreacija,
  'Sve za kuću': SveZaKucu,
  'Tehnička roba': TehnickaRoba,
  'Turizam': Turizam,
  'Usluge': Usluge,
  'Yettel': Yettel,
  'Zabava i karte': ZabavaIKarte,
  'Zdravlje': Zdravlje,
};
