import { Link, useNavigate } from 'react-router-dom';
import { useFetchCategories } from '../hooks/useFetchCategories';
import { CATEGORY_IMAGE_SOURCES } from '../constants/categories';
import { useState } from 'react';
import { Category, Offer } from '../types/types';
import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '../constants/queryKeys';
import { callPublicAPI } from '../utils/api';
import Button from './Button';
import CheckboxChecked from '../assets/checkbox_checked.png';
import CheckboxUnchecked from '../assets/checkbox_unchecked.png';
import '../scss/category-list.scss';

const CategoryList = () => {
  const { data: categoryList } = useFetchCategories();
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [totalNumberOfOffers, setTotalNumberOfOffers] = useState(0);
  const selectedCategoriesQueryParams = selectedCategories.join(',');
  const navigate = useNavigate();

  const { refetch } = useQuery<Offer[]>({
    queryKey: [QUERY_KEYS.GET_OFFERS, selectedCategoriesQueryParams],
    queryFn: async () => {
      const requestSearchParams = new URLSearchParams({
        category: selectedCategoriesQueryParams
      });

      const response = await callPublicAPI<Offer[]>('GET', '/offers/filter', requestSearchParams);
      if (selectedCategories.length > 0) {
        setTotalNumberOfOffers(response?.meta?.total || 0);
      }
      return response.data;
    }
  });

  const handleClickShowOffers = () => {
    const redirectToPath = `/ponude?category=${selectedCategoriesQueryParams}`;
    navigate(redirectToPath);
  };

  const handleClick = (category: Category) => {
    let updatedSelectedCategories = [];
    if (selectedCategories.includes(category.id)) {
      updatedSelectedCategories = selectedCategories.filter(categoryId => categoryId !== category.id);
      setSelectedCategories(updatedSelectedCategories);
    } else {
      updatedSelectedCategories = [...selectedCategories, category.id];
      setSelectedCategories(updatedSelectedCategories);
    }
    if (updatedSelectedCategories.length > 0) {
      refetch();
    } else {
      setTotalNumberOfOffers(0);
    }
  };

  return (
    <>
      <div className="category-list mt-5">
        {categoryList?.map(category => (
          <div key={category.id} onClick={() => handleClick(category)} className="category-switch d-flex ">
            <div className="category-user-selected__title d-flex align-items-center">
              {category?.icon && <img className="category-icon" src={CATEGORY_IMAGE_SOURCES[category.icon]} />}
              <span className="ps-2">{`${category.name} (${category.ad_count})`}</span>
            </div>
            <img
              className="category-checkbox"
              alt="checkmark"
              src={selectedCategories?.includes(category.id) ? CheckboxChecked : CheckboxUnchecked}
            />
          </div>
        ))}
        <Link to={'/ponude'}>
          <div className="category-switch d-flex align-items-center">
            <img className="category-icon" src={CATEGORY_IMAGE_SOURCES['sve-kategorije']} />
            <span className="ps-2">Sve kategorije</span>
          </div>
        </Link>
      </div>
      <div className="d-flex justify-content-center full-width mt-5 mb-5">
        <Button onClick={handleClickShowOffers}>{`Prikaži ponude (${totalNumberOfOffers})`}</Button>
      </div>
    </>
  );
};

export default CategoryList;
