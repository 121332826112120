import { Outlet, createBrowserRouter } from 'react-router-dom';
import { APP_BASE } from './constants/config';
import Layout from './components/Layout';
import HomePage from './components/HomePage';
import OfferOverlayGroup from './components/OfferOverlayGroup';
import LoginPromptOverlay from './components/LoginPromptOverlay';
import SearchOverlay from './components/SearchOverlay';
import ShoppingMallPage from './components/ShoppingMallPage';
import SearchResultsPage from './components/SearchResultsPage';
import OffersPage from './components/OffersPage';
import MapPage from './components/MapPage';
import MapCategoryPickerOverlay from './components/MapCategoryPickerOverlay';
import MapPartnerIdOverlay from './components/MapPartnerIdOverlay';
import PartnerOfferOverlay from './components/PartnerOfferOverlay';
import CouponsPage from './components/CouponsPage';
import YettelPetak from './components/YettelPetak';
import YettelPetakHistory from './components/YettelPetakHistory';
import ProfilePage from './components/ProfilePage';
import ProfileSettings from './components/ProfileSettings';
import SendSuggestions from './components/SendSuggestions';
import SuggestionSent from './components/SuggestionSent';
import PrivacySettings from './components/PrivacySettings';
import LoginRedirectPage from './components/LoginRedirectPage';
import MapCategoryAndPartnerPickerOverlay from './components/MapCategoryAndPartnerPickerOverlay';
import ErrorPageContent from './components/ErrorPageContent';
import FakeThrowError from './components/FakeThrowError';

const router = createBrowserRouter(
  [
    {
      path: '/',
      element: (
        <>
          <Layout />
          <SearchOverlay />
        </>
      ),
      errorElement: (
        <Layout>
          <ErrorPageContent />
        </Layout>
      ),
      children: [
        {
          path: '/',
          element: (
            <>
              <Outlet />
              <OfferOverlayGroup />
              <LoginPromptOverlay />
            </>
          ),
          errorElement: <ErrorPageContent />,
          children: [
            {
              path: '/',
              element: <HomePage />
            },
            {
              path: '/shopping-mall/:shoppingMallId',
              element: <ShoppingMallPage />
            },
            {
              path: '/pretraga/:searchTerm',
              element: <SearchResultsPage />
            },
            {
              path: '/ponude',
              element: <OffersPage />
            }
          ]
        },
        {
          path: '/near-by',
          errorElement: <ErrorPageContent />,
          element: (
            <>
              <MapPage />
              <OfferOverlayGroup />

              <Outlet />
            </>
          ),
          children: [
            {
              path: '/near-by/categories',
              element: <MapCategoryPickerOverlay />
            },
            {
              path: '/near-by/filters',
              element: <MapCategoryAndPartnerPickerOverlay />
            },
            {
              path: '/near-by/partner/:partnerId',
              element: <Outlet />,
              children: [
                {
                  path: '/near-by/partner/:partnerId',
                  element: (
                    <>
                      <MapPartnerIdOverlay />
                      <Outlet />
                    </>
                  ),
                  children: [
                    {
                      path: '/near-by/partner/:partnerId/offer/:offerId',
                      element: <PartnerOfferOverlay />
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          path: '/kuponi',
          errorElement: <ErrorPageContent />,
          element: <CouponsPage />
        },
        {
          path: '/yettel-petak',
          errorElement: <ErrorPageContent />,
          element: <YettelPetak />
        },
        {
          path: '/pregled-dobitaka',
          errorElement: <ErrorPageContent />,
          element: <YettelPetakHistory />
        },
        {
          path: '/profil',
          errorElement: <ErrorPageContent />,
          element: <Outlet />,
          children: [
            {
              path: '/profil',
              element: <ProfilePage />
            },
            {
              path: '/profil/podesavanja',
              element: <ProfileSettings />
            },
            {
              path: '/profil/sugestije',
              element: <Outlet />,
              children: [
                {
                  path: '/profil/sugestije',
                  element: <SendSuggestions />
                },
                {
                  path: '/profil/sugestije/poslato',
                  element: <SuggestionSent />
                }
              ]
            },
            {
              path: '/profil/privatnost',
              element: <PrivacySettings />
            }
          ]
        },
        {
          path: '/login-redirect',
          errorElement: <ErrorPageContent />,
          element: <LoginRedirectPage />
        },
        {
          path: '/greska-prilikom-autorizacije',
          element: (
            <ErrorPageContent
              customMessage="Nismo uspeli da proverimo tvoje podatke. Molimo te da pokušaš da se uloguješ ponovo."
              showLoginButton={true}
            />
          )
        },
        {
          path: '/greska-tokom-sso-procesa',
          element: (
            <ErrorPageContent
              customMessage="Nismo uspeli da te automatski ulogujemo na Yettel Shopping aplikaciji. Molimo te da se sam uloguješ, klikom na dugme Prijavi se."
              showLoginButton={true}
            />
          )
        },
        {
          path: '/t3st1ranje',
          element: <FakeThrowError />,
          errorElement: <ErrorPageContent />,
        },
        {
          path: '*',
          element: <ErrorPageContent />
        }
      ]
    }
  ],
  {
    basename: APP_BASE
  }
);

export default router;
