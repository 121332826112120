import { useSearchParams } from 'react-router-dom';
import viber from '../assets/share/share_viber.png';
import facebook from '../assets/share/share_facebook.png';
import whatsapp from '../assets/share/share_whatsapp.png';
import linkedin from '../assets/share/share_linkedin.png';
import telegram from '../assets/share/share_telegram.png';
import arrow from '../assets/category-arrow.svg';
import copy from '../assets/copy.png';
import Button from './Button';
import { MouseEventHandler, useState } from 'react';
import { SEARCH_PARAMS } from '../constants/searchParamNames';
import { QUERY_KEYS } from '../constants/queryKeys';
import { useQuery } from '@tanstack/react-query';
import { ShareOption, SingleOffer, YSH_GTM_EVENTS } from '../types/types';
import { callPublicAPI } from '../utils/api';
import { mapSingleOfferToAnalyticsObject, pushToAnalytics } from '../utils/analytics';
import LoadingIndicator from './LoadingIndicator';

interface Props {
  handlePopupClosure: () => void;
}

const ShareOfferOverlayContent: React.FC<Props> = ({ handlePopupClosure }) => {
  const [searchParams] = useSearchParams();
  const offerId = searchParams.get(SEARCH_PARAMS.OFFER_ID) || '';

  const { data: offer } = useQuery<SingleOffer>({
    queryKey: [QUERY_KEYS.GET_OFFER, offerId],
    queryFn: async () => {
      const response = await callPublicAPI<SingleOffer>('GET', `/offers/${offerId}`);
      return response.data;
    }
  });

  const pushShareOfferToAnalytics = (type: ShareOption) => {
    if (offer) {
      pushToAnalytics(YSH_GTM_EVENTS.SHARE_COUPON, {
        coupon_details: [
          {
            ...mapSingleOfferToAnalyticsObject(offer, 'Coupon details'),
            coupon_share_option_selected: type
          }
        ]
      });
    }
  };

  return (
    <>
      <h3>Podeli preko:</h3>

      <section className="mt-3 mb-3">
        <SocialLink
          handleAnalytics={pushShareOfferToAnalytics}
          href={`viber://forward?text=Vidi%20ovu%20sjajnu%20ponudu%3A%20https%3A%2F%2Fshopping.yettel.rs%2F%3FofferId%3D${offerId}`}
          image={viber}
          title="Viber"
        />
        <SocialLink
          handleAnalytics={pushShareOfferToAnalytics}
          href={`https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fshopping.yettel.rs%2F%3FofferId%3D${offerId}`}
          image={facebook}
          title="Facebook"
        />
        <SocialLink
          handleAnalytics={pushShareOfferToAnalytics}
          href={`whatsapp://send?text=Vidi%20ovu%20sjajnu%20ponudu%3A%20https%3A%2F%2Fshopping.yettel.rs%2F%3FofferId%3D${offerId}`}
          image={whatsapp}
          title="WhatsApp"
        />
        <SocialLink
          handleAnalytics={pushShareOfferToAnalytics}
          href={`https://www.linkedin.com/shareArticle?mini=true&url=https%3A%2F%2Fshopping.yettel.rs%2F%3FofferId%3D${offerId}&title=&summary=&source=`}
          image={linkedin}
          title="LinkedIn"
        />
        <SocialLink
          handleAnalytics={pushShareOfferToAnalytics}
          href={`https://telegram.me/share/url?url=https%3A%2F%2Fshopping.yettel.rs%2F%3FofferId%3D${offerId}&text=Vidi%20ovu%20sjajnu%20ponudu%3A`}
          image={telegram}
          title="Telegram"
        />
      </section>

      <section>
        <CopyToClipboard handleAnalytics={() => pushShareOfferToAnalytics('Copy link')} offerId={offerId} />
      </section>

      <Button onClick={handlePopupClosure}>Nazad na ponudu</Button>
    </>
  );
};

export default ShareOfferOverlayContent;

interface SocialMediaProps {
  href: string;
  image: string;
  title: ShareOption;
  handleAnalytics: (type: ShareOption) => void;
}

const SocialLink: React.FC<SocialMediaProps> = ({ href, image, title, handleAnalytics }) => {
  const handleClick = () => {
    handleAnalytics(title);
  };

  return (
    <>
      <a
        onClick={handleClick}
        href={href}
        target="blank"
        className="d-flex justify-content-between align-items-center pb-2 mt-2"
      >
        <img src={image} alt="social media link" width={26} height={26} />
        <span className="ps-2" style={{ flex: 1 }}>
          {title}
        </span>
        <img src={arrow} alt="arrow" width={18} height={18} />
      </a>
      <hr />
    </>
  );
};

interface CopyToClipboardProps {
  offerId: string;
  handleAnalytics: () => void;
}

const CopyToClipboard: React.FC<CopyToClipboardProps> = ({ offerId, handleAnalytics }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccessText, setShowSuccessText] = useState(false);
  const url = `https://shopping.yettel.rs/?offerId=${offerId}`;

  const copyToClipboard: MouseEventHandler<HTMLButtonElement> = e => {
    e.preventDefault();
    navigator.clipboard.writeText(url);
    handleAnalytics();
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      setShowSuccessText(true);
      setTimeout(() => {
        setShowSuccessText(false);
      }, 1000);
    }, 300);
  };

  return (
    <>
      {isLoading && (
        <div className="d-flex justify-content-center">
          <LoadingIndicator />
        </div>
      )}

      {!isLoading && !showSuccessText && (
        <button
          className="mt-1 mb-4 d-flex justify-content-between align-items-center cursor-pointer"
          onClick={copyToClipboard}
        >
          <span className="fz-14 font-yettel-bold pe-2 clamp-1">{url}</span>
          <img alt="copy text to clipboard" src={copy} width={23} height={23} />
        </button>
      )}

      {showSuccessText && (
        <p className="text-center color-success mt-1 mb-4">
          Link je uspešno kopiran u clipboard.
        </p>
      )}
    </>
  );
};
