import { InfiniteData, InfiniteQueryObserverResult } from '@tanstack/react-query';
import { debounce } from 'lodash';
import { useEffect } from 'react';

type Hook = <T>(
  fetchCallback: () => Promise<InfiniteQueryObserverResult<InfiniteData<T>>>,
  isFetching: boolean,
  hasNextPage: boolean
) => void;

export const useFetchOnVerticalScroll: Hook = (fetchCallback, isFetching, hasNextPage) => {
  useEffect(() => {
    const onScroll = async (event: Event) => {
      if (event.target instanceof Document) {
        const { scrollHeight, scrollTop, clientHeight } = event.target.scrollingElement as Element;

        if (hasNextPage && !isFetching && scrollHeight - scrollTop <= clientHeight * 1.25) {
          await fetchCallback();
        }
      }
    };

    const debouncedOnScroll = debounce(onScroll, 100);

    const fillPage = async () => {
      if (document.scrollingElement?.scrollHeight === document.scrollingElement?.clientHeight) {
        await fetchCallback();
      }

      if (document.scrollingElement?.scrollHeight === document.scrollingElement?.clientHeight) {
        await fillPage();
      } else {
        document.removeEventListener('scroll', debouncedOnScroll);
        document.addEventListener('scroll', debouncedOnScroll);
      }
    };

    !isFetching && hasNextPage && fillPage();

    return () => {
      document.removeEventListener('scroll', debouncedOnScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching, hasNextPage]);
};
