import AutoMoto from '/categories/auto-moto.png';
import DecijiSvet from '/categories/deciji-svet.png';
import HranaIPice from '/categories/hrana-i-pice.png';
import Kancelarija from '/categories/kancelarija.png';
import Knjizara from '/categories/knjizara.png';
import KucniLjubimci from '/categories/kucni-ljubimci.png';
import LepotaINega from '/categories/lepota-i-nega.png';
import Moda from '/categories/moda.png';
import Obrazovanje from '/categories/obrazovanje.png';
import Sezona from '/categories/sezona.png';
import SportIRekreacija from '/categories/sport-i-rekreacija.png';
import SveKategorije from '/categories/sve-kategorije.png';
import SveZaKucu from '/categories/sve-za-kucu.png';
import TehnickaRoba from '/categories/tehnicka-roba.png';
import Telekomunikacije from '/categories/telekomunikacije.png';
import Turizam from '/categories/turizam.png';
import Usluge from '/categories/usluge.png';
import ZabavaIKarte from '/categories/zabava-i-karte.png';
import Zdravlje from '/categories/zdravlje.png';

export const CATEGORY_IMAGE_SOURCES = {
  ['auto-moto']: AutoMoto,
  ['deciji-svet']: DecijiSvet,
  ['hrana-i-pice']: HranaIPice,
  ['kancelarija']: Kancelarija,
  ['knjizara']: Knjizara,
  ['kucni-ljubimci']: KucniLjubimci,
  ['lepota-i-nega']: LepotaINega,
  ['moda']: Moda,
  ['obrazovanje']: Obrazovanje,
  ['sezona']: Sezona,
  ['sport-i-rekreacija']: SportIRekreacija,
  ['sve-kategorije']: SveKategorije,
  ['sve-za-kucu']: SveZaKucu,
  ['tehnicka-roba']: TehnickaRoba,
  ['telekomunikacije']: Telekomunikacije,
  ['turizam']: Turizam,
  ['usluge']: Usluge,
  ['zabava-i-karte']: ZabavaIKarte,
  ['zdravlje']: Zdravlje
} as const;
