import { Link } from "react-router-dom"
import ThankYouImageSrc from "../assets/suggestion_sent.png";

const SuggestionSent = () => {
  return (
    <div className="text-center">
      <h2>Hvala vam!</h2>

      <div className="mt-4 mb-4">
        <img src={ThankYouImageSrc} alt="zahvalnica" width={192} height={155} />
      </div>

      <Link to="/profil" className="text-decoration-underline">Nazad na profil</Link>
    </div>
  )
}

export default SuggestionSent