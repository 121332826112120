import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { useSearchParams } from "react-router-dom";
import { GOOGLE_MAPS_API_KEY } from "../constants/config";
import { useQuery } from "@tanstack/react-query";
import { Store } from "../types/types";
import { QUERY_KEYS } from "../constants/queryKeys";
import { callPublicAPI } from "../utils/api";
import currentLocationIcon from '../assets/current-user-location.png';
import genericStoreMarkerIcon from '../assets/generic-store-marker.png';
import StoresMarkerClustererCustomRenderer from "./StoresMarkerClustererCustomRenderer";
import { DEFAULT_OPTIONS } from "../constants/googleMapConstants";
import { SEARCH_PARAMS } from "../constants/searchParamNames";
import "../scss/closest-stores-map.scss";
import { STALE_TIME } from "../constants/staleTime";

const ClosestStoresMap = () => {
  const [searchParams] = useSearchParams();
  const offerId = searchParams.get(SEARCH_PARAMS.OFFER_ID) || "";

  const { isLoaded: isMapsAPILoaded } = useJsApiLoader({
    id: 'map-section-script-loader',
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries: ['geometry', 'drawing'],
  });

  const { isSuccess: storesFetched, data: stores } = useQuery<Store[]>({
    queryKey: [QUERY_KEYS.GET_OFFER_PARTNER_LOCATIONS_LIST, offerId],
    queryFn: async () => {
      const searchParams = new URLSearchParams({
        ad_id: offerId,
        limit: "100"
      });

      const response = await callPublicAPI<Store[]>("GET", "/partner-locations", searchParams);

      return response.data;
    },
    staleTime: STALE_TIME.HOUR,
    enabled: Boolean(offerId)
  });

  const addUserMarker = (position: GeolocationPosition, map: google.maps.Map, bounds: google.maps.LatLngBounds) => {
    const { latitude, longitude } = position.coords;
    const userGeolocation = new google.maps.LatLng(latitude, longitude);
    const userMarker = new google.maps.Marker({
      position: userGeolocation,
      icon: currentLocationIcon,
      zIndex: 9999,
      animation: google.maps.Animation.DROP,
    });
    
    userMarker.setMap(map);

    bounds.extend(userGeolocation);
    map.fitBounds(bounds);
  }

  const setStoreMarkers = (stores: Store[] , map: google.maps.Map, bounds: google.maps.LatLngBounds) => {
    const markers: google.maps.Marker[] = [];

    stores.map((store) => {
      const { lat, lng } = store;
      
      const infoWindow = new google.maps.InfoWindow({
        content: `<div><div><strong>${store.name}</strong></div><div>${store.address}</div></div>`,
        ariaLabel: store.name,
      });

      const marker = new google.maps.Marker({
        position: { lat, lng },
        icon: genericStoreMarkerIcon,
      });

      marker.addListener("click", () => {
        infoWindow.open({
          anchor: marker,
          map,
        });
      });

      bounds.extend(new google.maps.LatLng(lat, lng));
      map.fitBounds(bounds);
      
      markers.push(marker);
    });
    
    new MarkerClusterer({ map, markers, renderer: new StoresMarkerClustererCustomRenderer() });
  }

  return (
    <>
      {isMapsAPILoaded && storesFetched && (
        <GoogleMap 
          id="closest-stores-map"
          onLoad={async (map) => {
            map.setOptions(DEFAULT_OPTIONS);

            const bounds = new google.maps.LatLngBounds();
            let permissionGranted: Partial<PermissionStatus> = { state: "denied" };

            if(navigator && navigator.geolocation) {
              permissionGranted = await navigator.permissions.query({ name: 'geolocation' });
            }

            if(permissionGranted.state === "granted") {            
              navigator.geolocation.getCurrentPosition((position) => {
                addUserMarker(position, map, bounds);
                setStoreMarkers(stores, map, bounds);
              });              
            } else {
              navigator.geolocation.getCurrentPosition((position) => {
                addUserMarker(position, map, bounds);
              });

              setStoreMarkers(stores, map, bounds);
            }
          }}
        />
      )}
    </>
  )
}

export default ClosestStoresMap;
