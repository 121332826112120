import Sheet from 'react-modal-sheet';
import { useSearchParams } from 'react-router-dom';
import { SEARCH_PARAMS } from '../constants/searchParamNames';
import close from '../assets/images/icons/icon-close.svg';
import ClosestStoresMap from './ClosestStoresMap';
import '../scss/closest-stores-overlay-content.scss';

const OfferClosestStoresOverlay = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const showClosestStoresOverlay = searchParams.get(SEARCH_PARAMS.SHOW_CLOSEST_STORES_MAP) ?? '';

  const closeModal = () => {
    searchParams.delete(SEARCH_PARAMS.SHOW_CLOSEST_STORES_MAP);
    setSearchParams(searchParams);
  };

  return (
    <>
      <Sheet
        isOpen={Boolean(showClosestStoresOverlay)}
        onClose={closeModal}
        className="closest-stores-map-overlay"
        disableDrag={true}
      >
        <Sheet.Container>
          <Sheet.Header>
            <div className="d-flex justify-content-end pt-3 pe-2 pb-2">
              <img
                className="cursor-pointer"
                width={25}
                height={25}
                src={close}
                alt="close modal"
                onClick={closeModal}
              />
            </div>
          </Sheet.Header>
          <Sheet.Content>
            <ClosestStoresMap />
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={closeModal} />
      </Sheet>
    </>
  );
};

export default OfferClosestStoresOverlay;
