import { useEffect, useState } from 'react';
import { SEARCH_PARAMS } from '../constants/searchParamNames';
import { useSearchParams } from 'react-router-dom';
import { Location, UserSelectedLocationResponse } from '../types/types';
import { useMutation } from '@tanstack/react-query';
import { callPublicAPI } from '../utils/api';
import { isUserLoggedIn } from '../utils/auth';
import { SESSION_STORAGE_KEYS } from '../constants/sessionStorageKeys';
import { LOCAL_STORAGE_KEYS } from '../constants/localStorageKeys';
import { useFetchUserSelectedLocations } from '../hooks/useFetchUserSelectedLocations';
import { useQueryGeolocation } from '../hooks/useQueryGeolocation';
import HeaderLocationListCommon from './HeaderLocationListCommon';

const HeaderLocationList = () => {
  const userLoggedIn = isUserLoggedIn();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedLocations, setSelectedLocations] = useState<string[]>([]);
  const [shouldQueryGPS, setShouldQueryGPS] = useState<boolean>(false);

  const { data: userSelectedLocationsList } = useFetchUserSelectedLocations(userLoggedIn);
  const { data: userLocationByGPS } = useQueryGeolocation(shouldQueryGPS);

  const handlePopupClosure = () => {
    searchParams.delete(SEARCH_PARAMS.LOCATIONS_MODAL);
    setSearchParams(searchParams);
  };

  const handleClick = (locationData: Location) => {
    const currentLocationValue = locationData.location_group;
    const indexInArray = selectedLocations.indexOf(currentLocationValue);
    const newArray: Array<string> = [...selectedLocations];
    if (indexInArray > -1) {
      newArray.splice(indexInArray, 1);
      setSelectedLocations(newArray);
    } else {
      newArray.push(currentLocationValue);
      setSelectedLocations(newArray);
    }
  };

  const handleSubmit = () => {
    setLocations();
    handlePopupClosure();
  };

  const setLocations = () => {
    const locationValues = selectedLocations.join(',');

    // The source of truth is the useState variable `selectedLocations`.
    // `selectedLocations` is a string array.
    //
    // We store selected locations in sessionStorage to save it while navigating
    // to pages without a location selector.
    //
    // We manually reflect changes to the useState variable in searchParams
    // in order to read it across other components and append it to offer requests.
    if (selectedLocations.length === 0) {
      sessionStorage.removeItem(SESSION_STORAGE_KEYS.USER_SELECTED_LOCATIONS);

      searchParams.delete(SEARCH_PARAMS.LOCATION);
      setSearchParams(searchParams);
    } else {
      sessionStorage.setItem(SESSION_STORAGE_KEYS.USER_SELECTED_LOCATIONS, locationValues);

      searchParams.set(SEARCH_PARAMS.LOCATION, locationValues);
      setSearchParams(searchParams);
    }

    // For logged in users, we store the choice on the API.
    userLoggedIn && saveLocationsForUser.mutate();

    // Just in case, we store the locations in localStorage as well,
    // so we can use those locations if the user is currently logged out
    // but was/is a regular user.
    localStorage.setItem(LOCAL_STORAGE_KEYS.SELECTED_LOCATIONS, locationValues);
  };

  const saveLocationsForUser = useMutation<UserSelectedLocationResponse>({
    mutationFn: async () => {
      const locationValues = selectedLocations.join(',');
      const data = {
        location_groups: locationValues
      };

      const response = await callPublicAPI<UserSelectedLocationResponse>(
        'PUT',
        '/user-selected-location-groups',
        null,
        data
      );
      return response.data;
    }
  });

  const useStringToSelectLocationsAndUpdateSearchParams = (locationsString: string) => {
    const locationsToSelect = locationsString.split(',');
    setSelectedLocations(locationsToSelect);

    searchParams.set(SEARCH_PARAMS.LOCATION, locationsString);
    setSearchParams(searchParams);
  };

  // On load, check session and local storage to see if there's usable previously set data.
  useEffect(() => {
    const previouslySelectedLocationsForSession = sessionStorage.getItem(SESSION_STORAGE_KEYS.USER_SELECTED_LOCATIONS);

    if (previouslySelectedLocationsForSession) {
      useStringToSelectLocationsAndUpdateSearchParams(previouslySelectedLocationsForSession);
    }

    if (!userLoggedIn && !previouslySelectedLocationsForSession) {
      const backupLocations = localStorage.getItem(LOCAL_STORAGE_KEYS.SELECTED_LOCATIONS);
      if (backupLocations && backupLocations.length > 0) {
        useStringToSelectLocationsAndUpdateSearchParams(backupLocations);
      }
    }
  }, []);

  // Based on user data, decied whether to query the location by GPS, or use available data.
  useEffect(() => {
    if (
      userSelectedLocationsList &&
      ((userSelectedLocationsList as UserSelectedLocationResponse).location_groups?.length === 0 ||
        (userSelectedLocationsList as []).length === 0)
    ) {
      setShouldQueryGPS(true);
    }

    if (
      userSelectedLocationsList &&
      (userSelectedLocationsList as UserSelectedLocationResponse)?.location_groups?.length > 0
    ) {
      useStringToSelectLocationsAndUpdateSearchParams(
        (userSelectedLocationsList as UserSelectedLocationResponse).location_groups
      );
    }
  }, [userSelectedLocationsList]);

  // Add the queried GPS location to selected locations.
  useEffect(() => {
    if (userLocationByGPS) {
      setSelectedLocations(userLocationByGPS);
    }
  }, [userLocationByGPS]);

  // Finally set and save the location we've gotten from the GPS query.
  useEffect(() => {
    if (selectedLocations.length > 0 && shouldQueryGPS && userLocationByGPS) {
      setLocations();
    }
  }, [selectedLocations, shouldQueryGPS, userLocationByGPS]);

  return (
    <HeaderLocationListCommon
      handleSubmit={handleSubmit}
      handleClick={handleClick}
      selectedLocations={selectedLocations}
    />
  );
};

export default HeaderLocationList;
