import { useSearchParams } from 'react-router-dom';
import searchSrc from '../assets/search.svg';
import { SEARCH_PARAMS } from '../constants/searchParamNames';

const SearchBarTrigger: React.FC = () => {
  const [ searchParams, setSearchParams ] = useSearchParams();

  const handleClick = () => {
    searchParams.set(SEARCH_PARAMS.SEARCH, 'true');
    setSearchParams(searchParams);
  }

  return (
    <>
      <img 
        onClick={handleClick} 
        className='cursor-pointer' 
        src={searchSrc} 
        alt="control searchbar" 
        height={24} 
        width={24}  
      />
    </>
  );
}

export default SearchBarTrigger;
