import { nanoid } from 'nanoid';
import {
  ApiResponse,
  Authorization,
  GetTokensRequestData,
  JsonWebToken,
  Tokens,
  UserConsent,
  UserConsentUpdated
} from '../types/types';
import { getCodeChallenge } from './crypto';
import { LOGIN_REDIRECT, NALOG_API, PUBLIC_API } from '../constants/config';
import { callNalogAPI, callPublicAPI } from './api';
import { LOCAL_STORAGE_KEYS } from '../constants/localStorageKeys';
import { ACCOUNT } from '../constants/cookies';
import { addAttemptedViewToLocalStorage } from './util';

export const persistAuthorizationDataToLocalStorage = (data: Authorization): void => {
  localStorage.setItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN, data.refresh_token);
  localStorage.setItem(LOCAL_STORAGE_KEYS.GTM_USER_ID, data.gtm_user_id);
  localStorage.setItem(LOCAL_STORAGE_KEYS.EXTERNAL_CONSENT, data.external_consent?.toString());
  localStorage.setItem(LOCAL_STORAGE_KEYS.LEGAL_TYPE, data.legal_type);
  localStorage.setItem(LOCAL_STORAGE_KEYS.TYPE, data.type);
  localStorage.setItem(LOCAL_STORAGE_KEYS.TP_NAME, data.tp_name);
  localStorage.setItem(LOCAL_STORAGE_KEYS.PAYMENT_TYPE, data.payment_type);
};

export const removeUserDataFromLocalStorage = (): void => {
  localStorage.removeItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN);
  localStorage.removeItem(LOCAL_STORAGE_KEYS.GTM_USER_ID);
  localStorage.removeItem(LOCAL_STORAGE_KEYS.EXTERNAL_CONSENT);
  localStorage.removeItem(LOCAL_STORAGE_KEYS.LEGAL_TYPE);
  localStorage.removeItem(LOCAL_STORAGE_KEYS.TYPE);
  localStorage.removeItem(LOCAL_STORAGE_KEYS.TP_NAME);
  localStorage.removeItem(LOCAL_STORAGE_KEYS.PAYMENT_TYPE);

  localStorage.removeItem(LOCAL_STORAGE_KEYS.USER_CONSENT_UID);
  localStorage.removeItem(LOCAL_STORAGE_KEYS.USER_CONSENT_PHONE_CONTACT_CONSENTED);
  localStorage.removeItem(LOCAL_STORAGE_KEYS.USER_CONSENT_EMAIL_CONTACT_CONSENTED);
  localStorage.removeItem(LOCAL_STORAGE_KEYS.USER_CONSENT_DIRECT_MARKETING_CONSENTED);
  localStorage.removeItem(LOCAL_STORAGE_KEYS.USER_CONSENT_TERMS_ACCEPTED);
  localStorage.removeItem(LOCAL_STORAGE_KEYS.USER_CONSENT_PARTNER_REDIRECTION);
  localStorage.removeItem(LOCAL_STORAGE_KEYS.USER_CONSENT_UPDATE_COUNT);
  localStorage.removeItem(LOCAL_STORAGE_KEYS.USER_CONSENT_CONSENT_SHOWN);

  localStorage.removeItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);

  localStorage.removeItem(LOCAL_STORAGE_KEYS.ATTEMPTED_VIEW);
};

export const isUserLoggedIn = (): boolean => {
  const accessTokenFromLocalStorage = localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN) || '';
  const refreshTokenFromLocalStorage = localStorage.getItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN) || '';

  const parsedAccessToken = parseJsonWebToken(accessTokenFromLocalStorage || '');

  const accessTokenValid = parsedAccessToken && Date.now() < parsedAccessToken.exp * 1000;
  const refreshTokenValid = Boolean(refreshTokenFromLocalStorage) && refreshTokenFromLocalStorage !== 'undefined';

  const cookies = document.cookie;
  const hasAccountCookie = cookies.indexOf(ACCOUNT) > -1;

  return (accessTokenValid || import.meta.env.PROD === false) && refreshTokenValid && hasAccountCookie;
};

export const userHasNalogCookie = () => {
  const cookies = document.cookie;
  const cookieList = cookies.split(';');
  const nalogCookie = cookieList.filter((cookie: string) => cookie.indexOf(ACCOUNT) > -1);

  return Boolean(nalogCookie.length);
};

export const getUserNalogCookieValue = (): string => {
  const cookies = document.cookie;
  const cookieList = cookies.split(';');
  const nalogCookie = cookieList.filter((cookie: string) => cookie.indexOf(ACCOUNT) > -1);
  const nalogCookieValue = nalogCookie.length ? nalogCookie[0].split('=')[1] : '';

  return nalogCookieValue;
};

export const generateState = () => {
  const state = localStorage.getItem(LOCAL_STORAGE_KEYS.STATE) || nanoid();
  localStorage.setItem(LOCAL_STORAGE_KEYS.STATE, state);
};

export function parseJsonWebToken(token: string): JsonWebToken {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url?.replace(/-/g, '+').replace(/_/g, '/');

    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );
    return JSON.parse(jsonPayload);
  } catch (e) {
    return {
      iss: '',
      aud: '',
      jti: '',
      nbf: 0,
      exp: 0,
      iat: 0
    };
  }
}

export const loginRequest = (): void => {
  const state = localStorage.getItem(LOCAL_STORAGE_KEYS.STATE) || '';
  const client_id = 'market';
  const redirect_uri = LOGIN_REDIRECT;
  const scope = 'resource_info';

  getCodeChallenge().then(code_challenge => {
    const params = new URLSearchParams();
    params.append('response_type', 'code');
    params.append('client_id', client_id);
    params.append('redirect_uri', redirect_uri);
    params.append('state', state);
    params.append('scope', scope);
    params.append('code_challenge', code_challenge);
    params.append('code_challenge_method', 'S256');

    const url = `${NALOG_API}/auth?${params.toString()}`;
    window.location.assign(url);
  });
};

export const logIn = (): void => {
  addAttemptedViewToLocalStorage();

  if (import.meta.env.PROD === true) {
    generateState();
    loginRequest();
  } else {
    mockLogin();
  }
};

export const authorizeWithAccessAndRefreshTokens = async (
  access_token: string,
  refresh_token: string
): Promise<ApiResponse<Authorization>> => {
  const data = {
    access_token,
    refresh_token
  };

  const response = await callPublicAPI<Authorization>('POST', '/auth', null, data);
  return response;
};

export const authorizeWithJWEToken = async (jwe_token: string): Promise<ApiResponse<Authorization>> => {
  const data = { jwe_token };

  const response = await callPublicAPI<Authorization>('POST', '/auth', null, data);
  return response;
};

export const getTokensFromCodeAndCodeVerifier = async (code: string, code_verifier: string): Promise<Tokens> => {
  const data: GetTokensRequestData = {
    client_id: 'market',
    grant_type: 'authorization_code',
    redirect_uri: LOGIN_REDIRECT,
    code,
    code_verifier
  };

  return callNalogAPI<Tokens>('POST', '/token', data);
};

export const getUserConsents = async (): Promise<ApiResponse<UserConsent>> => {
  return callPublicAPI<UserConsent>('GET', '/user-consents');
};

export const persistUserConsentsInLocalStorage = (consents: UserConsent): void => {
  localStorage.setItem(LOCAL_STORAGE_KEYS.USER_CONSENT_UID, consents.uid);
  localStorage.setItem(
    LOCAL_STORAGE_KEYS.USER_CONSENT_PHONE_CONTACT_CONSENTED,
    consents.phone_contact_consented?.toString() || ''
  );
  localStorage.setItem(
    LOCAL_STORAGE_KEYS.USER_CONSENT_EMAIL_CONTACT_CONSENTED,
    consents.email_contact_consented?.toString() || ''
  );
  localStorage.setItem(
    LOCAL_STORAGE_KEYS.USER_CONSENT_DIRECT_MARKETING_CONSENTED,
    consents.direct_marketing_consented?.toString() || ''
  );
  localStorage.setItem(LOCAL_STORAGE_KEYS.USER_CONSENT_TERMS_ACCEPTED, consents.terms_and_conditions?.toString() || '');
  localStorage.setItem(
    LOCAL_STORAGE_KEYS.USER_CONSENT_PARTNER_REDIRECTION,
    consents.partner_redirection?.toString() || ''
  );
  localStorage.setItem(LOCAL_STORAGE_KEYS.USER_CONSENT_UPDATE_COUNT, consents.update_count?.toString() || '');
  localStorage.setItem(LOCAL_STORAGE_KEYS.USER_CONSENT_CONSENT_SHOWN, consents.consent_shown);
};

export const persistUserConsentMutationInLocalStorage = (consents: UserConsentUpdated): void => {
  localStorage.setItem(LOCAL_STORAGE_KEYS.USER_CONSENT_CONSENT_SHOWN, consents.consent_shown);
  localStorage.setItem(
    LOCAL_STORAGE_KEYS.USER_CONSENT_DIRECT_MARKETING_CONSENTED,
    consents.direct_marketing_consented?.toString() || ''
  );
  localStorage.setItem(
    LOCAL_STORAGE_KEYS.USER_CONSENT_PARTNER_REDIRECTION,
    consents.partner_redirection?.toString() || ''
  );
  localStorage.setItem(LOCAL_STORAGE_KEYS.USER_CONSENT_UPDATE_COUNT, consents.update_count?.toString() || '');
};

export const persistPhoneContactConsentInLocalStorage = (consent: 0 | 1): void => {
  localStorage.setItem(LOCAL_STORAGE_KEYS.USER_CONSENT_PHONE_CONTACT_CONSENTED, consent.toString());
};

export const persistEmailContactConsentInLocalStorage = (consent: 0 | 1): void => {
  localStorage.setItem(LOCAL_STORAGE_KEYS.USER_CONSENT_EMAIL_CONTACT_CONSENTED, consent.toString());
};

export const persistTermsAndConditionsAcceptedInLocalStorage = (consent: 0 | 1): void => {
  localStorage.setItem(LOCAL_STORAGE_KEYS.USER_CONSENT_TERMS_ACCEPTED, consent.toString());
};

export const shouldRedirectToPrivacySettings = (): boolean => {
  const isLoggedIn = isUserLoggedIn();
  if (!isLoggedIn) return false;

  const termsAndConditionsAccepted = localStorage.getItem(LOCAL_STORAGE_KEYS.USER_CONSENT_TERMS_ACCEPTED);
  if (termsAndConditionsAccepted !== '1') return true;

  const consentShown = new Date(localStorage.getItem(LOCAL_STORAGE_KEYS.USER_CONSENT_CONSENT_SHOWN) || '');
  const marketingConsentGiven = localStorage.getItem(LOCAL_STORAGE_KEYS.USER_CONSENT_DIRECT_MARKETING_CONSENTED);
  const differenceInDays = Math.floor((new Date().getTime() - consentShown.getTime()) / (1000 * 3600 * 24));
  return differenceInDays > 13 && !marketingConsentGiven;
};

export const mockLogin = (): void => {
  fetch(`${PUBLIC_API}/mock/auth`, {
    method: 'POST'
  })
    .then(res => res.json())
    .then((res: ApiResponse<Authorization>) => {
      persistAuthorizationDataToLocalStorage(res.data);
    })
    .finally(() => {
      const redirectToPath = localStorage.getItem(LOCAL_STORAGE_KEYS.ATTEMPTED_VIEW) || '/';
      window.location.assign(redirectToPath);
    });
};
