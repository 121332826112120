import "../scss/keyboard-letter.scss";

interface Props {
  letter: string;
  isPressed: boolean;
  handleClick: (letter: string) => void;
}

const KeyboardButton: React.FC<Props> = ({ letter, isPressed, handleClick }) => {
  const buttonClassName = `keyboard-button ${isPressed ? "active" : ""}`;
  return (
    <div className={buttonClassName} onClick={() => handleClick(letter)}>
      <span>{letter}</span>
    </div>
  );
};

export default KeyboardButton;
