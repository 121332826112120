import Sheet from 'react-modal-sheet';
import { useSearchParams } from "react-router-dom";
import ShareOfferOverlayContent from "./ShareOfferOverlayContent";
import { SEARCH_PARAMS } from "../constants/searchParamNames";
import close from '../assets/images/icons/icon-close.svg';
import "../scss/share-offer-overlay.scss";

const ShareOfferOverlay = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const showShareOverlay = searchParams.get(SEARCH_PARAMS.SHOW_SHARE_OPTIONS) || "";

  const closeModal = () => {  
    searchParams.delete(SEARCH_PARAMS.SHOW_SHARE_OPTIONS);
    setSearchParams(searchParams);
  }

  return (
    <>
      {showShareOverlay && (
        <Sheet isOpen={Boolean(showShareOverlay)} onClose={closeModal} className='share-offer-overlay'>
          <Sheet.Container>
            <Sheet.Header>
              <div className='d-flex justify-content-end pt-3'>
                <img className='cursor-pointer' width={25} height={25} src={close} alt="close modal" onClick={closeModal} />
              </div>
            </Sheet.Header>
            <Sheet.Content>
              <ShareOfferOverlayContent handlePopupClosure={closeModal} />
            </Sheet.Content>
          </Sheet.Container>
          <Sheet.Backdrop onTap={closeModal} />
        </Sheet>
      )}
    </>
  )
}

export default ShareOfferOverlay