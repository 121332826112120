import { Link } from "react-router-dom";
import Arrow from "../assets/category-arrow.svg";
import "../scss/profile-page-option.scss";

interface Props {
  children?: React.ReactNode;
  imgSrc: string;
  imgWidth?: number;
  imgHeight: number,
  linkTo: string;
}

const ProfilePageOption: React.FC<Props> = ({
  children,
  imgSrc,
  imgWidth = 28,
  imgHeight,
  linkTo
}) => {
  return (
    <Link to={linkTo} className="profile-page-option d-flex align-items-center mb-4">
      <div className="profile-page-option__icon d-flex align-items-center justify-content-center">
        <img src={imgSrc} alt="icon" width={imgWidth} height={imgHeight} />
      </div>
      <div className="profile-page-option__title mt-4 mb-4 ms-3 me-4">
        {children}
      </div>
      <div className="profile-page-option__arrow text-center">
        <img src={Arrow} alt="arrow" width={20} height={20} />
      </div>
    </Link>
  )
}

export default ProfilePageOption