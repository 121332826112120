import { Link, useSearchParams } from 'react-router-dom';
import Button from './Button';
import { SEARCH_PARAMS } from '../constants/searchParamNames';
import Sheet from 'react-modal-sheet';
import '../scss/privacy-modal.scss';

const PrivacyModal = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const showModal = searchParams.get(SEARCH_PARAMS.SHOW_PRIVACY_MODAL);

  const closeModal = () => {
    searchParams.delete(SEARCH_PARAMS.SHOW_PRIVACY_MODAL);
    setSearchParams(searchParams);
  };

  return (
    <>
      <Sheet isOpen={Boolean(showModal)} onClose={closeModal} className="privacy-modal ps-3 pe-3">
        <Sheet.Container>
          <Sheet.Header />
          <Sheet.Content className="vertical-scroll">
            <PrivacyModalContent />
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={closeModal} />
      </Sheet>
    </>
  );
};

const PrivacyModalContent = () => {
  return (
    <>
      <h3 className="mb-4">Politika privatnosti</h3>

      <section className="font-yettel-light mb-5">
        <p className="mb-3">
          Ne pristajem da Yettel obrađuje moje podatke o ličnosti u cilju kreiranja i dostavljanja komercijalnih i
          personalizovanih ponuda.
        </p>

        <p className="mb-5">
          Davanje pristanka je potrebno ukoliko želite da na osnovu analize podataka o ličnosti kreiramo za vas ponude
          kao što su: personalizovane ponude i popusti, kao i specijalne Yettel ponude.
        </p>

        <a
          className="d-block mb-3 text-decoration-underline font-yettel"
          target="_blank"
          rel="noreferrer"
          href="https://www.yettel.rs/sr/privatni/ponuda/aktuelno/yettel-shopping/pristanak-non-yettel/"
        >
          Detaljnije o pristanku
        </a>

        <a
          className="d-block mb-3 text-decoration-underline font-yettel"
          target="_blank"
          rel="noreferrer"
          href="https://www.yettel.rs/sr/o-yettelu/yettel-shopping-obavestenje-o-privatnosti/uvod"
        >
          Obaveštenje o privatnosti
        </a>

        <p className="mb-3">Pristajem da me za ponude kontaktirate putem telefona</p>

        <p className="mb-3">Ne pristajem da me za ponude kontaktirate putem e-maila</p>
      </section>

      <Link to="/profil/privatnost">
        <Button>Promeni</Button>
      </Link>
    </>
  );
};

export default PrivacyModal;
