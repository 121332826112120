import { useEffect } from "react";
import FilterSortContainer from "./FilterSortContainer";
import menu from "../assets/menu.png";
import "../scss/filter-box.scss";

interface Props {
  setIsPopupOpen: (active: boolean) => void;
}

const FilterBox: React.FC<Props> = ({
  setIsPopupOpen
}) => {
  
  useEffect(() => {
    const yettelContent = document.querySelector(".yettel-content");
    yettelContent?.classList.add("yettel-content--has-sticky-filter-box");
    return () => {
      yettelContent?.classList.remove("yettel-content--has-sticky-filter-box");
    }
  }, []);

  return (
    <section className="filter-box mt-1 mb-4 d-inline-flex align-items-center justify-content-center">
      <div className="filter-box__filter font-yettel fz-16 cursor-pointer d-flex align-items-center pe-3" onClick={() => setIsPopupOpen(true)}>
        <div className="chevron-container pe-2"><img width={20} height={20} alt="checkmark" src={menu} /></div>         
        Filter
      </div>

      <div className="divider-box" style={{height: 20}}></div>

      <FilterSortContainer />
    </section>
  )
}

export default FilterBox;