import { LOCAL_STORAGE_KEYS } from "../constants/localStorageKeys";

export const DESKTOP_SIZE = 1200;

export const formatDate = (date: Date) => {
  return `${date.getDate() < 10 ? `${0}${date.getDate()}` : date.getDate()}/${date.getMonth() < 9 ? `${0}${date.getMonth() + 1}` : date.getMonth() + 1}/${date.getFullYear()}`;
};

export const getCurrentYear = () => {
  const currentDate = new Date();
  return currentDate.getFullYear();
};

export function isDesktopView(): boolean {
  const deviceSize = window.screen.width;
  return Boolean(deviceSize >= DESKTOP_SIZE);
}

export function openGoogleMapsWithCoords(lat: number, lng: number, newTab: boolean = true): void {
  const target = newTab ? '_blank' : '_self';
  window.open(`https://www.google.com/maps/search/?api=1&query=${lat},${lng}`, target)
}

export function addAttemptedViewToLocalStorage() {
  let attemptedView = location.pathname + location.search;

  const featureRoots = ["feature1", "feature2", "feature3"];
  const excludedLocations = ["greska-prilikom-autorizacije"];

  featureRoots.forEach((root: string) => {
    attemptedView = attemptedView.replace(root, '');
  });

  excludedLocations.forEach((location: string) => {
    attemptedView = attemptedView.replace(location, '');
  });

  attemptedView = attemptedView.replace(/\/+/g, '/');

  localStorage.setItem(LOCAL_STORAGE_KEYS.ATTEMPTED_VIEW, attemptedView);
}
