import { useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash';
import checked from '../assets/checked.png';
import unchecked from '../assets/unchecked.png';
import KeyboardButton from './KeyboardButton';
import { SEARCH_PARAMS_FILTERS } from '../constants/searchParamsFilters';
import { useSearchParams } from 'react-router-dom';
import { useFetchPartners } from '../hooks/useFetchPartners';
import { sortPartnersByLetter } from '../utils/partners';
import { PartnerMinimalInfo } from '../types/types';
import { modifySearchParamsForId } from '../utils/filters';
import { useFetchPartnerCounts } from '../hooks/useFetchPartnerCounts';
import { ALPHABET } from '../constants/alphabetLetters';
import '../scss/filter-list-checkbox.scss';
import '../scss/filter-list-container.scss';

const FilterPartner: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { data: partners } = useFetchPartners();
  const sortedPartners = sortPartnersByLetter(partners || []);
  const selectedPartners = searchParams.get(SEARCH_PARAMS_FILTERS.PARTNER_PARAM) || '';

  const [offset, setOffset] = useState<number>(0);
  const { data: partnerCounts } = useFetchPartnerCounts(offset);

  const dropdownRef = useRef<HTMLDivElement>(null);
  const [letterPressed, setLetterPressed] = useState<string>('');

  const handlePartnerClick = (partner: PartnerMinimalInfo) => {
    const alteredSearchParams = modifySearchParamsForId(
      searchParams,
      selectedPartners,
      partner.id,
      SEARCH_PARAMS_FILTERS.PARTNER_PARAM
    );
    setSearchParams(alteredSearchParams);
  };

  const handleLetterClick = (letter: string) => {
    if (dropdownRef.current) {
      setLetterPressed(letter);
      const keyElement = dropdownRef.current.querySelector(`[data-key="${letter}"]`);
      if (keyElement) {
        keyElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  useEffect(() => {
    if (dropdownRef.current) {
      const handleScroll = debounce(() => {
        const container = dropdownRef.current;
        const allPartners = document.querySelectorAll('[data-partners-key]');
        const firstVisiblePartner = Array.from(allPartners).find(
          partner => partner.getBoundingClientRect().top >= (container as HTMLElement).getBoundingClientRect().top
        ) as HTMLElement;

        const firstVisiblePartnerIndex = firstVisiblePartner?.getAttribute('data-partners-key');
        setOffset(parseInt(firstVisiblePartnerIndex || '0'));
      }, 200);

      dropdownRef.current.addEventListener('scroll', () => handleScroll());
    }
  }, [dropdownRef]);

  useEffect(() => {
    if (partnerCounts && partnerCounts.length > 0) {
      const relevantPartnerIds = partnerCounts.map(partner => partner.id);
      const allPartners = document.querySelectorAll('[data-partners-key]');
      const relevantPartners = Array.from(allPartners).filter(partner => {
        const partnerId = partner.getAttribute('data-partner-id');
        return relevantPartnerIds.includes(partnerId as string);
      });

      relevantPartners.forEach(partner => {
        const partnerCount = partner.parentNode?.querySelector('[data-partner-count]');
        if (!partnerCount?.firstChild) {
          const partnerId = partner.getAttribute('data-partner-id');
          const partnerInfo = partnerCounts.find(partner => partner.id === partnerId);
          partnerCount?.appendChild(document.createTextNode(` (${partnerInfo?.adCount})`));
        }
      });
    }
  }, [partnerCounts]);

  return (
    <div className="filter-list__item pt-2">
      <div className="filter-list__name font-yettel-bold">Partneri</div>

      <section>
        {ALPHABET.map((letter, index) => (
          <KeyboardButton
            key={index}
            isPressed={letterPressed === letter}
            letter={letter}
            handleClick={() => handleLetterClick(letter)}
          />
        ))}
      </section>

      <section className="filter-list__fixed-height mt-3" ref={dropdownRef}>
        {partners &&
          sortedPartners.letters.map(letter => (
            <PartnerLetter key={letter} letter={letter}>
              {sortedPartners.items[letter]?.map(partner => (
                <PartnerItem
                  key={partner.id}
                  partner={partner}
                  selectedPartners={selectedPartners}
                  handlePartnerClick={handlePartnerClick}
                  index={partner.index as number}
                />
              ))}
            </PartnerLetter>
          ))}
      </section>
    </div>
  );
};

interface PartnerItemProps {
  partner: PartnerMinimalInfo;
  selectedPartners: string;
  handlePartnerClick: (partner: PartnerMinimalInfo) => void;
  index: number;
}

const PartnerItem: React.FC<PartnerItemProps> = ({ partner, selectedPartners, handlePartnerClick, index }) => {
  return (
    <div className="filter-list__checkbox-item__box pt-2 pb-2" onClick={() => handlePartnerClick(partner)}>
      <img width={20} height={20} alt="checkmark" src={selectedPartners.includes(partner.id) ? checked : unchecked} />
      <div className="filter-list__checkbox-item__name d-inline-block">
        <span data-partners-key={index} data-partner-id={partner.id}>
          {partner.name}
        </span>{' '}
        <span data-partner-count></span>
      </div>
    </div>
  );
};

interface PartnerLetterProps {
  letter: string;
  children: React.ReactNode;
}

const PartnerLetter: React.FC<PartnerLetterProps> = ({ children, letter }) => {
  return (
    <div className="filter-list__checkbox-item" key={letter} data-key={letter}>
      <div className="filter-list__checkbox-item__key-name">{letter}</div>
      {children}
    </div>
  );
};

export default FilterPartner;
