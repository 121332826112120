import { useState } from 'react';
import LoadingIndicator from './LoadingIndicator';
import clsx from 'clsx';

interface Props {
  code: string;
  url: string | null;
  pushUseCouponToAnalytics: () => void;
  showCopyButton: boolean;
}

const CodeDisplay: React.FC<Props> = ({
  code,
  url,
  pushUseCouponToAnalytics,
  showCopyButton,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isCodeCopied, setIsCodeCopied] = useState(false);

  const handleCodeCopy = async () => {
    setIsLoading(true);
    navigator.clipboard.writeText(code).then(() => {
      setTimeout(() => {
        setIsLoading(false);
        setIsCodeCopied(true);
        setTimeout(() => {
          setIsCodeCopied(false);
          pushUseCouponToAnalytics();
          if (url) {
            window.open(url);
          }
        }, 1000);
      }, 300);
    });
  };

  return (
    <>
      {!isLoading && (
        isCodeCopied ?
          <span className="fs-20 offer-overlay-content__coupon__code--copied">Kod je kopiran</span> :
          <section className="offer-overlay-content__coupon__code d-flex justify-content-between align-items-center">
            <span className={clsx('font-yettel ms-4 fz-18', !showCopyButton && 'code-only')}>{code}</span>
            {showCopyButton && <span onClick={handleCodeCopy} className="offer-overlay-content__coupon__copy cursor-pointer">Kopiraj i iskoristi</span>}
          </section>
      )}

      {isLoading && (<LoadingIndicator />)}
    </>
  );
};

export default CodeDisplay;
