import React from "react";
import { debounce } from "lodash";
import { useMutation } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import { SEARCH_PARAMS_FILTERS } from "../constants/searchParamsFilters";
import { SaveAllOffersRequestData } from "../types/types";
import { callPublicAPI } from "../utils/api";
import { isUserLoggedIn } from "../utils/auth";
import { SAVE_ALL_COUPONS_VISIBLE } from "../constants/config";
import "../scss/coupon-recap.scss";

interface Props {
  totalNumber: number;
}

const CouponRecap: React.FC<Props> = ({ totalNumber }) => {
  const [searchParams] = useSearchParams();
  const isLoggedIn = isUserLoggedIn();

  const saveAllCouponsMutation = useMutation({
    mutationFn: async () => {
      const partnerQueryParam = searchParams.get(SEARCH_PARAMS_FILTERS.PARTNER_PARAM);
      const categoryQueryParam = searchParams.get(SEARCH_PARAMS_FILTERS.CATEGORY_PARAM);
      const shoppingMallQueryParam = searchParams.get(SEARCH_PARAMS_FILTERS.SHOPPING_MALL_PARAM);
      const storeParam = searchParams.get(SEARCH_PARAMS_FILTERS.STORE_PARAM);
      const recommendedParam = searchParams.get(SEARCH_PARAMS_FILTERS.RECOMMENDED_PARAM);

      const data: SaveAllOffersRequestData = {
        section: "Sačuvaj sve",
      };

      if (partnerQueryParam) {
        data.partner = partnerQueryParam;
      }

      if (categoryQueryParam) {
        data.category = categoryQueryParam;
      }

      if (shoppingMallQueryParam) {
        data["shopping-mall"] = shoppingMallQueryParam;
      }

      if (storeParam) {
        data.store = storeParam;
      }

      if (recommendedParam) {
        data.recommended = recommendedParam === "1" ? 1 : 0;
      }

      const response = await callPublicAPI<unknown>("POST", "/offers/filter", null, data);
      return response.data;
    },
    onSuccess: () => {
      window.location.reload();
    },
    onError: () => {
      window.location.reload();
    },
  });

  const handleSaveAllCoupons = () => {
    saveAllCouponsMutation.mutate();
  };

  const debouncedSaveAllCoupons = debounce(handleSaveAllCoupons, 1000);

  return (
    <div className="coupon-recap d-flex fz-14 mt-3 mb-3">
      <section>Prikazanih ponuda {totalNumber}</section>
      {isLoggedIn && SAVE_ALL_COUPONS_VISIBLE && (
        <>
          <div className="divider-box ms-3 me-3"></div>
          <section className="font-yettel-bold text-decoration-underline cursor-pointer" onClick={debouncedSaveAllCoupons}>
          Sačuvaj sve kupone
          </section>
        </>
      )}
    </div>
  );
};

export default CouponRecap;
