import checkmark from '../assets/checkmark.png';
import Button from './Button';

const OfferActionJustSaved = () => {
  return (
    <Button className="button--just-saved-button">
      <img width={16} height={16} src={checkmark} alt="coupon saved" className="me-1" />
      <span>Kupon sačuvan</span>
    </Button>
  );
};

export default OfferActionJustSaved;
