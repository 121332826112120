import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '../constants/queryKeys';
import { callPublicAPI } from '../utils/api';
import { useEffect, useState } from 'react';

export const useQueryGeolocation = (queryEnabled: boolean) => {
  const [shouldRunQuery, setShouldRunQuery] = useState<boolean>(false);

  const [lat, setLat] = useState<number>();
  const [lng, setLng] = useState<number>();

  async function getLatLng() {
    if (navigator && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        setLat(position.coords.latitude);
        setLng(position.coords.longitude);
      });
    }
  }

  if (queryEnabled) {
    getLatLng();
  }

  useEffect(() => {
    if (lat && lng) {
      setShouldRunQuery(true);
    }
  }, [lat, lng]);

  return useQuery<string[]>({
    queryKey: [QUERY_KEYS.GET_USER_LOCATION_BY_GPS, lat, lng],
    queryFn: async () => {
      const searchParams = new URLSearchParams({
        lat: (lat as number).toString(),
        lon: (lng as number).toString()
      });

      const response = await callPublicAPI<string[]>('GET', '/user-location-group-areas', searchParams);

      return response.data;
    },
    enabled: shouldRunQuery
  });
};
