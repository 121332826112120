import { shoppingMallImages } from "../constants/shoppingMallImages";
import { ShoppingMall } from "../types/types";
import { Link } from "react-router-dom";
import "../scss/shopping-mall.scss";

interface Props {
  shoppingMall: ShoppingMall
}

const ShoppingMallDisplay: React.FC<Props> = ({ shoppingMall }) => {
  return (
    <Link to={`/shopping-mall/${shoppingMall.id}`} state={{ shoppingMallName: shoppingMall.name }} className="shopping-mall color-secondary-text text-center fz-14 pb-2 ps-2 pe-2 mb-2">
      <img className="shopping-mall__image mb-3" 
        src={shoppingMallImages[`${shoppingMall.location_group}-${shoppingMall.name}`]} 
        alt={`Tržni centar ${shoppingMall.name}`} 
        width={90} 
        height={90} 
        loading="lazy"
      />
      <span className="shopping-mall__name d-block color-primary-color text-center fz-18 mb-2">{shoppingMall.name}</span> 
      {shoppingMall.location_group.replace(" - sve opštine", "")}
    </Link>
  );
}

export default ShoppingMallDisplay;