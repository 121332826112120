import tw from "../assets/social/social_tw.png";
import fb from "../assets/social/social_fb.png";
import ig from "../assets/social/social_insta.png";
import "../scss/yettel-footer.scss";

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="yettel-footer bg-primary-color">
      <div className="yettel-footer-container container">
        <section className="yettel-footer__social">
          <a href="https://twitter.com/YettelSrbija_tw" target="_blank">
            <img src={tw} alt="twitter" width={30} height={30} loading="lazy" />
          </a>
          <a href="https://www.facebook.com/YettelSrbija/" target="_blank">
            <img src={fb} alt="facebook" width={30} height={30} loading="lazy" />
          </a>
          <a href="https://www.instagram.com/yettelsrbija/" target="_blank">
            <img src={ig} alt="instagram" width={30} height={30} loading="lazy" />
          </a>
        </section>
        <section className="d-flex justify-content-between font-yettel-light fz-12 mt-2 pb-2">
          <div>&copy;{currentYear} Yettel Srbija</div>
          <div><a rel="noreferrer" target="_blank" href="https://www.yettel.rs/sr/o-yettelu/obavestenje-o-privatnosti/">Politika privatnosti</a></div>
        </section>
      </div>
    </footer>
  )
}

export default Footer;
