import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { Offer, PartnerLocation, Store, YSHParams } from '../types/types';
import { useQuery } from '@tanstack/react-query';
import { callPublicAPI } from '../utils/api';
import { QUERY_KEYS } from '../constants/queryKeys';
import OfferDisplay from './OfferDisplay';
import OfferGrid from './OfferGrid';
import OfferList from './OfferList';
import { pushImpressionsToAnalytics } from '../utils/analytics';

const getPartnerLocationDataFromOfferList = (offers: Offer[], partnerId: string) => {
  const offersWithPartnerLocations = offers.filter(offer => offer.partner_locations.length > 0);
  const partnerLocation = offersWithPartnerLocations[0].partner_locations.find(
    partnerLocation => partnerLocation.id === partnerId
  );

  return partnerLocation as PartnerLocation;
};

interface Props {
  setIsSingleOfferDisplayed: Dispatch<SetStateAction<boolean>>;
}

const MapPartnerContent: React.FC<Props> = ({ setIsSingleOfferDisplayed }) => {
  const [searchParams] = useSearchParams();
  const params = useParams<YSHParams>();
  const partnerId = params.partnerId;
  const [partnerData, setPartnerData] = useState<React.ReactNode | null>(null);

  const { data: offers } = useQuery<Offer[]>({
    queryKey: [QUERY_KEYS.GET_OFFERS, QUERY_KEYS.GET_OFFERS_VARIATION.FOR_PARTNER, partnerId],
    queryFn: async () => {
      const searchParams = new URLSearchParams({
        partner_location_id: partnerId as string
      });

      const response = await callPublicAPI<Offer[]>('GET', '/offers', searchParams);

      const partnerLocation = getPartnerLocationDataFromOfferList(response.data, partnerId as string);
      pushImpressionsToAnalytics(response.data, `${response.data[0]?.partner_name}, ${partnerLocation.name}`);

      setIsSingleOfferDisplayed(response.data.length === 1);

      return response.data;
    },
    enabled: !!partnerId
  });

  useEffect(() => {
    if (offers && partnerId) {
      const partnerLocation = getPartnerLocationDataFromOfferList(offers, partnerId);

      partnerLocation && setPartnerData(<PartnerData partnerLocation={partnerLocation as Store} />);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offers]);

  return (
    <>
      {offers && (
        <>
          {partnerData}
          <hr />
          <OfferList className="d-block">
            <OfferGrid
              className="offer-grid--small mt-3"
              style={{
                width: 207 * offers.length + 15 * offers.length
              }}
            >
              {offers.map(offer => (
                <OfferDisplay
                  key={offer.id}
                  offer={offer}
                  showButton={false}
                  linkTo={`/near-by/partner/${partnerId}/offer/${offer.id}?${searchParams.toString()}`}
                  displayedIn="Mapa"
                />
              ))}
            </OfferGrid>
          </OfferList>
        </>
      )}
    </>
  );
};

const PartnerData: React.FC<{ partnerLocation: Store }> = ({ partnerLocation }) => {
  return (
    <section className="d-flex justify-space-between align-items-center mb-3">
      <div style={{ flex: 1 }}>
        <h2 className="fz-18">{partnerLocation?.name}</h2>
        <h3 className="fz-16 font-yettel-light">{partnerLocation?.address}</h3>
      </div>
      <a
        className="text-decoration-underline font-yettel-bold"
        href={`https://www.google.com/maps/dir/?api=1&destination=${partnerLocation?.lat}%2C${partnerLocation?.lng}`}
        target="_blank"
      >
        Putanja
      </a>
    </section>
  );
};

export default MapPartnerContent;
