import { useSearchParams } from 'react-router-dom';
import { SEARCH_PARAMS } from '../constants/searchParamNames';
import SHARE_ICON from '../assets/images/icons/offer/share.png';
import "../scss/share-offer-link.scss";

const ShareOfferLink: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleClick = () => {
    searchParams.set(SEARCH_PARAMS.SHOW_SHARE_OPTIONS, 'true');
    setSearchParams(searchParams);
  }

  return (
    <>
      <button onClick={handleClick} className={`share-offer-link cursor-pointer`}>
        <img src={SHARE_ICON} alt="icon" />
      </button>
    </>
  )
}

export default ShareOfferLink
