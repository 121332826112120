interface Props {
  children: React.ReactNode;
}

const HomepageSectionTitle: React.FC<Props> = ({ children }) => {
  return (
    <h2 className="mb-4">
      {children}
    </h2>
  );
}

export default HomepageSectionTitle;