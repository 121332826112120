import Sheet from 'react-modal-sheet';
import close from '../assets/images/icons/icon-close.svg';
import '../scss/daily-prize-description-modal.scss';
import { Banner } from '../types/types.ts';

interface Props {
  isActive: boolean;
  setIsActive: (isActive: boolean) => void;
  banner: Banner;
}

export const DailyPrizeDescriptionModal: React.FC<Props> = ({ isActive, setIsActive, banner }) => {
  const onClose = () => {
    setIsActive(false);
  };

  return (
    <Sheet isOpen={isActive} onClose={onClose} className="daily-prize-description-modal">
      <Sheet.Container>
        <Sheet.Header>
          <div className="d-flex justify-content-end pt-3 pe-3 pb-3">
            <img className="cursor-pointer" width={25} height={25} src={close} alt="close modal" onClick={onClose} />
          </div>
        </Sheet.Header>
        <Sheet.Content>
          <div className="content" dangerouslySetInnerHTML={{ __html: banner.htmlContent }} />
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop onTap={onClose} />
    </Sheet>
  );
};
