export const LOCAL_STORAGE_KEYS = {
  ACCESS_TOKEN: 'yshAccessToken',
  REFRESH_TOKEN: 'yshRefreshToken',
  GTM_USER_ID: 'yshGTMUserId',
  EXTERNAL_CONSENT: 'yshExternalConsent',
  LEGAL_TYPE: 'yshLegalType',
  TYPE: 'yshType',
  TP_NAME: 'yshTpName',
  PAYMENT_TYPE: 'yshPaymentType',
  STATE: 'yshState',
  GENERATE_CODE_CHALLENGE_VERIFIER: 'yshGenerateCodeChallengeVerifier',
  CODE_CHALLENGE_VERIFIER: 'yshCodeChallengeVerifier',
  ATTEMPTED_VIEW: 'yshAttemptedView',
  CONSENT_SHOWN: 'yshConsentShown',

  USER_CONSENT_UID: 'yshUserConsentUid',
  USER_CONSENT_PHONE_CONTACT_CONSENTED: 'yshUserConsentPhoneContactConsented',
  USER_CONSENT_EMAIL_CONTACT_CONSENTED: 'yshUserConsentEmailContactConsented',
  USER_CONSENT_DIRECT_MARKETING_CONSENTED: 'yshUserConsentDirectMarketingConsented',
  USER_CONSENT_TERMS_ACCEPTED: 'yshUserConsentTermsAccepted',
  USER_CONSENT_PARTNER_REDIRECTION: 'yshUserConsentPartnerRedirection',
  USER_CONSENT_UPDATE_COUNT: 'yshUserConsentUpdateCount',
  USER_CONSENT_CONSENT_SHOWN: 'yshUserConsentConsentShown',

  PREVIOUS_SEARCH_CACHE: 'yshPreviousSearchCache',
  IS_PRIZE_MODAL_SEEN: 'yshIsPrizeModalSeen',

  SELECTED_LOCATIONS: 'yshSelectedLocations'
};
