import { useSearchParams } from 'react-router-dom';
import Authorize from './Authorize';
import AuthorizeWithJWEToken from './AuthorizeWithJWEToken';
import { SEARCH_PARAMS } from '../constants/searchParamNames';
import { logIn } from '../utils/auth';
import { useState } from 'react';

const Authorization = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const autoLogin = searchParams.get(SEARCH_PARAMS.AUTO_LOGIN);
  const redirected = searchParams.get(SEARCH_PARAMS.REDIRECTED) === 'true';

  const jweToken = searchParams.get(SEARCH_PARAMS.TOKEN);

  const [shouldAuthorize, setShouldAuthorize] = useState(!(autoLogin || jweToken));

  if (autoLogin) {
    searchParams.delete(SEARCH_PARAMS.AUTO_LOGIN);
    setSearchParams(searchParams);

    logIn();

    return <></>;
  }

  return (
    <>
      {jweToken && !shouldAuthorize && (
        <AuthorizeWithJWEToken jweToken={jweToken} setShouldAuthorize={setShouldAuthorize} />
      )}
      {shouldAuthorize && <Authorize jweToken={jweToken} redirected={redirected} />}
    </>
  );
};

export default Authorization;
