interface Props {
  children: React.ReactNode;
  className?: string;
}

const HomepageSectionContainer: React.FC<Props> = ({ children, className }) => {
  return (
    <section className={`${className ?? ""}`}>
      {children}
    </section>
  );
};

export default HomepageSectionContainer;