import { Link } from "react-router-dom";
import { LOCAL_STORAGE_KEYS } from "../constants/localStorageKeys";
import { PreviousSearchCacheResult } from "../types/types";
import magnifyingGlass from "../assets/search-gray.svg";

const SearchOverlayContentSavedSearches = () => {
  let savedSearches: PreviousSearchCacheResult[] = [];
  try {
    savedSearches = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.PREVIOUS_SEARCH_CACHE) || "[]");
  } catch (error) {
    console.log(error);
  }

  return (
    <>
      {savedSearches.length > 0 && (
        <section className="mb-2">
          <h4 className="font-yettel-bold mt-5">Ranije pretrage</h4>
          {savedSearches.map((search) => (
            <Link className="d-block mt-2" to={search.link} key={search.link}>
              <hr />
              <div className="d-flex mt-2 justify-content-between align-items-center">
                <span dangerouslySetInnerHTML={{ __html: search.html }} /> <img src={magnifyingGlass} width={18} height={18} />
              </div>
            </Link>
          ))}
        </section>
      )}
    </>
  )
}

export default SearchOverlayContentSavedSearches;