import { ShoppingMall } from "../types/types";
import checked from "../assets/checked.png";
import unchecked from "../assets/unchecked.png";
import { SEARCH_PARAMS_FILTERS } from "../constants/searchParamsFilters";
import { useSearchParams } from "react-router-dom";
import { useShoppingMallsQuery } from "../hooks/useFetchShoppingMalls";
import { modifySearchParamsForId } from "../utils/filters";
import "../scss/filter-list-checkbox.scss";
import "../scss/filter-list-container.scss";

const FilterShoppingMall: React.FC = () => {
  const { data: shoppingMalls } = useShoppingMallsQuery();
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedShoppingMalls = searchParams.get(SEARCH_PARAMS_FILTERS.SHOPPING_MALL_PARAM) || "";

  const handleShoppingMallClick = (shoppingMall: ShoppingMall) => {
    const alteredSearchParams = modifySearchParamsForId(searchParams, selectedShoppingMalls, shoppingMall.id, SEARCH_PARAMS_FILTERS.SHOPPING_MALL_PARAM);
    setSearchParams(alteredSearchParams);
  };

  return (
    <>
      {shoppingMalls && (
        <div className="filter-list__item pt-2">
          <div className="filter-list__name font-yettel-bold">Tržni centri</div>
          <div className="filter-list__fixed-height">
            {shoppingMalls &&
              shoppingMalls.map((shoppingMall) => (
                <div key={shoppingMall.id} className="filter-list__checkbox-item" onClick={() => handleShoppingMallClick(shoppingMall)}>
                  <div className="filter-list__checkbox-item__box">
                    <img width={20} height={20} alt="checkmark" src={selectedShoppingMalls.includes(shoppingMall.id) ? checked : unchecked} />
                    <div className="filter-list__checkbox-item__name d-inline-block">
                      {shoppingMall.name} {shoppingMall?.location_group.replace(" - sve opštine", "")}({shoppingMall?.count})
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
    </>
  );
};

export default FilterShoppingMall;
