import { useQuery } from "@tanstack/react-query";
import { Category, CategoryWithCount } from "../types/types";
import { QUERY_KEYS } from "../constants/queryKeys";
import { callPublicAPI } from "../utils/api";
import { STALE_TIME } from "../constants/staleTime";

export const useFetchCategories = () => {
  return useQuery<Category[]>({
    queryKey: [QUERY_KEYS.GET_CATEGORIES_WITH_COUNT, "withLimit100"],
    queryFn: async () => {
      const requestSearchParams = new URLSearchParams({
        withCount: "1",
        sort: "abc",
        limit: "100",
      });

      const response = await callPublicAPI<CategoryWithCount[]>("GET", "/categories", requestSearchParams);

      return response.data.filter((category) => category.ad_count > 0);
    },
    staleTime: STALE_TIME.HOUR,
  });
};
