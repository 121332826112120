import { useState } from 'react';
import { Category, YSH_GTM_EVENTS } from '../types/types';
import { Link } from 'react-router-dom';
import { pushToAnalytics } from '../utils/analytics';
import { APP_BASE } from '../constants/config';
import Sheet from 'react-modal-sheet';
import CategoryList from './CategoryList';
import close from '../assets/images/icons/icon-close.svg';
import '../scss/category-box.scss';

interface Props {
  category?: Category;
  isDefault?: boolean;
}

const CategoryBox: React.FC<Props> = ({ category, isDefault }) => {
  const hasAppBase = APP_BASE !== '/';
  const [isOpenModal, setIsOpenModal] = useState(false);

  const handleClickSpecificCategory = () => {
    pushToAnalytics(YSH_GTM_EVENTS.SELECT_COUPON_CATEGORY, { coupon_category: category?.name });
  };

  const handleClickAllCategories = () => {
    setIsOpenModal(true);
    pushToAnalytics(YSH_GTM_EVENTS.SELECT_COUPON_CATEGORY, { coupon_category: 'Sve kategorije' });
  };

  return (
    <>
      {isDefault && (
        <div className="category-box" onClick={handleClickAllCategories}>
          <div className="category-box__icon">
            <img
              width={30}
              height={30}
              src={`${hasAppBase ? APP_BASE : ''}/categories/all.png`}
              alt="slika koja predstavlja sve kategorije"
            />
          </div>
          <h3 className="category-box__text font-yettel fz-15">Sve kategorije</h3>
        </div>
      )}

      {!isDefault && (
        <Link to={`/ponude?category=${category?.id}`} className="category-box" onClick={handleClickSpecificCategory}>
          <div className="category-box__icon">
            {category?.icon && (
              <img
                width={30}
                height={30}
                src={`${hasAppBase ? APP_BASE : ''}/categories/${category?.icon}.png`}
                alt="slika kategorije"
              />
            )}
          </div>
          <h3 className="category-box__text font-yettel fz-15">{category?.name}</h3>
        </Link>
      )}

      {isDefault && (
        <Sheet isOpen={isOpenModal} onClose={() => setIsOpenModal(false)}>
          <Sheet.Container>
            <Sheet.Header>
              <div className="d-flex justify-content-end pt-3 pe-3">
                <img
                  className="cursor-pointer"
                  width={25}
                  height={25}
                  src={close}
                  alt="close modal"
                  onClick={() => setIsOpenModal(false)}
                />
              </div>
            </Sheet.Header>
            <Sheet.Content>
              <CategoryList />
            </Sheet.Content>
          </Sheet.Container>
          <Sheet.Backdrop onTap={() => setIsOpenModal(false)} />
        </Sheet>
      )}
    </>
  );
};

export default CategoryBox;
