import { useRouteError } from 'react-router-dom';
import errorImage from '../assets/error.png';
import Button from './Button';
import { logIn } from '../utils/auth';
import ErrorReport from './ErrorReport';

interface Props {
  customMessage?: string;
  showLoginButton?: boolean;
}

const ErrorPageContent: React.FC<Props> = ({ customMessage, showLoginButton }) => {
  const error = useRouteError();

  const handleLogin = () => {
    logIn();
  };

  return (
    <div className="full-width text-center mt-5" >
      <h1 className="fz-32 font-yettel-bold mb-2">Uuups!</h1>
      {!error && <h2 className="fz-24 font-yettel mb-4">Došlo je do greške.</h2>}

      {Boolean(error) && <ErrorReport error={error} />}

      {customMessage && <h2 className="fz-24 font-yettel mb-4">{customMessage}</h2>}
      {showLoginButton && <Button onClick={handleLogin}>Prijavi se</Button>}

      <img src={errorImage} alt="Greška" width={460} />
    </div>
  );
};

export default ErrorPageContent;
