import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { LOCAL_STORAGE_KEYS } from '../constants/localStorageKeys';
import { SEARCH_PARAMS } from '../constants/searchParamNames';
import { SESSION_STORAGE_KEYS } from '../constants/sessionStorageKeys';
import HeaderLocationListCommon from './HeaderLocationListCommon';
import { Location } from '../types/types';
import { useQueryGeolocation } from '../hooks/useQueryGeolocation';

const HeaderLocationListDynamic = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedLocations, setSelectedLocations] = useState<string[]>([]);
  const [shouldQueryGPS, setShouldQueryGPS] = useState<boolean>(false);
  const { data: userLocationByGPS } = useQueryGeolocation(shouldQueryGPS);

  const setLocations = () => {
    const locationValues = selectedLocations.join(',');

    // The source of truth is the useState variable `selectedLocations`.
    // `selectedLocations` is a string array.
    //
    // We store selected locations in sessionStorage to save it while navigating
    // to pages without a location selector.
    //
    // We manually reflect changes to the useState variable in searchParams
    // in order to read it across other components and append it to offer requests.
    if (selectedLocations.length === 0) {
      sessionStorage.removeItem(SESSION_STORAGE_KEYS.USER_SELECTED_LOCATIONS);

      searchParams.delete(SEARCH_PARAMS.LOCATION);
      setSearchParams(searchParams);
    } else {
      sessionStorage.setItem(SESSION_STORAGE_KEYS.USER_SELECTED_LOCATIONS, locationValues);

      searchParams.set(SEARCH_PARAMS.LOCATION, locationValues);
      setSearchParams(searchParams);
    }

    // Just in case, we store the locations in localStorage as well,
    // so we can use those locations if the user is currently logged out
    // but was/is a regular user.
    localStorage.setItem(LOCAL_STORAGE_KEYS.SELECTED_LOCATIONS, locationValues);
  };

  const handlePopupClosure = () => {
    searchParams.delete(SEARCH_PARAMS.LOCATIONS_MODAL);
    setSearchParams(searchParams);
  };

  const handleSubmit = () => {
    setLocations();
    handlePopupClosure();
  };

  const handleClick = (locationData: Location) => {
    const currentLocationValue = locationData.location_group;
    const indexInArray = selectedLocations.indexOf(currentLocationValue);
    const newArray: Array<string> = [...selectedLocations];
    if (indexInArray > -1) {
      newArray.splice(indexInArray, 1);
      setSelectedLocations(newArray);
    } else {
      newArray.push(currentLocationValue);
      setSelectedLocations(newArray);
    }
  };

  const useStringToSelectLocationsAndUpdateSearchParams = (locationsString: string) => {
    const locationsToSelect = locationsString.split(',');
    setSelectedLocations(locationsToSelect);

    searchParams.set(SEARCH_PARAMS.LOCATION, locationsString);
    setSearchParams(searchParams);
  };

  // On load, check session and local storage to see if there's usable previously set data.
  useEffect(() => {
    const previouslySelectedLocationsForSession = sessionStorage.getItem(SESSION_STORAGE_KEYS.USER_SELECTED_LOCATIONS);

    if (previouslySelectedLocationsForSession) {
      useStringToSelectLocationsAndUpdateSearchParams(previouslySelectedLocationsForSession);
    } else {
      const backupLocations = localStorage.getItem(LOCAL_STORAGE_KEYS.SELECTED_LOCATIONS);
      if (backupLocations && backupLocations.length > 0) {
        useStringToSelectLocationsAndUpdateSearchParams(backupLocations);
      } else {
        setShouldQueryGPS(true);
      }
    }
  }, []);

  // Use the location we've gotten from the GPS query.
  useEffect(() => {
    if (shouldQueryGPS && userLocationByGPS) {
      setSelectedLocations(userLocationByGPS);
    }
  }, [shouldQueryGPS, userLocationByGPS]);

  useEffect(() => {
    if (selectedLocations.length > 0 && shouldQueryGPS && userLocationByGPS) {
      setLocations();
    }
  }, [selectedLocations, shouldQueryGPS, userLocationByGPS]);

  return (
    <HeaderLocationListCommon
      handleSubmit={handleSubmit}
      handleClick={handleClick}
      selectedLocations={selectedLocations}
    />
  );
};

export default HeaderLocationListDynamic;
