export const SEARCH_PARAMS = {
  WORD: 'word',
  OFFER_ID: 'offerId',
  CATEGORY_IDS: 'categoryIds',
  CATEGORIES: 'categories',
  PARTNERS: 'partner',
  SEARCH: 'searchBarOpen',
  SEARCH_TERM_PARTNER_ID: 'withPartnerId',
  SHOW_CLOSEST_STORES_MAP: 'stores',
  SHOW_MAP_CATEGORIES: 'mapCategories',
  AUTO_LOGIN: 'autoLogin',
  TOKEN: 'token',
  LOCATION: 'location',
  LOCATIONS_MODAL: 'locationsModal',
  SHOW_PRIVACY_MODAL: 'privacyModal',
  SHOW_SHARE_OPTIONS: 'shareOptions',
  SHOW_OFFER_JUST_SAVED_MODAL: 'saved',
  PROMPT_LOGIN: 'promptLogin',
  CODE: 'code',
  STATE: 'state',
  REDIRECTED: 'redirected'
} as const;
