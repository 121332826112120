import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../constants/queryKeys";
import { callPublicAPI } from "../utils/api";
import { Location } from "../types/types";
import { STALE_TIME } from "../constants/staleTime";

interface Props {
  forLocation: string;
}

const MostPopularTitle: React.FC<Props> = ({ forLocation }) => {
  const { data } = useQuery<Location[]>({
    queryKey: [QUERY_KEYS.GET_LOCATIONS],
    queryFn: async () => {
      const response = await callPublicAPI<Location[]>( "GET", "/user-location-groups");
      return response.data;
    },
    staleTime: STALE_TIME.HOUR
  });	

  const locationWordVariant = data?.find((location) => location.location_group === forLocation)?.location_group_locative;

  return (
    <>Najpopularnije u {locationWordVariant}</>
  )
}

export default MostPopularTitle;