import { Link } from 'react-router-dom';
import { DisplayedIn, Offer } from '../types/types';
import OfferAction from './OfferAction';
import { IMAGE_BASE_URL } from '../constants/config';
import CouponsUsedCount from './CouponsUsedCount';
import OfferDiscountLabelContent from './OfferDiscountLabelContent';
import ExpirationInfo from './ExpirationInfo';
import useDynamicParamsLink from '../hooks/useDynamicParamsLink';
import '../scss/offer-wide.scss';

interface Props {
  offer: Offer;
  isUserLoggedIn?: boolean;
  imgLoadingType?: 'lazy' | 'eager';
  imgFetchPriority?: 'high' | 'low';
  displayedIn: DisplayedIn;
}

const OfferDisplayWide: React.FC<Props> = ({
  offer,
  isUserLoggedIn,
  imgLoadingType,
  imgFetchPriority,
  displayedIn
}) => {
  const displayCouponExpiration = Boolean(isUserLoggedIn && offer.code);
  const showDiscountLabel = Boolean(offer.discount_value && offer.original_price);
  const offerLink = useDynamicParamsLink('offerId', offer.id);

  return (
    <Link to={offerLink} className="offer-wide mb-2">
      <img
        alt={`${offer.partner_name} - ${offer.name}`}
        className="offer-wide__image"
        src={`${IMAGE_BASE_URL}/${offer.image0}`}
        loading={imgLoadingType}
        fetch-priority={imgFetchPriority}
      />
      {showDiscountLabel && (
        <div className="offer-wide__discount-label">
          <OfferDiscountLabelContent
            originalPrice={offer.original_price as number}
            discountedPrice={offer.discount_value as number}
          />
        </div>
      )}

      <section className="ps-2 pe-2 pb-2">
        <h3 className="offer-wide__partner font-yettel-bold fz-16 mt-2 mb-2">{offer.partner_name}</h3>
        <h4 className="offer-wide__name mb-3 font-yettel">{offer.name}</h4>

        <OfferAction offer={offer} isUserLoggedIn={Boolean(isUserLoggedIn)} displayedIn={displayedIn} />

        <section className="d-flex offer__info">
          <ExpirationInfo
            offer={offer}
            isCentered={true}
            lookForExpirationOf={displayCouponExpiration ? 'coupon' : 'offer'}
          />
          {Boolean(offer.used_code_count) && <CouponsUsedCount usedCount={offer.used_code_count} />}
        </section>
      </section>
    </Link>
  );
};

export default OfferDisplayWide;
