import { useQuery } from "@tanstack/react-query";
import { ShoppingMall } from "../types/types";
import { QUERY_KEYS } from "../constants/queryKeys";
import { callPublicAPI } from "../utils/api";
import { STALE_TIME } from "../constants/staleTime";

export const useShoppingMallsQuery = () => {
  return useQuery<ShoppingMall[]>({
    queryKey: [QUERY_KEYS.GET_SHOPPING_MALLS, "withLimit100"],
    queryFn: async () => {
      const requestSearchParams = new URLSearchParams({
        limit: "100",
      });

      const response = await callPublicAPI<ShoppingMall[]>("GET", "/shopping-malls", requestSearchParams);

      return response.data;
    },
    staleTime: STALE_TIME.HOUR,
  });
};
