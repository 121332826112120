import { useEffect, useState } from 'react';
import Sheet from 'react-modal-sheet';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { SingleOffer, YSHParams } from '../types/types';
import OfferOverlayContent from './OfferOverlayContent';
import { QUERY_KEYS } from '../constants/queryKeys';
import { useQuery } from '@tanstack/react-query';
import { callPublicAPI } from '../utils/api';
import SheetClose from './SheetClose';
import LoadingIndicator from './LoadingIndicator';
import '../scss/offer-overlay.scss';

const PartnerOfferOverlay = () => {
  const navigate = useNavigate();
  const params = useParams<YSHParams>();
  const partnerId = params.partnerId;
  const offerId = params.offerId;
  const [searchParams] = useSearchParams();
  const [isPopupActive, setIsPopupActive] = useState(false);

  const { isLoading, data: offer } = useQuery<SingleOffer>({
    queryKey: [QUERY_KEYS.GET_OFFER, offerId],
    queryFn: async () => {
      const response = await callPublicAPI<SingleOffer>('GET', `/offers/${offerId}`);
      return response.data;
    }
  });

  const closeModal = () => {
    setIsPopupActive(false);
    setTimeout(() => {
      const urlParams = searchParams.toString();
      navigate(`/near-by/partner/${partnerId}?${urlParams}`, { replace: true });
    }, 300);
  };

  useEffect(() => {
    setIsPopupActive(true);
  }, []);

  return (
    <Sheet isOpen={isPopupActive} onClose={closeModal} className="offer-overlay">
      <Sheet.Container>
        <Sheet.Header />
        <Sheet.Content>
          <>
            <SheetClose closeModal={closeModal} />
            <Sheet.Scroller>
              {offer && (
                <>
                  {isLoading && <LoadingIndicator />}
                  <OfferOverlayContent offer={offer} />
                </>
              )}
            </Sheet.Scroller>
          </>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop onTap={closeModal} />
    </Sheet>
  );
};

export default PartnerOfferOverlay;
