import cancel from "../assets/cancel.png";
import "../scss/offers-with-filters.scss";

interface Props {
  name?: string;
  handleOnClick: () => void;
}

const SelectedFilterButton: React.FC<Props> = ({ name, handleOnClick }) => {
  return (
    <div className="selected-filter-button" onClick={handleOnClick}>
      <span className="selected-filter-button__name fz-14">{name}</span>
      <img className="cancel-icon" width={20} height={20} alt="Cancel Icon" src={cancel} />
    </div>
  );
};

export default SelectedFilterButton;
