import ContentContainer from "./ContentContainer";
import "../scss/yettel-content.scss";

interface Props {
  children: React.ReactNode;
}

const Content: React.FC<Props> = ({children}) => {
  return (
    <main className="yettel-content d-flex">
      <ContentContainer>
        {children}
      </ContentContainer>
    </main>
  );
}

export default Content;
