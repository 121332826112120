import { Link } from "react-router-dom";
import { OfferDeleted, UserCoupon } from "../types/types";
import { IMAGE_BASE_URL } from "../constants/config";
import { MouseEventHandler } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { callPublicAPI } from "../utils/api";
import { QUERY_KEYS } from "../constants/queryKeys";
import trash from "../assets/trash.png";
import useDynamicParamsLink from "../hooks/useDynamicParamsLink";
import "../scss/expired-offer.scss";

interface Props {
  offer: UserCoupon;
}

const ExpiredOfferView: React.FC<Props> = ({
  offer
}) => {
  const queryClient = useQueryClient();

  const deleteOffer = useMutation({
    mutationFn: async () => {
      const response = await callPublicAPI<OfferDeleted>("DELETE", `/offers/${offer.id}/code`);
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_USER_EXPIRED_COUPONS],
      });
    },
    onError: () => {
      alert("Žao nam je, nismo uspeli da obrišemo kupon");
    }
  });

  const handleDeleteOffer: MouseEventHandler<HTMLImageElement> = (e) => {
    e.stopPropagation();
    e.preventDefault();
    
    deleteOffer.mutate();
  }

  const offerLink = useDynamicParamsLink("offerId", offer.id);

  return (
    <Link to={offerLink} className="expired-offer">
      <div className="d-flex justify-content-between">
        <span className="d-block font-yettel-light mb-3">{offer.partner_name}</span>
        <img onClick={handleDeleteOffer} src={trash} width={20} height={20} alt="delete offer" />
      </div>
      <div className="d-flex">
        <img className="expired-offer__img me-2" src={`${IMAGE_BASE_URL}/${offer.image0}`} width={99} height={50} />
        <span className="font-yettel-bold clamp-2">{offer.name}</span>
      </div>
    </Link>
  );
}

export default ExpiredOfferView;
