interface Props {
  children?: React.ReactNode;
}

const FullWidthPageContent: React.FC<Props> = ({ children }) => (
  <div className="content-container" style={{width: "100%"}}>
    {children}
  </div>
);

export default FullWidthPageContent;