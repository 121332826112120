import "../scss/page-title.scss";

interface Props {
  children?: React.ReactNode;
  className?: string;
}

const PageTitle: React.FC<Props> = ({
  children,
  className,
}) => {
  return (
    <h1 className={`page-title d-block fz-22 font-yettel-bold pt-3 pb-3 ps-3 pe-3 ${className}`}>{children}</h1>
  )
}

export default PageTitle