import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../constants/queryKeys";
import { Location } from "../types/types";
import { callPublicAPI } from "../utils/api";
import { STALE_TIME } from "../constants/staleTime";

export const useFetchAvailableLocations = () => {
  return useQuery<Location[]>({
    queryKey: [QUERY_KEYS.GET_LOCATIONS],
    queryFn: async () => {
      const response = await callPublicAPI<Location[]>('GET', '/user-location-groups');
      return response.data;
    },
    staleTime: STALE_TIME.HOUR
  });
};
