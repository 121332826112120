import { DisplayedIn, Offer } from "../types/types";
import OfferAction from "./OfferAction";
import { Link } from "react-router-dom";
import { IMAGE_BASE_URL } from "../constants/config";
import OfferDiscountLabelContent from "./OfferDiscountLabelContent";
import ExpirationInfo from "./ExpirationInfo";
import CouponsUsedCount from "./CouponsUsedCount";
import useDynamicParamsLink from "../hooks/useDynamicParamsLink";
import "../scss/offer.scss";

interface Props {
  offer: Offer;
  isUserLoggedIn?: boolean;
  showButton?: boolean;
  linkTo?: string;
  imgLoadingType?: "lazy" | "eager";
  imgFetchPriority?: "high" | "low";
  displayedIn: DisplayedIn;
}

const OfferDisplay: React.FC<Props> = ({ offer, isUserLoggedIn, showButton = true, linkTo, imgLoadingType, imgFetchPriority, displayedIn }) => {
  const displayCouponExpiration = isUserLoggedIn && offer.code;
  const showDiscountLabel = Boolean(offer.discount_value && offer.original_price);
  const offerLink = useDynamicParamsLink("offerId", offer.id);

  return (
    <Link to={linkTo ?? offerLink} className="offer mb-2">
      <img
        className="offer__image"
        alt={`${offer.partner_name} - ${offer.name}`}
        src={`${IMAGE_BASE_URL}/${offer.image0}`}
        width={207}
        height={103}
        loading={imgLoadingType}
        fetch-priority={imgFetchPriority}
      />
      {showDiscountLabel && (
        <div className="offer__discount-label">
          <OfferDiscountLabelContent originalPrice={offer.original_price as number} discountedPrice={offer.discount_value as number} />
        </div>
      )}

      <section className="ps-2 pe-2 pb-2">
        <h3 className="offer__partner fz-16 mt-4 mb-2 font-yettel-bold">{offer.partner_name}</h3>
        <h4 className="offer__name mb-3 font-yettel-light">{offer.name}</h4>

        {showButton && <OfferAction offer={offer} isUserLoggedIn={Boolean(isUserLoggedIn)} displayedIn={displayedIn} />}
      </section>
      <section className="d-flex offer__info">
        <ExpirationInfo offer={offer} isCentered={true} lookForExpirationOf={displayCouponExpiration ? "coupon" : "offer"}/>
        {Boolean(offer.used_code_count) && <CouponsUsedCount usedCount={offer.used_code_count} />}
      </section>
    </Link>
  )
}

export default OfferDisplay;
