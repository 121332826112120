import { YettelPetakHistoryActivation } from "../types/types";
import arrowRight from "../assets/images/arrows/icon-arrow-r.svg";
import { useState } from "react";
import PopupOverlay from "./PopupOverlay";
import PopupContainer from "./PopupContainer";
import { getPrizeValue, isStringPrizeType } from "../constants/prizes";
import { VOUCHER_STATUSES, VoucherStatus } from "../constants/voucherStatuses";
import { PROMOTION_STATUSES } from "../constants/promotionStatuses";
import { formatDate } from "../utils/util";
import { Link } from "react-router-dom";
import Button from "./Button";
import "../scss/yettel-petak-history-item-modal.scss";
import { PROVISIONED, USED } from "../constants/scratchCard";

interface Props {
  item: YettelPetakHistoryActivation;
}

const YettelPetakHistoryItem: React.FC<Props> = ({ item }) => {
  const [isPopupActive, setIsPopupActive] = useState(false);

  async function copyTextToClipboard(textToCopy: string) {
    try {
      await navigator.clipboard.writeText(textToCopy);
      console.log("copied to clipboard");
    } catch (error) {
      console.log("failed to copy to clipboard. error=" + error);
    }
  }

  const startDate = new Date(item.promotionDate);
  const endDate = new Date(item.voucherDetails?.validTo as string);
  const isVoucherType = item.promotionType === "VOUCHER";
  const voucherStatus =
    isVoucherType && item.voucherDetails
      ? item.voucherDetails.status.toUpperCase()
      : PROMOTION_STATUSES.PENDING;
  const statusText = isVoucherType
    ? VOUCHER_STATUSES[voucherStatus as VoucherStatus]
    : PROMOTION_STATUSES[item.promotionProvisioningStatus.toUpperCase()];

  const value = isStringPrizeType(item.renderType)
    ? getPrizeValue(item.renderType, item.promotionCategory, item.value)
    : item.value;

  return (
    <>
      <div onClick={() => setIsPopupActive(true)} className="profile-page-item">
        <div className="content-wrapper">
          <p className="text">{value}</p>
          <img className="arrow" src={arrowRight} alt="arrow right" />
        </div>
      </div>
      {isPopupActive && (
        <PopupOverlay onClick={() => setIsPopupActive(false)}>
          <PopupContainer
            isActive={isPopupActive}
            setIsActive={setIsPopupActive}
            handlePopupClosure={() => setIsPopupActive(false)}
            contentClassName={"yettel-petak-history-item-modal"}
          >
            <h2 className="mb-4 text-center">{value}</h2>
            <section className="font-yettel-light mb-5">
              <p className="mt-3 mb-3 text-center">
                Status:{" "}
                <span
                  className={
                    (!isVoucherType &&
                      item.promotionProvisioningStatus === PROVISIONED) ||
                    (isVoucherType && voucherStatus === USED.toLowerCase())
                      ? "status-active"
                      : "status-inactive"
                  }
                >
                  {statusText}
                </span>
              </p>
              <p className="mt-3 mb-3 text-center">
                Datum dobitka: {formatDate(startDate)}
              </p>
              {isVoucherType && (
                <p className=" text-center">
                  Datum isteka: {formatDate(endDate)}
                </p>
              )}
              <p className="mt-3 mb-3">{item.promotionStatusMessage}</p>
              {item.renderType === "linkAndButton" && (
                <>
                  <p
                    className="mt-3 mb-3 copy-link text-center"
                    onClick={() =>
                      copyTextToClipboard(item.metadata?.code as string)
                    }
                  >
                    Kopiraj kod
                  </p>
                  <br />
                </>
              )}
              {(item.renderType === "link" ||
                item.renderType === "linkAndButton") && (
                <Link to={item.metadata?.link as string}>
                  <Button>Poseti sajt</Button>
                </Link>
              )}
              {item.voucherDetails?.barcode && (
                <img
                  className="arrow"
                  src={`data:image/png;base64,${item.voucherDetails?.barcode}`}
                  alt="barcode"
                />
              )}
            </section>
          </PopupContainer>
        </PopupOverlay>
      )}
    </>
  );
};

export default YettelPetakHistoryItem;
