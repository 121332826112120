import { YettelPetakActivationsHistory } from "../types/types";
import { YettelPetakHistoryActivation } from "../types/types";
import { callPublicAPI } from "../utils/api";
import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../constants/queryKeys";
import YettelPetakHistoryItem from "./YettelPetakHistoryItem";
import { isUserLoggedIn } from "../utils/auth";
import PageTitle from "./PageTitle";
import "../scss/yettel-petak-history.scss";

const YettelPetakHistory = () => {
  const isLoggedIn = isUserLoggedIn();

  const { data: history } = useQuery<YettelPetakActivationsHistory>({
    queryKey: [QUERY_KEYS.GET_YETTEL_PETAK_HISTORY],
    queryFn: async () => {
      const response = await callPublicAPI<YettelPetakActivationsHistory>("GET", "/scratch-offers/history");
      return response.data;
    },
    enabled: isLoggedIn,
  });

  return (
    <div className="history-page">
      <PageTitle className="mb-4">Pregled dobitaka</PageTitle>
      {history?.activations?.map((item: YettelPetakHistoryActivation, index: number) => {
        return <YettelPetakHistoryItem item={item} key={`${item.value}_${index}`} />;
      })}
    </div>
  );
};

export default YettelPetakHistory;
