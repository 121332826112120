import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { debounce } from "lodash";
import { SEARCH_PARAMS } from "../constants/searchParamNames";
import LoadingIndicator from "./LoadingIndicator";
import useFocusInputOnMount from "../hooks/useFocusInputOnMount";
import { useSearchByTermQuery } from "../hooks/useSearchByTermQuery";
import SearchOverlayContentResults from "./SearchOverlayContentResults";
import SearchOverlayContentSavedSearches from "./SearchOverlayContentSavedSearches";
import "../scss/search-overlay-content.scss";

const SearchOverlayContent = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const preloadedWord = searchParams.get(SEARCH_PARAMS.WORD) || "";
  const [searchTerm, setSearchTerm] = useState(preloadedWord);
  const [enableQuery, setEnableQuery] = useState(false);
  const searchInput = useRef<HTMLInputElement>(null);
  const { isLoading, data: searchTermResults } = useSearchByTermQuery(searchTerm, enableQuery, setEnableQuery);

  useFocusInputOnMount(searchInput);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedChangeHandler = useCallback(
    debounce((value: string) => {
      if(value.length >= 3) {
        setEnableQuery(true);
      } else {
        setEnableQuery(false);
      }
    }, 300), []
  );
  
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setSearchTerm(event.target.value);
    debouncedChangeHandler(event.target.value);
  }

  const handleFormSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.stopPropagation();
    e.preventDefault();

    if(searchTerm.length >= 3) {
      navigate(`/ponude?word=${searchTerm}`);
    }
  }

  useEffect(() => {
    if(preloadedWord) {
      debouncedChangeHandler(preloadedWord);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="search-overlay-content pt-3">

      <div className="search-overlay-content-wrapper">

        <section>
          <header className="d-flex justify-content-between align-items-center mb-3">
            <h3>Pretraži proizvode i usluge</h3>
            <div className="search-overlay-content__close cursor-pointer" onClick={() => {
              searchParams.delete(SEARCH_PARAMS.SEARCH);
              setSearchParams(searchParams);
            }}></div>
          </header>
        
          <main>
            <form onSubmit={handleFormSubmit}>
              <input ref={searchInput} className="search-overlay-content__input mb-3" onChange={handleChange} type="text" value={searchTerm} />
            </form>
            {isLoading && <LoadingIndicator />}

            {searchTerm.length >=2 && !isLoading && searchTermResults && <SearchOverlayContentResults searchResults={searchTermResults} />}
          </main>
        </section>

        <footer>
          <SearchOverlayContentSavedSearches />
        </footer>
      </div>

    </section>
  )
}

export default SearchOverlayContent
