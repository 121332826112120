export const prizes = {
  "addon": {
    "digital_service": {
      "bookmate_loyalty": "10 GB interneta za Bookmate 30 dana",
      "hbo_loyalty": "10 GB interneta za HBO GO 30 dana",
      "deezer_loyalty": "10 GB interneta za Deezer 30 dana",
      "facebook_loyalty": "10 GB interneta za Facebook 30 dana",
      "viber_loyalty": "10 GB interneta za Viber 30 dana",
      "instagram_loyalty": "10 GB interneta za Instagram 30 dana",
      "whatsapp_loyalty": "10 GB interneta za WhatsApp 30 dana",
    },
    "min": {
      "50_min_consumer_1day_loyalty":
        "50 minuta koji važe 24 sata od trenutka aktivacije",
      "50_min_consumer_3days_loyalty":
        "50 minuta 3 dana od trenutka aktivacije",
      "100_min_consumer_1day_loyalty":
        "100 minuta koji važe 24 sata od trenutka aktivacije",
      "100_min_consumer_3days_loyalty":
        "100 minuta 3 dana od trenutka aktivacije",
    },
    "data": {
      "500mb_1_dan_loyalty": "500 MB koji važe 24 sata od trenutka aktivacije",
      "1gb_1_dan_loyalty": "1 GB koji važe 24 sata od trenutka aktivacije",
      "500mb_3_dan_loyalty": "500 MB 3 dana od trenutka aktivacije",
      "1gb_3_dan_loyalty": "1 GB 3 dana od trenutka aktivacije",
      "3gb_1_dan_loyalty": "3 GB koji važe 24 sata od trenutka aktivacije",
      "5gb_1_dan_loyalty": "5 GB koji važe 24 sata od trenutka aktivacije",
      "10gb_1_dan_loyalty": "10 GB koji važe 24 sata od trenutka aktivacije",
      "3gb_3_dan_loyalty": "3 GB 3 dana od trenutka aktivacije",
      "5gb_3_dan_loyalty": "5 GB 3 dana od trenutka aktivacije",
      "10gb_3_dan_loyalty": "10 GB 3 dana od trenutka aktivacije",
      "100mb_1_dan_loyalty": "100 MB koji važe 24 sata od trenutka aktivacije",
      "100mb_3_dana_loyalty": "100 MB 3 dana od trenutka aktivacije",
      "1gb_30_days_loyalty": "1 GB 30 dana od trenutka aktivacije",
    },
    "bundle": {
      "loyalty_neo_min_and_1_gb_1day":
        "1 GB i neograničeni minuti u Yettel mreži koji važe 24 sata od trenutka aktivacije",
      "loyalty_neo_min_and_1_gb_3days":
        "1 GB i neograničeni minuti u Yettel mreži 3 dana od trenutka aktivacije",
      "loyalty_200_min_and_2_gb_1day":
        "2 GB i 200 minuta u Yettel mreži koji važe 24 sata od trenutka aktivacije",
      "loyalty_200_min_and_2_gb_3_days":
        "2 GB i 200 minuta u Yettel mreži 3 dana od trenutka aktivacije",
      "loyalty_neo_min_and_10_gb_1day":
        "10 GB i neograničeni minuti u Yettel mreži koji važe 24 sata od trenutka aktivacije",
      "loyalty_neo_min_and_10_gb_3day":
        "10 GB i neograničeni minuti u Yettel mreži 3 dana od trenutka aktivacije",
      "1000_min_consumer_1_day_loyalty":
        "1000 minuta u Yettel mreži koji važe 24 sata od trenutka aktivacije",
      "1000_min_consumer_3_dats_loyalty":
        "1000 minuta u Yettel mreži 3 dana od trenutka aktivacije",
      "loyalty_300_min_and_3_gb_1day":
        "3 GB i 300 minuta u Yettel mreži koji važe 24 sata od trenutka aktivacije",
      "loyalty_300_min_and_3_gb_3days":
        "3 GB i 300 minuta u Yettel mreži 3 dana od trenutka aktivacije",
      "loyalty_400_min_and_4_gb_1day":
        "4 GB i 400 minuta u Yettel mreži koji važe 24 sata od trenutka aktivacije",
      "loyalty_400_min_and_4_gb_3days":
        "4 GB i 400 minuta u Yettel mreži 3 dana od trenutka aktivacije",
    },
  },
  "voucher": {
    "HANDSET": {
      "2500": "2500 dinara popusta na kupovinu telefona na rate",
      "5000": "5000 dinara popusta na kupovinu telefona na rate",
      "10000": "10.000 dinara popusta na kupovinu telefona na rate",
    },
    "ACCESSORY": {
      "500": "500 dinara popusta na dodatnu opremu",
      "1000": "1000 dinara popusta na dodatnu opremu",
      "2000": "2000 dinara popusta na dodatnu opremu",
    },
  },
};

export type PrizeType = keyof typeof prizes;
export type PrizeTypeAddonSubtype = keyof typeof prizes.addon;
export type PrizeTypeVoucherSubtype = keyof typeof prizes.voucher;

export const isStringPrizeType = (type: string): type is PrizeType => {
  return type in prizes;
}

export const isStringPrizeTypeAddonSubtype = (
  subtype: string
): subtype is PrizeTypeAddonSubtype => {
  return subtype in prizes.addon;
};

export const isStringPrizeTypeVoucherSubtype = (
  subtype: string
): subtype is PrizeTypeVoucherSubtype => {
  return subtype in prizes.voucher;
};

export const getPrizeValue = (
  renderType: PrizeType, 
  promotionCategory: PrizeTypeAddonSubtype | PrizeTypeVoucherSubtype, 
  defaultValue: string
): string => {
  if(renderType === "addon") {
    if(isStringPrizeTypeAddonSubtype(promotionCategory)) {
      const validKeys = Object.keys(prizes[renderType][promotionCategory]);
      if(validKeys.includes(defaultValue)) {
        // @ts-expect-error - we know that defaultValue is a valid key
        return prizes[renderType][promotionCategory][defaultValue] as string;
      } 
    }
  }

  if(renderType === "voucher") {
    if(isStringPrizeTypeVoucherSubtype(promotionCategory)) {
      const validKeys = Object.keys(prizes[renderType][promotionCategory as PrizeTypeVoucherSubtype]);
      if(validKeys.includes(defaultValue)) {
        // @ts-expect-error - we know that defaultValue is a valid key
        return prizes[renderType][promotionCategory][defaultValue] as string;
      }
    }
  }

  return defaultValue;
}