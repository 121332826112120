import { useQuery } from "@tanstack/react-query";
import { SearchSuggestionResult, SearchSuggestionResultWithTerm, YSH_GTM_EVENTS } from "../types/types";
import { QUERY_KEYS } from "../constants/queryKeys";
import { callPublicAPI } from "../utils/api";
import { STALE_TIME } from "../constants/staleTime";
import { mapSearchToAnalyticsObject, pushToAnalytics } from "../utils/analytics";

export const useSearchByTermQuery = (
  searchTerm: string, 
  enableQuery: boolean, 
  setEnableQuery:  (value: React.SetStateAction<boolean>) => void
) => useQuery<SearchSuggestionResultWithTerm>({
  queryKey: [
    QUERY_KEYS.GET_SEARCH_SUGGESTIONS,
    searchTerm
  ],
  queryFn: async ({ signal }) => {
    setEnableQuery(false);

    const searchParams = new URLSearchParams({
      word: searchTerm,
    });
    const response = await callPublicAPI<SearchSuggestionResult>("GET", "/offers/filter-info", searchParams, undefined, signal);
    
    const searchObject = mapSearchToAnalyticsObject(response.data);
    const numberOfResults = (response.data.categories.length + response.data.partners.length).toString()
    pushToAnalytics(YSH_GTM_EVENTS.SEARCH_APPLIED, {...searchObject}, { searchTerm, numberOfResults})

    return {
      ...response.data,
      term: searchTerm
    };
  },
  enabled: enableQuery,
  staleTime: STALE_TIME.HOUR
});
  
