import { useEffect, useState } from 'react';
import Sheet from 'react-modal-sheet';
import { DailyPrizeDescriptionModal } from './DailyPrizeDescriptionModal';
import { SESSION_STORAGE_KEYS } from '../constants/sessionStorageKeys.ts';
import { LOCAL_STORAGE_KEYS } from '../constants/localStorageKeys.ts';
import close from '../assets/yettel-close.png';
import { useFetchBanners } from '../hooks/useFetchBanners.ts';
import { Banner } from '../types/types.ts';
import { isDesktopView } from '../utils/util.ts';
import { BannerType } from '../constants/banners.ts';
import '../scss/daily-prize-modal.scss';

export const DailyPrizeModal = () => {
  const [isPrizePopupActive, setIsPrizePopupActive] = useState(false);
  const [isDescriptionPopupActive, setIsDescriptionPopupActive] = useState(false);
  const { data: banners } = useFetchBanners();
  const banner = banners?.find(banner => banner.bannerType === BannerType.popup);

  const isPrizeModalSeen = () => {
    if (banner?.showEveryTime) {
      return sessionStorage.getItem(SESSION_STORAGE_KEYS.IS_PRIZE_MODAL_SEEN) === 'true';
    } else {
      return localStorage.getItem(LOCAL_STORAGE_KEYS.IS_PRIZE_MODAL_SEEN) === 'true';
    }
  };

  const setAsSeen = () => {
    if (banner?.showEveryTime) {
      sessionStorage.setItem(SESSION_STORAGE_KEYS.IS_PRIZE_MODAL_SEEN, 'true');
    } else {
      localStorage.setItem(LOCAL_STORAGE_KEYS.IS_PRIZE_MODAL_SEEN, 'true');
    }
  };

  const onClosePrizePopup = () => {
    setAsSeen();
    setIsPrizePopupActive(false);
  };

  const onOpenDescriptionModal = () => {
    if (banner?.url && typeof banner.url === 'string' && banner.url.length > 0) {
      setAsSeen();
      setIsPrizePopupActive(false);
      document.location.assign(banner.url);
    } else {
      setIsPrizePopupActive(false);
      setIsDescriptionPopupActive(true);
    }
  };

  useEffect(() => {
    if (!isPrizeModalSeen()) {
      if (banner?.desktopImage || banner?.mobileImage) {
        setIsPrizePopupActive(true);
      } else if (banner?.htmlContent) {
        onOpenDescriptionModal();
      }
    }
  }, [banner]);

  if (!banner) return null;

  return (
    <>
      <Sheet isOpen={isPrizePopupActive} onClose={onClosePrizePopup} className="daily-prize-modal">
        <Sheet.Container>
          <Sheet.Content>
            <PrizeModalContent
              onClosePrizePopup={onClosePrizePopup}
              onOpenDescriptionModal={onOpenDescriptionModal}
              banner={banner}
            />
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={onClosePrizePopup} />
      </Sheet>

      <DailyPrizeDescriptionModal
        isActive={isDescriptionPopupActive}
        setIsActive={setIsDescriptionPopupActive}
        banner={banner}
      />
    </>
  );
};

interface PrizeModalContentProps {
  onClosePrizePopup: () => void;
  onOpenDescriptionModal: () => void;
  banner: Banner;
}

const PrizeModalContent: React.FC<PrizeModalContentProps> = ({ onClosePrizePopup, onOpenDescriptionModal, banner }) => {
  const imageSrc = isDesktopView()
    ? banner.desktopImage || banner.mobileImage
    : banner.mobileImage || banner.desktopImage;

  const isNotClickable = !banner.htmlContent && !banner.url;

  return (
    <div className={`daily-prize-modal ${isNotClickable ? 'daily-prize-modal--not-clickable' : ''}`}>
      <div className="content" onClick={e => e.stopPropagation()}>
        <img className="image" src={imageSrc + '?h=600'} alt="reward image" onClick={onOpenDescriptionModal} />
        <img className="close-icon" src={close} alt="close" onClick={onClosePrizePopup} width="30" height="30" />
      </div>
    </div>
  );
};
export default DailyPrizeModal;
