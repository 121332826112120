import close from '../assets/images/icons/icon-close.svg';
import '../scss/close-offer.scss';

interface SheetCloseProps {
  closeModal: () => void;
}

const SheetClose: React.FC<SheetCloseProps> = ({ closeModal }) => (
  <div className="close-offer cursor-pointer ps-3 pb-3" onClick={closeModal}>
    <img width={25} height={25} src={close} />
  </div>
);

export default SheetClose;
