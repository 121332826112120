import "../scss/popup-overlay.scss";

interface Props {
  children: React.ReactNode;
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
  className?: string;
}

const PopupOverlay: React.FC<Props> = ({ children, onClick, className }) => {
  return (
    <div className={`popup-overlay ${className ?? ""}`} onClick={onClick} >
      {children}
    </div>
  )
}

export default PopupOverlay
