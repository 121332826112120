import { useInfiniteQuery } from '@tanstack/react-query';
import HomepageSectionContainer from './HomepageSectionContainer';
import HomepageSectionTitle from './HomepageSectionTitle';
import { ApiResponse, QueryPageFn, ShoppingMall } from '../types/types';
import { QUERY_KEYS } from '../constants/queryKeys';
import ErrorMessage from './ErrorMessage';
import OfferList from './OfferList';
import ShoppingMallDisplay from './ShoppingMall';
import { callPublicAPI } from '../utils/api';
import { handleHorizontalScrollHelper } from '../utils/scrolling-queries';
import { STALE_TIME } from '../constants/staleTime';

const PAGE_SIZE = window.innerWidth > 800 ? 14 : 7;

const ShoppingMallsList = () => {
  const fetchMallsPage: QueryPageFn<ShoppingMall> = async (page = 1) => {
    const searchParams = new URLSearchParams({
      limit: PAGE_SIZE.toString(),
      offset: (page === 1 ? 0 : (page - 1) * PAGE_SIZE).toString()
    });

    const response = await callPublicAPI<ShoppingMall[]>('GET', '/shopping-malls', searchParams);
    return response;
  };

  const { data, isFetching, error, fetchNextPage } = useInfiniteQuery<ApiResponse<ShoppingMall[]>>({
    queryKey: [QUERY_KEYS.GET_SHOPPING_MALLS],
    queryFn: ({ pageParam = 1 }) => fetchMallsPage(pageParam as number | undefined),
    initialPageParam: 1,
    getNextPageParam: (_: ApiResponse<ShoppingMall[]>, allPages: ApiResponse<ShoppingMall[]>[]) => {
      const maxPages = 5;
      const nextPage = allPages.length + 1;
      return nextPage <= maxPages ? nextPage : undefined;
    },
    staleTime: STALE_TIME.HOUR
  });

  return (
    <>
      {error && <ErrorMessage>{error.message}</ErrorMessage>}

      <HomepageSectionContainer className="titled-row-of-shopping-malls">
        <HomepageSectionTitle>Ponuda u tržnim centrima</HomepageSectionTitle>

        <OfferList handleScroll={e => handleHorizontalScrollHelper(e, fetchNextPage, isFetching)}>
          {data &&
            data.pages.length &&
            data.pages.map(
              page =>
                page.data &&
                page.data.map(shoppingMall => (
                  <ShoppingMallDisplay
                    key={`${shoppingMall.location_group} - ${shoppingMall.name}`}
                    shoppingMall={shoppingMall}
                  />
                ))
            )}
        </OfferList>
      </HomepageSectionContainer>
    </>
  );
};

export default ShoppingMallsList;
