const getUsedText = (usedCount: number) => {
  const useVariant = usedCount === 1 || (usedCount > 11 && usedCount % 10) === 1;
  return (
    <>
      {usedCount} preuzet{useVariant ? '' : 'o'}
    </>
  );
};

const getUsedTextWithCouponWord = (usedCount: number) => {
  const useCouponVariant = usedCount === 1 || (usedCount > 11 && usedCount % 10) === 1;
  const useVariant = usedCount === 1 || (usedCount > 11 && usedCount % 10) === 1;

  return (
    <>
      {usedCount} kupon{useCouponVariant ? '' : 'a'} preuzet{useVariant ? '' : 'o'}
    </>
  );
};

interface Props {
  usedCount: number;
  isOfferOverlay?: boolean;
}

const CouponsUsedCount: React.FC<Props> = ({ usedCount, isOfferOverlay = false }) => {
  return (
    <div className={`${isOfferOverlay ? 'fz-16' : 'fz-14'} mb-2 pt-1 d-flex align-items-center justify-content-center`}>
      {isOfferOverlay ? getUsedTextWithCouponWord(usedCount) : getUsedText(usedCount)}
    </div>
  );
};

export default CouponsUsedCount;
