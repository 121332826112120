import { PreviousSearchCacheResult, SearchSuggestionResultWithTerm } from "../types/types"
import { Link } from "react-router-dom";
import { LOCAL_STORAGE_KEYS } from "../constants/localStorageKeys";
import "../scss/search-overlay-content-results.scss";

interface Props {
  searchResults: SearchSuggestionResultWithTerm
}

const SAVED_SEARCH_LIMIT = 5;

const SearchOverlayContentResults: React.FC<Props> = ({
  searchResults
}) => {
  const { term, categories, partners } = searchResults;

  const storeResultAsPreviousSearch = (link: string, html: string) => {
    try {      
      const previousSearches: PreviousSearchCacheResult[] = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.PREVIOUS_SEARCH_CACHE) || "[]");

      // Keep a list of #SAVED_SEARCH_LIMIT most recent previous searches.
      if(previousSearches.length >= SAVED_SEARCH_LIMIT) {
        while(previousSearches.length !< SAVED_SEARCH_LIMIT) {
          previousSearches.pop();
        }
      }

      const searchMap = new Map(previousSearches.map((item) => [item.html, item.link]));
      searchMap.set(html, link);

      const newPreviousSearches = Array.from(searchMap).map(([html, link]) => ({ html, link }));
      localStorage.setItem(LOCAL_STORAGE_KEYS.PREVIOUS_SEARCH_CACHE, JSON.stringify(newPreviousSearches));
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <section className="search-overlay-content-results font-yettel-light">
      {categories.length > 0 && categories.map((category) => (
        <Link 
          to={`/ponude?word=${term}&category=${category.id}`} 
          onClick={() => storeResultAsPreviousSearch(
            `/ponude?word=${term}&category=${category.id}`, 
            `<span class="font-yettel-bold">${term}</span> u ${category.name} | kategorija`
          )} 
          className="search-overlay-content-results__item d-block" 
          key={category.id}
        >
          <span className="font-yettel-bold">{term}</span> u {category.name} | kategorija
        </Link>
      ))}

      {partners.length > 0 && partners.map((partner) => (
        <Link 
          to={`/ponude?word=${term}&partner=${partner.id}`} 
          onClick={() => storeResultAsPreviousSearch(
            `/ponude?word=${term}&partner=${partner.id}`, 
            partner.name
          )}
          className="search-overlay-content-results__item d-block" 
          key={partner.id}
        >
          {partner.name}
        </Link>
      ))}
    </section>
  )
}

export default SearchOverlayContentResults