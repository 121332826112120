import Button from './Button';
import { MouseEventHandler, useRef, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS } from '../constants/queryKeys';
import { DisplayedIn, Offer, OfferSaved, YSH_GTM_EVENTS } from '../types/types';
import { callPublicAPI } from '../utils/api';
import { mapOfferToAnalyticsObject, pushToAnalytics } from '../utils/analytics';
import LoadingIndicator from './LoadingIndicator';

interface Props {
  offer: Offer;
  displayedIn: DisplayedIn;
  setShowOfferJustSaved: React.Dispatch<React.SetStateAction<boolean>>;
}

const OfferActionSave: React.FC<Props> = ({ offer, displayedIn, setShowOfferJustSaved }) => {
  const button = useRef<HTMLButtonElement>(null);
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleOfferSaveFail = () => {
    setIsLoading(false);
    alert('Žao nam je, nismo uspeli da sačuvamo kupon');
  };

  const handleSaveOfferSuccess = async (response: OfferSaved) => {
    if (!response) return handleOfferSaveFail();

    setIsLoading(false);
    setShowOfferJustSaved(true);

    // Očisti query za ovu individualnu ponudu ukoliko postoji u query cache-u.
    await queryClient.invalidateQueries({
      predicate: query => query.queryKey[0] === QUERY_KEYS.GET_OFFER && query.queryKey[1] === offer.id
    });

    pushToAnalytics(YSH_GTM_EVENTS.DOWNLOAD_COUPON, {
      coupon_details: [mapOfferToAnalyticsObject(offer, displayedIn)]
    });
  };

  const saveOffer = useMutation<OfferSaved>({
    mutationKey: [QUERY_KEYS.SAVE_OFFER, offer.id],
    mutationFn: async () => {
      setIsLoading(true);
      const response = await callPublicAPI<OfferSaved>('PUT', `/offers/${offer.id}/code`);
      return response.data;
    },
    onSuccess: handleSaveOfferSuccess,
    onError: handleOfferSaveFail
  });

  const handleClick: MouseEventHandler<HTMLButtonElement> = e => {
    e.preventDefault();
    e.stopPropagation();

    saveOffer.mutate();
  };

  return (
    <>
      <Button className="button--save-coupon" ref={button} onClick={handleClick} disabled={isLoading}>
        <span className="text">Sačuvaj kupon</span>
        {isLoading && <LoadingIndicator inverseColor={true} miniVersion={true} customMarginClasses="ms-2" />}
      </Button>
    </>
  );
};

export default OfferActionSave;
