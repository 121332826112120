import { useInfiniteQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '../constants/queryKeys';
import { QueryPageFn, Offer, ApiResponse } from '../types/types';
import { callPublicAPI } from '../utils/api';
import { getNextPageParamHelper } from '../utils/scrolling-queries';
import { SetStateAction } from 'react';
import { SEARCH_PARAMS } from '../constants/searchParamNames';
import { pushImpressionsToAnalytics } from '../utils/analytics';

const PAGE_SIZE = 24;

const fetchFilteredOffers: QueryPageFn<Offer> = async (page = 1) => {
  const fetchSearchParams = new URLSearchParams(window.location.search);

  fetchSearchParams.delete(SEARCH_PARAMS.OFFER_ID);
  fetchSearchParams.delete(SEARCH_PARAMS.SHOW_CLOSEST_STORES_MAP);
  fetchSearchParams.delete(SEARCH_PARAMS.SHOW_SHARE_OPTIONS);

  fetchSearchParams.set('limit', PAGE_SIZE.toString());
  fetchSearchParams.set('offset', (page === 1 ? 0 : (page - 1) * PAGE_SIZE).toString());

  const response = await callPublicAPI<Offer[]>('GET', '/offers/filter', fetchSearchParams);

  pushImpressionsToAnalytics(response.data, 'Ponude');

  return response;
};

export const useInfiniteFilteredOffers = (
  searchParams: URLSearchParams | undefined,
  setTotalNumber: (value: SetStateAction<number>) => void
) =>
  useInfiniteQuery<ApiResponse<Offer[]>>({
    queryKey: [QUERY_KEYS.GET_OFFERS, searchParams?.toString() || 'allOffers'],
    queryFn: async ({ pageParam = 1 }) => {
      const response = await fetchFilteredOffers(pageParam as number | undefined);

      setTotalNumber(response?.meta?.total || 0);
      return response;
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage: ApiResponse<Offer[]>, allPages: ApiResponse<Offer[]>[]) => {
      return getNextPageParamHelper(lastPage, allPages, PAGE_SIZE);
    }
  });
