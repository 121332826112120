import { PartnerMinimalInfo, SortedPartnerMinimalInfo } from '../types/types';
import { ALPHABET, CYRYLIC_ALPHABET, NUMBERS } from '../constants/alphabetLetters';

export const sortPartnersByLetter = (partners: PartnerMinimalInfo[]): SortedPartnerMinimalInfo => {
  const sortedPartners: SortedPartnerMinimalInfo['items'] = {};

  partners.forEach((partner, i) => {
    partner.index = i;
    let firstLetter = partner.name.charAt(0).toUpperCase();

    if (firstLetter === 'N' || firstLetter === 'L') {
      if (partner.name.charAt(1) === 'j') {
        firstLetter = firstLetter + 'j';
      }
    }

    if (!sortedPartners[firstLetter]) {
      sortedPartners[firstLetter] = [];
    }

    sortedPartners[firstLetter].push(partner);
  });

  const usedCharacters = Object.keys(sortedPartners);
  const letters = NUMBERS.filter(num => usedCharacters.includes(num))
    .concat(ALPHABET)
    .concat(CYRYLIC_ALPHABET.filter(char => usedCharacters.includes(char)));

  return {
    items: sortedPartners,
    letters
  };
};
