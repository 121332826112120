import checked from "../assets/checked.png";
import unchecked from "../assets/unchecked.png";
import { SEARCH_PARAMS_FILTERS } from "../constants/searchParamsFilters";
import { useSearchParams } from "react-router-dom";
import "../scss/filter-list-container.scss";
import "../scss/filter-list-checkbox.scss";
import { options } from "../constants/sortOptions";



const FilterTypePurchase: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const store = searchParams.get(SEARCH_PARAMS_FILTERS.STORE_PARAM) || "";

  const handleOptionClick = (clickedStore: string) => {
    const newStore = store === clickedStore ? "" : clickedStore;

    if(newStore === "") {
      searchParams.delete(SEARCH_PARAMS_FILTERS.STORE_PARAM);
    } else {
      searchParams.set(SEARCH_PARAMS_FILTERS.STORE_PARAM, newStore);
    }

    setSearchParams(searchParams);
  };

  return (
    <>
      <div className="pt-5 filter-list__name font-yettel-bold">Tip kupovine</div>
      {options.map((option) => (
        <div key={option.value} className={"pt-2 pb-2 filter-list__checkbox-item"} onClick={() => handleOptionClick(option.value)}>
          <div className="filter-list__checkbox-item__box">
            <img width={20} height={20} alt="checkmark" src={store === option.value ? checked : unchecked} />
            <div className="filter-list__checkbox-item__name d-inline-block">{option.label}</div>
          </div>
        </div>
      ))}
    </>
  );
};

export default FilterTypePurchase;
