interface Props {
  forWord: string;
  triggerSearch: () => void;
}

const OffersPageSearchResultsFor: React.FC<Props> = ({
  forWord,
  triggerSearch
}) => {
  return (
    <section className="full-width mb-4">
      <section className="d-flex justify-content-between">
        <span>Rezultati pretrage za "{forWord}"</span>
        <span onClick={triggerSearch} className="text-decoration-underline cursor-pointer">Izmeni</span>
      </section>
    </section>
  )
}

export default OffersPageSearchResultsFor;