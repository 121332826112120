import { LOCAL_STORAGE_KEYS } from "../constants/localStorageKeys";

function dec2hex(dec: number): string {
  return ('0' + dec.toString(16)).substr(-2);
}

export function generateCodeChallengeVerifier(): string {
  const array = new Uint32Array(56 / 2);
  window.crypto.getRandomValues(array);
  return Array.from(array, dec2hex).join('');
}

export function sha256(plain: string): Promise<ArrayBuffer> {
  // returns promise ArrayBuffer
  const encoder = new TextEncoder();
  const data = encoder.encode(plain);
  return window.crypto.subtle.digest('SHA-256', data);
}

export function base64urlencode(a: ArrayBuffer): string {
  let str = '';
  const bytes = new Uint8Array(a);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    str += String.fromCharCode(bytes[i]);
  }
  return btoa(str)
    ?.replace(/\+/g, '-')
    ?.replace(/\//g, '_')
    ?.replace(/=+$/, '');
}

async function generateCodeChallenge(v: string): Promise<string> {
  const hashed = await sha256(v);
  return base64urlencode(hashed);
}

export const getCodeChallenge = (): Promise<string> => {
  return new Promise(async (resolve) => {
    let codeChallengeVerifier = localStorage.getItem(LOCAL_STORAGE_KEYS.GENERATE_CODE_CHALLENGE_VERIFIER);

    if (!codeChallengeVerifier) {
      codeChallengeVerifier = generateCodeChallengeVerifier();
    }

    localStorage.setItem(LOCAL_STORAGE_KEYS.CODE_CHALLENGE_VERIFIER, codeChallengeVerifier);

    return generateCodeChallenge(codeChallengeVerifier).then((result) => {
      resolve(result);
    });
  });
}

