import { useNavigate } from "react-router-dom";
import Button from "./Button";
import useDynamicParamsLink from "../hooks/useDynamicParamsLink";

interface Props {
  offerId: string;
}

const OfferActionUseCoupon: React.FC<Props> = ({
  offerId,
}) => {
  const navigate = useNavigate();
  const offerLink = useDynamicParamsLink("offerId", offerId);

  const handleClick = () => {
    navigate(offerLink);
  }

  return (
    <Button className="button--saved-coupon" onClick={handleClick}>
      Iskoristi kupon
    </Button>
  )
}

export default OfferActionUseCoupon;
