import UserActiveCoupons from "./UserActiveCoupons"
import UserExpiredCoupons from "./UserExpiredCoupons"

const UserOffersPage = () => {
  return (
    <>
      <UserActiveCoupons />
      <UserExpiredCoupons />
    </>
  )
}

export default UserOffersPage
