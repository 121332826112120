import { Helmet } from "react-helmet";
import Button from "./Button";
import { isUserLoggedIn, logIn } from "../utils/auth";
import YettelPetakPage from "./YettelPetakPage";
import FullWidthPageContent from "./FullWidthPageContent";
import PageTitle from "./PageTitle";
import "../scss/yettel-petak.scss";

const YettelPetak = () => {
  const isLoggedIn = isUserLoggedIn();

  const handleLogin = () => {
    logIn();
  }

  return (
    <>
      <Helmet>
        <title>Yettel Petak | Yettel Shopping | Kuponi</title>
      </Helmet>
      <FullWidthPageContent>
        <PageTitle className="mb-4">Yettel Petak</PageTitle>
        {!isLoggedIn ? (
          <div className="yettel-petak-wrapper">
            <p>Prijavi se kako bi dobio pristup Yettel petku.</p>

            <Button className="mx-auto ms-0 mt-4" onClick={handleLogin}>Prijavi se</Button>
          </div>
        ) : (
          <>
            <YettelPetakPage />
          </>
        )}
      </FullWidthPageContent>
    </>
  );
};

export default YettelPetak;
