import React from 'react';
import { sortBy } from 'lodash';
import CommercialBanner from "./CommercialBanner";
import { useFetchBanners } from '../hooks/useFetchBanners.ts';
import { BannerType } from '../constants/banners.ts';
import "../scss/commercial-banner-list.scss";

const CommercialBannerList: React.FC = () => {
  const { data: bannersResponse } = useFetchBanners();
  const banners = sortBy(bannersResponse?.filter(banner => banner.bannerType === BannerType.horizontal), 'order');
  const bannerCount = banners?.length || 0;
  const calculatedWidth = (bannerCount * 268) + ((bannerCount - 1) * 15);

  return (
    <div className="commercial-banner-list-container mb-5 row-of-banners">
      <div className="commercial-banner-list mb-2" style={{ width: calculatedWidth }}>
        {banners?.map(banner => {
          return <CommercialBanner key={banner.id} banner={banner} />
        })}
      </div>
    </div>
  )
}

export default CommercialBannerList;
