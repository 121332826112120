import LoadingIndicator from "./LoadingIndicator"
import "../scss/global-loading-indicator.scss";

const GlobalLoadingIndicator = () => {
  return (
    <section className="global-loading-indicator">
      <LoadingIndicator />
    </section>
  )
}

export default GlobalLoadingIndicator