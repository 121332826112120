import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../constants/queryKeys";
import { callPublicAPI } from "../utils/api";
import { STALE_TIME } from "../constants/staleTime";
import { PartnerMinimalInfo } from "../types/types";

export const useFetchPartners = () => {
  return useQuery<PartnerMinimalInfo[]>({
    queryKey: [QUERY_KEYS.GET_PARTNERS, "withLimit1000"],
    queryFn: async () => {
      const requestSearchParams = new URLSearchParams({
        limit: "1000",
      });

      const response = await callPublicAPI<PartnerMinimalInfo[]>("GET", "/partners", requestSearchParams);

      return response.data;
    },
    staleTime: STALE_TIME.HOUR,
  });
};
