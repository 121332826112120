import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { logIn, parseJsonWebToken, userHasNalogCookie } from '../utils/auth';
import { LOCAL_STORAGE_KEYS } from '../constants/localStorageKeys';
import CheckConsent from './CheckConsent';
import FetchUserData from './FetchUserData';
import RefreshTokens from './RefreshTokens';

interface Props {
  redirected: boolean;
  jweToken: string | null;
}

interface CheckTokenValidity {
  accessTokenValid: boolean;
  refreshTokenValid: boolean;
}
const checkTokenValidity: () => CheckTokenValidity = () => {
  const accessTokenFromLocalStorage = localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN) || '';
  const refreshTokenFromLocalStorage = localStorage.getItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN) || '';
  const parsedAccessToken = parseJsonWebToken(accessTokenFromLocalStorage || '');
  const accessTokenValid = parsedAccessToken && Date.now() < parsedAccessToken.exp * 1000;
  const refreshTokenValid = Boolean(refreshTokenFromLocalStorage) && refreshTokenFromLocalStorage !== 'undefined';

  return {
    accessTokenValid: import.meta.env.PROD === true ? accessTokenValid : Boolean(accessTokenFromLocalStorage),
    refreshTokenValid
  };
};

const Authorize: React.FC<Props> = ({ redirected, jweToken }) => {
  const userHasCookie = userHasNalogCookie();
  const location = useLocation();

  // Check for edge case where SSO was not successfull (user is not logged in on nalog and therefore has no cookie)
  if (!userHasCookie && jweToken) {
    return <Navigate to={'/greska-tokom-sso-procesa'} />;
  }

  // Ignore logged out users and do nothing on the /login-redirect page.
  if (!userHasCookie || location.pathname === '/login-redirect') return <></>;

  // Just check consent if we've been redirected from the /login-redirect page.
  if (redirected) return <CheckConsent />;

  // From this point on, the user definitely has an account cookie - time to check token validity.
  const { accessTokenValid, refreshTokenValid } = checkTokenValidity();

  if (!accessTokenValid && refreshTokenValid) {
    return <RefreshTokens />;
  }

  if (accessTokenValid && refreshTokenValid) {
    return (
      <>
        <FetchUserData />
        <CheckConsent />
      </>
    );
  }

  if (!accessTokenValid && !refreshTokenValid) {
    logIn();
  }

  return <></>;
};

export default Authorize;
