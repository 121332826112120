import "../scss/checkmark.scss";

interface Props {
  checked: boolean;
  toggle: () => void;
}

const CheckMark: React.FC<Props> = ({
  checked,
  toggle
}) => {
  return (
    <div 
      className={`checkmark ${checked ? "checkmark--checked" : ""} cursor-pointer`}
      onClick={toggle}
    >
    </div>
  )
}

export default CheckMark