import Countdown from 'react-countdown';
import {
  getSecondsString,
  getMinutesString,
  getHoursString,
  isValidForLessThanOneMinute,
  validityEnd,
  isExpired
} from '../utils/date-functions';
import { ExpirationType, Offer, SingleOffer } from '../types/types';

type Props = {
  offer: SingleOffer | Offer;
  lookForExpirationOf: ExpirationType;
};

interface RendererProps {
  hours: number;
  minutes: number;
  seconds: number;
  completed: boolean;
}

const ExpirationCountdown: React.FC<Props> = ({ offer, lookForExpirationOf }) => {
  const renderer = ({ hours, minutes, seconds, completed }: RendererProps) => {
    if (completed) {
      return <></>;
    }

    return (
      <span className="countdown-expiration-text text-center">
        {isValidForLessThanOneMinute(validityEnd(offer, lookForExpirationOf), isExpired(offer, lookForExpirationOf))
          ? getSecondsString(seconds)
          : `${hours ? hours : ''} ${getHoursString(hours)} ${minutes} ${getMinutesString(minutes)} `}
      </span>
    );
  };

  return (
    <Countdown
      date={validityEnd(offer, lookForExpirationOf) ? (validityEnd(offer, lookForExpirationOf) as Date) : undefined}
      renderer={renderer}
    />
  );
};

export default ExpirationCountdown;
