import ProfilePageOption from "./ProfilePageOption";
import profile from "../assets/User.png";
import contact from "../assets/Letter.png";
import privacy from "../assets/privacy.png";
import { isBusinessUser, isRegisteredPrepaidUser } from "../utils/user";
import PrivacyModal from "./PrivacyModal";
import { SEARCH_PARAMS } from "../constants/searchParamNames";

const hasPrivacyEditingEnabled = () => {
  if (isBusinessUser()) {
    return false;
  }

  if (isRegisteredPrepaidUser()) {
    return false;
  }

  return true;
};

const ProfilePageContent = () => {
  const showPrivacyLink = hasPrivacyEditingEnabled();

  return (
    <>
      <ProfilePageOption linkTo="/profil/podesavanja" imgSrc={profile} imgHeight={30}>
        Moji podaci i podešavanja
      </ProfilePageOption>

      <ProfilePageOption linkTo="/profil/sugestije" imgSrc={contact} imgHeight={28}>
        Pošalji sugestiju
      </ProfilePageOption>

      {showPrivacyLink && (
        <ProfilePageOption linkTo={`/profil?${SEARCH_PARAMS.SHOW_PRIVACY_MODAL}=true`} imgSrc={privacy} imgHeight={28}>
          Privatnost podataka
        </ProfilePageOption>
      )}

      <PrivacyModal />
    </>
  );
};

export default ProfilePageContent;
