import Sheet from 'react-modal-sheet';
import Button from './Button';
import FilterCategory from './FilterCategory';
import FilterShoppingMall from './FilterShoppingMall';
import FilterTypeOffer from './FilterTypeOffer';
import FilterTypePurchase from './FilterTypePurchase';
import FilterPartner from './FilterPartner';
import { SEARCH_PARAMS_FILTERS } from '../constants/searchParamsFilters';
import close from '../assets/images/icons/icon-close.svg';
import { getFiltersAnalyticsObject, pushToAnalytics } from '../utils/analytics';
import { YSH_GTM_EVENTS } from '../types/types';
import '../scss/filter-list-container.scss';

interface Props {
  totalNumber: number;
  isPopupOpen: boolean;
  setIsPopupOpen: (open: boolean) => void;
}

const FilterListContainer: React.FC<Props> = ({ totalNumber, isPopupOpen, setIsPopupOpen }) => {
  const handleClosePopup = async () => {
    setIsPopupOpen(false);

    const fetchSearchParams = new URLSearchParams(window.location.search);
    const filtersObject = await getFiltersAnalyticsObject(fetchSearchParams);

    pushToAnalytics(YSH_GTM_EVENTS.FILTERS_APPLIED, { ...filtersObject });
  };

  return (
    <Sheet isOpen={isPopupOpen} onClose={handleClosePopup}>
      <Sheet.Container className="ps-3 pe-3">
        <Sheet.Header>
          <div className="d-flex justify-content-end pt-3">
            <img
              className="cursor-pointer"
              width={25}
              height={25}
              src={close}
              alt="close modal"
              onClick={handleClosePopup}
            />
          </div>
        </Sheet.Header>
        <Sheet.Content>
          <div className="filter-list">
            {[
              <FilterCategory key={SEARCH_PARAMS_FILTERS.CATEGORY_PARAM} />,
              <FilterShoppingMall key={SEARCH_PARAMS_FILTERS.SHOPPING_MALL_PARAM} />,
              <FilterPartner key={SEARCH_PARAMS_FILTERS.PARTNER_PARAM} />,
              <div key="filterType" className="filter-list__item pt-2">
                <FilterTypeOffer />
                <FilterTypePurchase />
              </div>
            ]}
          </div>
          <div className="filter-list__button d-flex justify-content-center full-width mt-3">
            <Button onClick={handleClosePopup}>Prikaži ({totalNumber})</Button>
          </div>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop onTap={handleClosePopup} />
    </Sheet>
  );
};

export default FilterListContainer;
