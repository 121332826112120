import arrowRight from '../assets/images/arrows/icon-arrow-r.svg';
import gift from '../assets/images/icons/icon-gift-card.png';
import { ScratchOffer } from '../types/types';
import YettelPetakCountdown from './YettelPetakCountdown';
import { useState } from 'react';
import { LOCAL_STORAGE_KEYS } from '../constants/localStorageKeys';
import { UNUSED } from '../constants/scratchCard';
import YettelPetakScratchCardModal from './YettelPetakScratchCardModal';
import YettelPetakConsentModal from './YettelPetakConsentModal';

interface Props {
  scratchOffer: ScratchOffer;
}

const YettelPetakScratchTrigger: React.FC<Props> = ({ scratchOffer }) => {
  const [isYettelPetakCountdownActive, setYettelPetakCountdownActive] = useState(false);
  const [isYettelPetakConsentActive, setYettelPetakConsentActive] = useState(false);
  const [isScratchCardActive, setScratchCardActive] = useState(false);
  const userMarketingConsent = localStorage.getItem(LOCAL_STORAGE_KEYS.USER_CONSENT_DIRECT_MARKETING_CONSENTED);

  const handleClick = () => {
    if (userMarketingConsent !== '0') {
      if (scratchOffer.status === UNUSED) {
        setScratchCardActive(true);
      } else {
        setYettelPetakCountdownActive(true);
      }
    } else {
      setYettelPetakConsentActive(true);
    }
  };

  return (
    <>
      <div onClick={handleClick} className="yettel-petak-item">
        <div className="icon-wrapper">
          <img className="icon" src={gift} alt="ogrebi i osvoji" />
        </div>
        <div className="content-wrapper">
          <p className="text">Ogrebi i osvoji</p>
          <img className="arrow" src={arrowRight} alt="arrow right" />
        </div>
      </div>

      <YettelPetakCountdown
        scratchOffer={scratchOffer}
        isModalActive={isYettelPetakCountdownActive}
        setIsModalActive={setYettelPetakCountdownActive}
      />

      <YettelPetakConsentModal
        isModalActive={isYettelPetakConsentActive}
        setIsModalActive={setYettelPetakConsentActive}
        setIsCountdownModalActive={setYettelPetakCountdownActive}
        setIsScratchCardActive={setScratchCardActive}
        scratchOfferReady={Boolean(scratchOffer?.next_scratch_at)}
      />

      <YettelPetakScratchCardModal
        scratchOffer={scratchOffer}
        isModalActive={isScratchCardActive}
        setIsModalActive={setScratchCardActive}
      />
    </>
  );
};

export default YettelPetakScratchTrigger;
