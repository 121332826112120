import { CATEGORY_IMAGE_SOURCES } from "../constants/categories";
import {Category, CategoryWithCount} from "../types/types";
import CheckboxChecked from "../assets/checkbox_checked.png";
import CheckboxUnchecked from "../assets/checkbox_unchecked.png";
import "../scss/user-selected-category.scss";

interface Props {
  category: CategoryWithCount | Category;
  categoriesMarkedSelected: string[];
  handleCategoryClick: (categoryId: Category) => void;
}

const CategoryCheckboxWithImage: React.FC<Props> = ({
  category,
  categoriesMarkedSelected,
  handleCategoryClick
}) => {
  return (
    <li
      className="user-selected-category cursor-pointer pe-3 ps-3 pt-2 pb-2 d-flex"
      onClick={() => handleCategoryClick(category)}
    >
      <div className="user-selected-category__title d-flex align-items-center">
        <img width={30} height={30} src={CATEGORY_IMAGE_SOURCES[category.icon]} />
        <span className="ps-4">{category.name}</span>
        {Boolean((category as CategoryWithCount).ad_count) && <span className="text-muted">&nbsp;({(category as CategoryWithCount).ad_count})</span>}
      </div>
      <img src={categoriesMarkedSelected.includes(category.id) ? CheckboxChecked : CheckboxUnchecked} />
    </li>
  )
}

export default CategoryCheckboxWithImage
