import { LOCAL_STORAGE_KEYS } from '../constants/localStorageKeys';

const hasExternalConsent = (): boolean => {
  const externalConsent = localStorage.getItem(LOCAL_STORAGE_KEYS.EXTERNAL_CONSENT);

  return externalConsent === 'true';
};

export const isBusinessUser = (): boolean => {
  const legalType = localStorage.getItem(LOCAL_STORAGE_KEYS.LEGAL_TYPE);

  return legalType === 'business';
};

const isPrepaidUser = (): boolean => {
  const legalType = localStorage.getItem(LOCAL_STORAGE_KEYS.LEGAL_TYPE);
  const type = localStorage.getItem(LOCAL_STORAGE_KEYS.TYPE);
  const paymentType = localStorage.getItem(LOCAL_STORAGE_KEYS.PAYMENT_TYPE);

  return legalType === 'consumer' && type === 'mobile' && paymentType === 'PREPAID';
};

export const isRegisteredPrepaidUser = (): boolean => {
  return isPrepaidUser() && hasExternalConsent();
};

export const isUnregisteredPrepaidUser = (): boolean => {
  return isPrepaidUser() && !hasExternalConsent();
};

export const isYettelUser = (): boolean => {
  const legalType = localStorage.getItem(LOCAL_STORAGE_KEYS.LEGAL_TYPE);
  const type = localStorage.getItem(LOCAL_STORAGE_KEYS.TYPE);
  const paymentType = localStorage.getItem(LOCAL_STORAGE_KEYS.PAYMENT_TYPE);

  return legalType === 'consumer' && type === 'mobile' && paymentType === 'POSTPAID';
};

export const isNonYettelUser = (): boolean => {
  const legalType = localStorage.getItem(LOCAL_STORAGE_KEYS.LEGAL_TYPE) || false;
  const paymentType = localStorage.getItem(LOCAL_STORAGE_KEYS.PAYMENT_TYPE) || false;

  return !legalType && !paymentType;
};

export const isFtthUser = (): boolean => {
  const type = localStorage.getItem(LOCAL_STORAGE_KEYS.TYPE);
  return type === 'ftth';
};
