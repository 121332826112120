import Sheet from 'react-modal-sheet';
import Button from './Button';
import { useMutation } from '@tanstack/react-query';
import { UpdateConsentRequestData, UserConsentUpdated } from '../types/types';
import { callPublicAPI } from '../utils/api';
import { isYettelUser } from '../utils/user';
import { persistUserConsentMutationInLocalStorage } from '../utils/auth';
import close from '../assets/images/icons/icon-close.svg';
import '../scss/yettel-petak-consent-modal.scss';

interface Props {
  isModalActive: boolean;
  setIsModalActive: (value: boolean) => void;
  setIsWizardModalActive?: (value: boolean) => void;
  setIsCountdownModalActive?: (value: boolean) => void;
  setIsScratchCardActive: (value: boolean) => void;
  scratchOfferReady?: boolean;
}

const YettelPetakConsentModal: React.FC<Props> = ({
  isModalActive,
  setIsModalActive,
  setIsWizardModalActive,
  setIsCountdownModalActive,
  setIsScratchCardActive,
  scratchOfferReady
}) => {
  const giveConsent = useMutation({
    mutationFn: async () => {
      const data: UpdateConsentRequestData = {
        direct_marketing_consented: 1
      };

      const response = await callPublicAPI<UserConsentUpdated>('POST', '/user-consents', null, data);

      return response.data;
    },
    onSuccess: data => {
      persistUserConsentMutationInLocalStorage(data);
      setIsModalActive(false);
      if (scratchOfferReady && setIsCountdownModalActive) {
        setIsCountdownModalActive(true);
      } else {
        setIsScratchCardActive(true);
      }
    },
    onError: () => {
      alert('Došlo je do greške prilikom promene politike privatnosti. Molimo Vas pokušajte ponovo kasnije.');
    }
  });

  const handleClosePopup = () => {
    setIsModalActive(false);
    if (setIsWizardModalActive) {
      setIsWizardModalActive(true);
    }
  };

  return (
    <>
      <Sheet isOpen={isModalActive} onClose={handleClosePopup} className="wizard-modal">
        <Sheet.Container>
          <Sheet.Header>
            <div className="d-flex justify-content-end pt-3">
              <img
                className="cursor-pointer"
                width={25}
                height={25}
                src={close}
                alt="close modal"
                onClick={handleClosePopup}
              />
            </div>
          </Sheet.Header>
          <Sheet.Content>
            <section className="d-flex align-items-center justify-content-center" style={{ height: '100%' }}>
              <div>
                <p className="text">
                  {isYettelUser()
                    ? 'Pristajem da Yettel obrađuje moje podatke o ličnosti u cilju kreiranja i dostavljanja komercijalnih i personalizovanih ponuda.'
                    : 'Pristajem da Yettel obrađuje moje podatke o ličnosti u cilju kreiranja i dostavljanja personalizovanih ponuda na Yettel aplikaciji i "Yettel Shopping".'}
                </p>
                <p className="text">
                  {isYettelUser()
                    ? 'Davanje pristanka je potrebno ukoliko želite da na osnovu analize podataka o ličnosti kreiramo za vas ponude kao što su: komercijalne i personalizovane ponude i popusti.'
                    : 'Davanje pristanka je potrebno ukoliko želite da na osnovu analize podataka o ličnosti kreiramo za vas ponude kao što su: personalizovane ponude i popusti, kao i specijalne Yettel ponude.'}
                </p>

                <div className="button-wrapper mt-3">
                  <Button onClick={() => giveConsent.mutate()}>Potvrdi</Button>
                </div>
              </div>
            </section>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={handleClosePopup} />
      </Sheet>
    </>
  );
};

export default YettelPetakConsentModal;
