import { queryClient } from '../config/queryClientFactory';
import { LOCAL_STORAGE_KEYS } from '../constants/localStorageKeys';
import { QUERY_KEYS } from '../constants/queryKeys';
import {
  AnalyticsUtility,
  Category,
  CouponAnalyticsData,
  DisplayedIn,
  FiltersAnalyticsData,
  FiltersAnalyticsReturnData,
  Offer,
  PartnerMinimalInfo,
  SearchAnalyticsData,
  SearchSuggestionResult,
  ShoppingMall,
  SingleOffer,
  WindowWithDatalayer,
  YSH_GTM_EVENTS
} from '../types/types';

const getUserId = () => {
  return localStorage.getItem(LOCAL_STORAGE_KEYS.GTM_USER_ID) || '';
};

const getActualTariffName = () => {
  return localStorage.getItem(LOCAL_STORAGE_KEYS.TP_NAME) || '';
};

const getCarrierType = () => {
  return localStorage.getItem(LOCAL_STORAGE_KEYS.TYPE) || '';
};

const getCustumerType = () => {
  return localStorage.getItem(LOCAL_STORAGE_KEYS.LEGAL_TYPE) || '';
};

const getPlanType = () => {
  return localStorage.getItem(LOCAL_STORAGE_KEYS.PAYMENT_TYPE) || '';
};

const getInteractionType = () => {
  return Boolean(
    (window.matchMedia && window.matchMedia('(display-mode: standalone)').matches) ||
      // @ts-expect-error - Fallback for IOS
      window.navigator.standalone
  ).valueOf()
    ? 'pwa'
    : 'web';
};

export const pushToAnalytics: AnalyticsUtility = (eventName, data = {}, searchObj) => {
  const dataLayerContainer = window as WindowWithDatalayer;

  if ('dataLayer' in dataLayerContainer) {
    const dataLayerEvent = {
      event: eventName,
      user_data: {
        user_id: getUserId(),
        actualTariffName: getActualTariffName(),
        carrierType: getCarrierType(),
        customerType: getCustumerType(),
        interactionType: getInteractionType(),
        propertyType: 'ysh',
        planType: getPlanType(),
        search_term: searchObj?.searchTerm,
        number_of_results: searchObj?.numberOfResults
      },
      ...data
    };

    dataLayerContainer.dataLayer.push(dataLayerEvent);
  }
};

type MappingFunction<T, U> = (arg: T) => Partial<U>;
type MappingFunctionWithListName<T, U> = (arg: T, listName?: DisplayedIn) => Partial<U>;
type AsyncMappingFunction<T, U> = (arg: T) => Promise<Partial<U>>;

export const mapOfferToAnalyticsObject: MappingFunctionWithListName<Offer, CouponAnalyticsData> = (offer, listName) => {
  const location = window.location.pathname;
  const pageName = getPageNameFromLocation(location);
  const impressionLocation = [pageName, listName].join(' - ');

  return {
    coupon_id: offer.id,
    coupon_name: offer.name,
    coupon_code: offer.code ?? '',
    coupon_brand: offer.partner_name,
    coupon_category: offer.partner_category_name ?? '',
    coupon_date: `${offer?.date_start}_${offer?.date_end}`,
    coupon_url: offer.url ?? '',
    coupon_list_name: impressionLocation,
    coupon_list_id: impressionLocation,
    coupon_price: offer.original_price ?? '',
    coupon_discount: offer.discount_value ?? ''
  };
};

export const mapSingleOfferToAnalyticsObject: MappingFunctionWithListName<SingleOffer, CouponAnalyticsData> = (
  offer,
  listName
) => {
  const location = window.location.pathname;
  const pageName = getPageNameFromLocation(location);
  const impressionLocation = [pageName, listName].join(' - ');

  return {
    coupon_id: offer.id,
    coupon_name: offer.name,
    coupon_code: offer.code ?? '',
    coupon_brand: offer.partner.name,
    coupon_category: offer.categories[0]?.name ?? '',
    coupon_date: `${offer?.date_start}_${offer?.date_end}`,
    coupon_url: offer.url ?? '',
    coupon_list_name: impressionLocation,
    coupon_list_id: impressionLocation,
    coupon_price: Number(offer.original_price) ?? '',
    coupon_discount: Number(offer.discount_value) ?? ''
  };
};

export const mapFiltersToAnalyticsObject: AsyncMappingFunction<
  FiltersAnalyticsData,
  FiltersAnalyticsReturnData
> = async filters => {
  const data: FiltersAnalyticsReturnData = {};
  const filterKeys = Object.keys(filters) as Array<keyof FiltersAnalyticsData>;

  for (const filter of filterKeys) {
    const tempVar = filters[filter as keyof FiltersAnalyticsData]!.toString().split(',');

    if (filter === 'category') {
      const cachedData = await queryClient.ensureQueryData<Category[]>({
        queryKey: [QUERY_KEYS.GET_CATEGORIES_WITH_COUNT, 'withLimit100']
      })!;
      const categories = tempVar.map(cat => {
        return cachedData.find(c => c.id === cat);
      }) as Category[];
      data[filter] = categories;
    } else if (filter === 'shopping-mall') {
      const cachedData = await queryClient.ensureQueryData<ShoppingMall[]>({
        queryKey: [QUERY_KEYS.GET_SHOPPING_MALLS, 'withLimit100']
      })!;
      const malls = tempVar.map(cat => {
        return cachedData.find(c => c.id === cat);
      }) as ShoppingMall[];
      data[filter] = malls;
    } else if (filter === 'partner') {
      const cachedData = await queryClient.ensureQueryData<PartnerMinimalInfo[]>({
        queryKey: [QUERY_KEYS.GET_PARTNERS, 'withLimit1000']
      })!;

      const partners = tempVar!.map(cat => {
        return cachedData.find(c => c.id === cat);
      }) as PartnerMinimalInfo[];

      data[filter] = partners;
    } else {
      data[filter] = tempVar;
    }
  }

  return data;
};

export const mapSearchToAnalyticsObject: MappingFunction<
  SearchSuggestionResult,
  SearchAnalyticsData
> = searchParams => {
  const data: Record<string, object[]> = {
    search_results_category: [],
    search_results_brand: []
  };
  searchParams.categories.forEach(category => {
    data.search_results_category.push({ ['coupon_category']: category.name, ['coupon_category_id']: category.id });
  });
  searchParams.partners.forEach(category => {
    data.search_results_brand.push({ ['coupon_brand']: category.name, ['coupon_brand_id']: category.id });
  });
  return data;
};

const getPageNameFromLocation = (location: string) => {
  if (location === '/') return 'HomePage';
  if (location === '/ponude') return 'OffersPage';
  if (location.includes('/near-by/partner')) return 'NearByPage';

  return '';
};

export const pushImpressionsToAnalytics = (offers: Offer[], listName: string) => {
  const location = window.location.pathname;
  const pageName = getPageNameFromLocation(location);
  const impressionLocation = [pageName, listName].join(' - ');

  const data = {
    coupon_details: [
      ...offers.map(offer => {
        return {
          ...mapOfferToAnalyticsObject(offer),
          coupon_list_name: impressionLocation,
          coupon_list_id: impressionLocation
        };
      })
    ]
  };

  if (offers.length > 0) {
    pushToAnalytics(YSH_GTM_EVENTS.COUPON_IMPRESSION, data);
  }
};

export const getFiltersAnalyticsObject = async (fetchSearchParams: URLSearchParams) => {
  const filtersObject: Record<string, string | number> = {};
  for (const [key, value] of fetchSearchParams) {
    filtersObject[key] = value;
  }
  const result = await mapFiltersToAnalyticsObject(filtersObject);
  return result;
};
