import { useSearchParams } from "react-router-dom";
import Sheet from 'react-modal-sheet';
import LoginPromptModal from "./LoginPromptModal";
import { SEARCH_PARAMS } from "../constants/searchParamNames";
import SheetClose from "./SheetClose";
import "../scss/offer-overlay.scss";

const LoginPromptOverlay = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const promptLogin = searchParams.get(SEARCH_PARAMS.PROMPT_LOGIN) || undefined;

  const closeModal = () => {
    searchParams.delete(SEARCH_PARAMS.PROMPT_LOGIN);
    setSearchParams(searchParams);
  }

  return (
    <>
      {promptLogin && (
        <Sheet isOpen={Boolean(promptLogin)} onClose={closeModal} className='offer-overlay'>
          <Sheet.Container>
            <Sheet.Header />
            <Sheet.Content>
              <>
                <SheetClose closeModal={closeModal} />
                <Sheet.Scroller>
                  <LoginPromptModal />
                </Sheet.Scroller>
              </>
            </Sheet.Content>
          </Sheet.Container>
          <Sheet.Backdrop onTap={closeModal} />
        </Sheet>
      )}
    </>
  )
}

export default LoginPromptOverlay;