import Sheet from 'react-modal-sheet';
import { useSearchParams } from 'react-router-dom';
import { Location } from '../types/types';
import { SEARCH_PARAMS } from '../constants/searchParamNames';
import close from '../assets/images/icons/icon-close.svg';
import LocationList from './LocationList';
import { useFetchAvailableLocations } from '../hooks/useFetchAvailableLocations';

interface Props {
  handleSubmit: () => void;
  handleClick: (location: Location) => void;
  selectedLocations: string[];
}

const HeaderLocationListCommon: React.FC<Props> = ({ handleSubmit, handleClick, selectedLocations }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const showLocationModal = searchParams.get(SEARCH_PARAMS.LOCATIONS_MODAL) ?? '';

  const { error, data: availableLocationsList } = useFetchAvailableLocations();

  const handlePopupClosure = () => {
    searchParams.delete(SEARCH_PARAMS.LOCATIONS_MODAL);
    setSearchParams(searchParams);
  };

  return (
    <Sheet isOpen={showLocationModal === 'true'} onClose={handlePopupClosure}>
      <Sheet.Container>
        <Sheet.Header>
          <div className="d-flex justify-content-end pt-3 pe-3">
            <img
              className="cursor-pointer"
              width={25}
              height={25}
              src={close}
              alt="close modal"
              onClick={handlePopupClosure}
            />
          </div>
        </Sheet.Header>
        <Sheet.Content>
          <LocationList
            handleSubmit={handleSubmit}
            handleLocationClick={handleClick}
            selectedLocations={selectedLocations}
            error={error}
            allAvailableLocations={availableLocationsList}
          />
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop onTap={handlePopupClosure} />
    </Sheet>
  );
};

export default HeaderLocationListCommon;
