import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '../constants/queryKeys';
import { callPublicAPI } from '../utils/api';

const useReportError = (shouldRunQuery: boolean, errorMessage: string, captchaToken: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.GET_USER_LOCATION_BY_GPS],
    queryFn: async () => {
      const errorData = { errorMessage };
      const captchaHeader = captchaToken ? { 'recaptcha-value': captchaToken } : undefined;
      const response = await callPublicAPI('POST', '/report/error', null, errorData, undefined, captchaHeader);

      return response;
    },
    enabled: shouldRunQuery
  });
};

export default useReportError;
