import { useSearchParams } from 'react-router-dom';
import { SEARCH_PARAMS } from '../constants/searchParamNames';
import { IoChevronDown } from 'react-icons/io5';
import { TbMapPin } from 'react-icons/tb';
import '../scss/location-list-container.scss';

const LocationListContainer = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const locations = searchParams.get(SEARCH_PARAMS.LOCATION) ?? '';

  const handleOpen = () => {
    searchParams.set(SEARCH_PARAMS.LOCATIONS_MODAL, 'true');
    setSearchParams(searchParams);
  };

  return (
    <>
      <h3 className="mb-2 d-flex justify-content-between align-items-center location-text">
        <span className="font-yettel-light text-decoration-underline fz-14 cursor-pointer" onClick={handleOpen}>
          <div className="pin-container">
            <TbMapPin width={25} height={25} />
          </div>
          {locations
            ? locations
                .split(',')
                .join(', ')
                .replace(/ - sve opštine/g, '')
            : 'Izaberi lokaciju'}
          <div className="chevron-container">
            <IoChevronDown />
          </div>
        </span>
      </h3>
    </>
  );
};

export default LocationListContainer;
