import { MouseEventHandler } from 'react';
import { useSearchParams } from 'react-router-dom';
import { SEARCH_PARAMS } from '../constants/searchParamNames';
import { DisplayedIn, Offer, YSH_GTM_EVENTS } from '../types/types';
import { mapOfferToAnalyticsObject, pushToAnalytics } from '../utils/analytics';
import Button from './Button';

interface Props {
  offer: Offer;
  displayedIn: DisplayedIn;
}

const OfferActionSaveLoggedOut: React.FC<Props> = ({ offer, displayedIn }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleClick: MouseEventHandler<HTMLButtonElement> = e => {
    e.preventDefault();
    e.stopPropagation();

    searchParams.set(SEARCH_PARAMS.PROMPT_LOGIN, 'true');
    setSearchParams(searchParams);
    pushToAnalytics(YSH_GTM_EVENTS.DOWNLOAD_COUPON_LOGIN_REMINDER, {
      coupon_details: [mapOfferToAnalyticsObject(offer, displayedIn)]
    });
  };

  return <Button onClick={handleClick}>Sačuvaj kupon</Button>;
};

export default OfferActionSaveLoggedOut;
