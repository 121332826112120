import { UIEventHandler } from "react";
import "../scss/offer-list.scss";

interface Props {
  children: React.ReactNode;
  handleScroll?: UIEventHandler<HTMLDivElement>;
  className?: string;
}

const OfferList: React.FC<Props> = ({ children, handleScroll, className }) => {
  return (
    <div 
      className={`offer-list d-flex ${className ?? ""}`} 
      onScroll={(event) => handleScroll && handleScroll(event)}>
      {children}
    </div>
  )
}

export default OfferList;