import { useInfiniteQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../constants/queryKeys";
import { UserCoupon, ApiResponse, Offer, QueryPageFn } from "../types/types";
import { callPublicAPI } from "../utils/api";
import { getNextPageParamHelper } from "../utils/scrolling-queries";
import { useFetchOnVerticalScroll } from "../hooks/useFetchOnVerticalScroll";
import ErrorMessage from "./ErrorMessage";
import OfferGrid from "./OfferGrid";
import LoadingIndicator from "./LoadingIndicator";
import Button from "./Button";
import OfferDisplayWide from "./OfferDisplayWide";
import { isUserLoggedIn } from "../utils/auth";

const PAGE_SIZE = 12;

const UserActiveCoupons = () => {
  const userLoggedIn = isUserLoggedIn();
  const fetchOffersPage: QueryPageFn<UserCoupon> = async (page = 1) => {
    const searchParams = new URLSearchParams({
      expired: "0",
      limit: PAGE_SIZE.toString(),
      offset: page === 1 ? "0" : ((page - 1) * PAGE_SIZE).toString(),
    });

    const response = await callPublicAPI<UserCoupon[]>("GET", "/offer-codes", searchParams);

    return response;
  };

  const { data, isLoading, isFetching, error, fetchNextPage, hasNextPage } = useInfiniteQuery<ApiResponse<UserCoupon[]>>({
    queryKey: [QUERY_KEYS.GET_USER_ACTIVE_COUPONS],
    queryFn: ({ pageParam = 1 }) => fetchOffersPage(pageParam as number | undefined),
    initialPageParam: 1,
    getNextPageParam: ((lastPage: ApiResponse<UserCoupon[]>, allPages: ApiResponse<UserCoupon[]>[]) => {
      return getNextPageParamHelper(lastPage, allPages, PAGE_SIZE);
    }),
  });

  useFetchOnVerticalScroll<ApiResponse<UserCoupon[]>>(fetchNextPage, isFetching, hasNextPage);

  return (
    <section className="mb-5">
      <h3 className="mb-2 ps-2">Aktivni kuponi</h3>

      {error && <ErrorMessage>{error.message}</ErrorMessage>}

      {data && data.pages.length > 0 && (
        <OfferGrid className="full-width mb-4">
          {data.pages.map((page) => (
            page.data.map((offer) => (
              <OfferDisplayWide key={offer.id} offer={offer as unknown as Offer} isUserLoggedIn={userLoggedIn} displayedIn="Aktivni kuponi" />
            ))
          ))}
        </OfferGrid>
      )}

      {!isFetching && hasNextPage && (<Button className="mt-3" onClick={() => fetchNextPage()}>Prikaži još</Button>)}

      {isLoading && (<LoadingIndicator />)}
    </section>
  )
}

export default UserActiveCoupons
