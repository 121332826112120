import { NavLink } from "react-router-dom";
import { MdOutlineCardGiftcard, MdOutlineHome } from "react-icons/md";
import { RiMapPin2Line, RiCoupon2Line } from "react-icons/ri";
import { FiUser } from "react-icons/fi";
import "../scss/mobile-navigation.scss";

const MobileNavigation = () => {
  return (
    <ul className="mobile-navigation">
      <li>
        <NavLink className={({isActive}) => isActive ? "active" : ""} to="/">
          <div className="mobile-navigation__icon">
            <MdOutlineHome/>
          </div>
        Pregled
        </NavLink>
      </li>
      <li>
        <NavLink className={({isActive}) => isActive ? "active" : ""} to="/kuponi">
          <div className="mobile-navigation__icon">
            <RiCoupon2Line/>
          </div>
          Kuponi
        </NavLink>
      </li>
      <li>
        <NavLink className={({isActive}) => isActive ? "active" : ""} to="/near-by">
          <div className="mobile-navigation__icon">
            <RiMapPin2Line/>
          </div>
          Mapa
        </NavLink>
      </li>
      <li>
        <NavLink className={({isActive}) => isActive ? "active" : ""} to="/yettel-petak">
          <div className="mobile-navigation__icon">
            <MdOutlineCardGiftcard />
          </div>
          Y. petak
        </NavLink>
      </li>
      <li>
        <NavLink className={({isActive}) => isActive ? "active" : ""} to="/profil">
          <div className="mobile-navigation__icon">
            <FiUser/>
          </div>
          Profil
        </NavLink>
      </li>
    </ul>
  )
}

export default MobileNavigation;
