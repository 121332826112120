import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '../constants/queryKeys';
import { callPublicAPI } from '../utils/api';
import { STALE_TIME } from '../constants/staleTime';
import { PartnerWithCount } from '../types/types';

export const useFetchPartnerCounts = (offset: number) => {
  return useQuery<PartnerWithCount[]>({
    queryKey: [QUERY_KEYS.GET_PARTNERS_WITH_COUNT, offset],
    queryFn: async () => {
      const requestSearchParams = new URLSearchParams({
        offset: offset.toString(),
        limit: '10'
      });

      const response = await callPublicAPI<PartnerWithCount[]>('GET', '/partners', requestSearchParams);

      return response.data;
    },
    staleTime: STALE_TIME.HOUR
  });
};
