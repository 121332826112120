import { IoChevronDown } from "react-icons/io5";
import { useEffect, useRef, useState } from "react";
import { SEARCH_PARAMS_FILTERS } from "../constants/searchParamsFilters";
import { useSearchParams } from "react-router-dom";

const SORT_OPTIONS = [
  { label: "Najnovije ponude", sort: "latest" },
  { label: "Najpopularnije", sort: "popular" },
  { label: "Najveći popust", sort: "discount" },
  { label: "Uskoro ističe", sort: "expiration" },
] as const;

const FilterSortContainer: React.FC = () => {
  const [isSortContainerActive, setIsSortContainerActive] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [sortLabel, setSortLabel] = useState<typeof SORT_OPTIONS[number]["label"]>("Najnovije ponude");
  const sortOptions = useRef<HTMLDivElement | null>(null);

  const handleSortOptionClick = (sort: string) => {
    searchParams.set(SEARCH_PARAMS_FILTERS.SORT_PARAM, sort);
    setSearchParams(searchParams);
    setIsSortContainerActive(false);
  };

  useEffect(() => {
    const sortQueryParam = searchParams.get(SEARCH_PARAMS_FILTERS.SORT_PARAM);
    const sortOption = SORT_OPTIONS.find((option) => option.sort === sortQueryParam);

    if(sortOption) {
      setSortLabel(sortOption.label);
    }

    const handleClickOutside = (event: MouseEvent) => {
      if (sortOptions.current && !sortOptions.current.contains(event.target as Node)) {
        setIsSortContainerActive(false);
      }
    };

    if (isSortContainerActive) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);


  return (
    <>
      <section className="filter-box__sort font-yettel fz-16 cursor-pointer ps-3" onClick={() => setIsSortContainerActive(!isSortContainerActive)}>
        {sortLabel} <div className="chevron-container filter-box__item__img"><IoChevronDown /></div>
        
        <div ref={sortOptions} className={`filter-box__sort-options ${!isSortContainerActive ? "d-none" : "d-block"} `}>
          {SORT_OPTIONS.map((option, index) => (
            <div key={index} className="filter-box__sort__item font-yettel-light fz-14 cursor-pointer" onClick={() => handleSortOptionClick(option.sort)}>
              {option.label}
            </div>
          ))}
        </div>
      </section>
      
    </>
  );
};

export default FilterSortContainer;
