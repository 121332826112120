import { useInfiniteQuery } from '@tanstack/react-query';
import { ApiResponse, Offer, QueryPageFn } from '../types/types';
import { QUERY_KEYS } from '../constants/queryKeys';
import { useSearchParams } from 'react-router-dom';
import HomepageSectionContainer from './HomepageSectionContainer';
import HomepageSectionTitle from './HomepageSectionTitle';
import ErrorMessage from './ErrorMessage';
import OfferDisplayWide from './OfferDisplayWide';
import OfferGrid from './OfferGrid';
import { callPublicAPI } from '../utils/api';
import { getNextPageParamHelper } from '../utils/scrolling-queries';
import { SEARCH_PARAMS } from '../constants/searchParamNames';
import { pushImpressionsToAnalytics } from '../utils/analytics';
import { useFetchOnVerticalScrollHomepageNewestOffers } from '../hooks/useFetchOnVerticalScrollHomepageNewestOffers';
import { isUserLoggedIn } from '../utils/auth';

const PAGE_SIZE = 24;

const NewestOffersList = () => {
  const isLoggedIn = isUserLoggedIn();
  const [searchParams] = useSearchParams();
  const location = searchParams.get(SEARCH_PARAMS.LOCATION) ?? undefined;

  const fetchOffersPage: QueryPageFn<Offer> = async (page = 1) => {
    const searchParams = new URLSearchParams({
      limit: PAGE_SIZE.toString(),
      offset: page === 1 ? '0' : ((page - 1) * PAGE_SIZE).toString()
    });

    if (location) {
      searchParams.set(SEARCH_PARAMS.LOCATION, location);
    }

    const response = await callPublicAPI<Offer[]>('GET', '/offers', searchParams);

    pushImpressionsToAnalytics(response.data, 'Najnovije ponude');
    return response;
  };

  const { data, isFetching, error, fetchNextPage, hasNextPage } = useInfiniteQuery<ApiResponse<Offer[]>>({
    queryKey: [QUERY_KEYS.GET_OFFERS, QUERY_KEYS.GET_OFFERS_VARIATION.NEWEST_OFFERS, location],
    queryFn: ({ pageParam = 1 }) => fetchOffersPage(pageParam as number | undefined),
    initialPageParam: 1,
    getNextPageParam: (lastPage: ApiResponse<Offer[]>, allPages: ApiResponse<Offer[]>[]) => {
      return getNextPageParamHelper(lastPage, allPages, PAGE_SIZE);
    }
  });

  useFetchOnVerticalScrollHomepageNewestOffers<ApiResponse<Offer[]>>(fetchNextPage, isFetching, hasNextPage);

  return (
    <>
      {error && <ErrorMessage>{error.message}</ErrorMessage>}

      <HomepageSectionContainer>
        <HomepageSectionTitle>Najnovije ponude</HomepageSectionTitle>
        <span data-infinite-scroll-marker="najnovije-ponude"></span>
        <OfferGrid>
          {data &&
            data.pages.length > 0 &&
            data.pages.map(page =>
              page.data.map(offer => (
                <OfferDisplayWide
                  key={offer.id}
                  offer={offer}
                  isUserLoggedIn={isLoggedIn}
                  imgLoadingType="lazy"
                  displayedIn="Najnovije ponude"
                />
              ))
            )}
        </OfferGrid>
      </HomepageSectionContainer>
    </>
  );
};

export default NewestOffersList;
