import { useQuery } from "@tanstack/react-query";
import { Offer } from "../types/types";
import { QUERY_KEYS } from "../constants/queryKeys";
import OfferDisplay from "./OfferDisplay";
import { useSearchParams } from "react-router-dom";
import HomepageSectionContainer from "./HomepageSectionContainer";
import HomepageSectionTitle from "./HomepageSectionTitle";
import ErrorMessage from "./ErrorMessage";
import OfferList from "./OfferList";
import { callPublicAPI } from "../utils/api";
import MostPopularTitle from "./MostPopularTitle";
import { SEARCH_PARAMS } from "../constants/searchParamNames";
import ShowAllOffersLink from "./ShowAllOffersLink";
import { pushImpressionsToAnalytics } from "../utils/analytics";
import { isUserLoggedIn } from "../utils/auth";

const MostPopularList = () => {
  const isLoggedIn = isUserLoggedIn();
  const [searchParams] = useSearchParams();
  const location = searchParams.get(SEARCH_PARAMS.LOCATION) ?? null;
  
  const { error, data: offers } = useQuery<Offer[]>({
    queryKey: [
      QUERY_KEYS.GET_OFFERS, 
      QUERY_KEYS.GET_OFFERS_VARIATION.SORT_POPULAR, 
      location
    ],
    queryFn: async () => {
      const searcParams = new URLSearchParams({
        sort: "sort_popular",
        limit: "6",
        location: location || "Cela Srbija",
      });

      const response = await callPublicAPI<Offer[]>("GET", "/offers", searcParams);

      pushImpressionsToAnalytics(response.data, "Najpopularnije");
      return response.data;
    }
  });

  const hasDefinitiveLocation = () => {
    return location?.split(",").length === 1;
  }

  return (
    <>
      {error && <ErrorMessage>{error.message}</ErrorMessage>}

      <HomepageSectionContainer className="titled-row-of-offers">
        <HomepageSectionTitle>{hasDefinitiveLocation() ? <MostPopularTitle forLocation={location as string} />: "Najpopularnije"}</HomepageSectionTitle>
        <OfferList>
          {offers && Boolean(offers.length) && offers.map((offer) => (
            <OfferDisplay 
              key={offer.id} 
              offer={offer} 
              isUserLoggedIn={isLoggedIn}
              imgLoadingType="lazy" 
              displayedIn="Najpopularnije"
            />
          ))}
          <ShowAllOffersLink sortType={'popular'} />
        </OfferList>
      </HomepageSectionContainer>
    </>
  )
}

export default MostPopularList;
