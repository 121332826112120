import clsx from 'clsx';
import ExpirationCountdown from './ExpirationCountdown';
import { ExpirationType, Offer, SingleOffer } from '../types/types';
import {
  getExpiration,
  isExpired,
  isValidForFiveOrLessDays,
  isValidForLessThanOneDay,
  validityEnd
} from '../utils/date-functions';
import '../scss/expiration-info.scss';

interface Props {
  offer: SingleOffer | Offer;
  isCentered?: boolean;
  lookForExpirationOf: ExpirationType;
  classNameOverride?: string;
}

const ExpirationInfo: React.FC<Props> = ({ offer, isCentered = false, lookForExpirationOf, classNameOverride }) => {
  const isExpiringLessThanFive = isValidForFiveOrLessDays(validityEnd(offer, lookForExpirationOf));
  const isExpiringLessThanOne = isValidForLessThanOneDay(validityEnd(offer, lookForExpirationOf));

  return (
    <div
      className={classNameOverride || clsx(
        ['expiration-info'],
        isExpiringLessThanFive && 'danger',
        'd-flex',
        isCentered && 'justify-content-center'
      )}
    >
      {!isExpired(offer, lookForExpirationOf) && (
        <div className="info-line">
          <span className="expiration-text">
            {lookForExpirationOf === 'coupon' ? 'Kupon' : 'Ponuda'} važi još
            {' '}
            {getExpiration(validityEnd(offer, lookForExpirationOf))}
            {!!validityEnd(offer, lookForExpirationOf) && isExpiringLessThanOne && (
              <ExpirationCountdown offer={offer} lookForExpirationOf={lookForExpirationOf} />
            )}
          </span>
        </div>
      )}
    </div>
  );
};

export default ExpirationInfo;
