import Sheet from 'react-modal-sheet';
import { useSearchParams } from 'react-router-dom';
import { SEARCH_PARAMS } from '../constants/searchParamNames';
import LoadingIndicator from './LoadingIndicator';
import OfferOverlayContent from './OfferOverlayContent';
import { useQuery } from '@tanstack/react-query';
import { SingleOffer } from '../types/types';
import { QUERY_KEYS } from '../constants/queryKeys';
import { callPublicAPI } from '../utils/api';
import SheetClose from './SheetClose';
import '../scss/offer-overlay.scss';

const OfferOverlay = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const offerId = searchParams.get(SEARCH_PARAMS.OFFER_ID) ?? '';

  const { isLoading, data: fetchedOffer } = useQuery<SingleOffer>({
    queryKey: [QUERY_KEYS.GET_OFFER, offerId],
    queryFn: async () => {
      const response = await callPublicAPI<SingleOffer>('GET', `/offers/${offerId}`);
      return response.data;
    },
    enabled: Boolean(offerId)
  });

  const closeModal = () => {
    searchParams.delete(SEARCH_PARAMS.OFFER_ID);
    setSearchParams(searchParams);
  };

  return (
    <>
      <Sheet isOpen={Boolean(offerId)} onClose={closeModal} className="offer-overlay">
        <Sheet.Container>
          <Sheet.Header />
          <Sheet.Content>
            <>
              <SheetClose closeModal={closeModal} />
              <Sheet.Scroller>
                {fetchedOffer && (
                  <>
                    {isLoading && <LoadingIndicator />}
                    <OfferOverlayContent offer={fetchedOffer} />
                  </>
                )}
              </Sheet.Scroller>
            </>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={closeModal} />
      </Sheet>
    </>
  );
};

export default OfferOverlay;
