import bgAdaMall from '/malls/beograd-ada-mall.webp';
import bgAvaShopping from '/malls/beograd-ava-shopping-park.webp';
import bgAvivPark from '/malls/beograd-aviv-park-zvezdara.webp';
import bgBeoShopping from '/malls/beograd-beo-shopping-center.webp';
import bgBigFashion from '/malls/beograd-big-fashion.webp';
import bgDeltaCity from '/malls/beograd-delta-city.webp';
import bgGalerija from '/malls/beograd-galerija.webp';
import bgImmo from '/malls/beograd-immo.webp';
import bgZemunPark from '/malls/beograd-zemun-park.webp';
import bgMercator from '/malls/beograd-mercator-centar.webp';
import bgRajiceva from '/malls/beograd-rajićeva.webp';
import bgStadion from '/malls/beograd-stadion.webp';
import bgUsce from '/malls/beograd-ušće.webp';
import bgSs from '/malls/beograd-stop-shop.webp';
import bgWest65 from '/malls/beograd-west65.webp';
import bgZira from '/malls/beograd-zira.webp';
import borcaSS from '/malls/borča-stop-shop.webp';
import inBig from '/malls/inđija-bfoutlet.webp';
import kgBig from '/malls/kragujevac-big-fashion.webp';
import kgRoda from '/malls/kragujevac-roda-centar.webp';
import krNest from '/malls/kraljevo-nest.webp';
import ksBig from '/malls/kruševac-big.webp';
import lzSS from '/malls/lazarevac-stop-shop.webp';
import leSS from '/malls/leskovac-stop-shop.webp';
import niDelta from '/malls/niš-delta-planet.webp';
import niForum from '/malls/niš-forum-shopping-center.webp';
import niRoda from '/malls/niš-roda-centar.webp';
import niSS from '/malls/niš-stop-shop.webp';
import npBig from '/malls/nova-pazova-big-pazova.webp';
import nsBig from '/malls/novi-sad-big.webp';
import nsMercator from '/malls/novi-sad-mercator-centar.webp';
import nsMerkur from '/malls/novi-sad-merkur.webp';
import nsPromenada from '/malls/novi-sad-promenada.webp';
import obrNest from '/malls/obrenovac-nest.webp';
import paBig from '/malls/pančevo-big.webp';
import poSS from '/malls/pozarevac-stop-shop.webp';
import raBig from '/malls/rakovica-big.webp';
import smSS from '/malls/smederevo-stop-shop.webp';
import soSS from '/malls/sombor-stop-shop.webp';
import srMiSS from '/malls/sremska-mitrovica-stop-shop.webp';
import suSS from '/malls/subotica-stop-shop.webp';
import uzNest from '/malls/uzice-nest.webp';
import vaSS from '/malls/valjevo-stop-shop.webp';
import vrNest from '/malls/vrsac-nest.webp';
import vrSS from '/malls/vrsac-stop-shop.webp';
import zaSS from '/malls/zajecar-stop-shop.webp';
import zrBig from '/malls/zrenjanin-big.webp';
import caSS from '/malls/cacak-stop-shop.webp';
import caRoda from '/malls/cacak-roda-centar.webp';
import saSS from '/malls/sabac-stop-shop.webp';
import bgTCM from '/malls/beograd(mladenovac)-TCM.webp';
import bgBnbos from '/malls/beograd-bnbos.webp';
import bgKaraburma from '/malls/beograd-karaburma.webp';
import bgZmaj from '/malls/beograd-zmaj.webp';
import bgPoint from '/malls/beogradg-point-centar.webp';
import gmShopPark from '/malls/gornji milanovac-shop-park.webp';
import jgVivo from '/malls/jagodina - vivo.webp';
import jgBiser from '/malls/jagodina-biser.webp';
import jgRodaCentar from '/malls/jagodina-roda-centar.webp';
import kgDeltaPark from '/malls/kragujevac-delta-park.webp';
import kgRadnicki from '/malls/kragujevac-radnicki.webp';
import nsBazaar from '/malls/novi-sad-baazar.webp';
import piPulsPark from '/malls/pirot-puls-park.webp';
import saRodaCentar from '/malls/sabac-roda-centar.webp';
import vaRodaCentar from '/malls/valjevo-roda-centar.webp';
import vrZona from '/malls/vranje-zona.webp';

export const shoppingMallImages: Record<string, string> = {
  'Beograd - sve opštine-Ada Mall': bgAdaMall,
  'Beograd - sve opštine-Delta City': bgDeltaCity,
  'Beograd - sve opštine-AVA Shopping Park': bgAvaShopping,
  'Beograd - sve opštine-Aviv Park Zvezdara': bgAvivPark,
  'Beograd - sve opštine-BEO Shopping Center': bgBeoShopping,
  'Beograd - sve opštine-BIG FASHION Mall & Park': bgBigFashion,
  'Beograd - sve opštine-Galerija': bgGalerija,
  'Beograd - sve opštine-Immo': bgImmo,
  'Beograd - sve opštine-Mercator Centar': bgMercator,
  'Beograd - sve opštine-Rajićeva': bgRajiceva,
  'Beograd - sve opštine-Stadion': bgStadion,
  'Beograd - sve opštine-Ušće': bgUsce,
  'Beograd - sve opštine-West 65 Mall': bgWest65,
  'Beograd - sve opštine-Zira': bgZira,
  'Beograd - sve opštine-Zemun Park': bgZemunPark,
  'Beograd - sve opštine-Stop Shop': bgSs,
  'Beograd - sve opštine-Nest': obrNest,
  'Beograd - sve opštine-TCM': bgTCM,
  'Beograd - sve opštine-BN Bos': bgBnbos,
  'Beograd - sve opštine-Karaburma': bgKaraburma,
  'Beograd - sve opštine-Zmaj': bgZmaj,
  'Beograd - sve opštine-Point Centar': bgPoint,
  'Beograd - sve opštine-Mladenovac': bgTCM,
  'Beograd - sve opštine-BIG Pančevo': paBig,
  'Gornji Milanovac-Shop Park': gmShopPark,
  'Jagodina-Vivo': jgVivo,
  'Jagodina-Biser': jgBiser,
  'Jagodina-Roda Centar': jgRodaCentar,
  'Borča-Stop Shop': borcaSS,
  'Inđija-BIG FASHION Outlet Inđija': inBig,
  'Inđija-BIG Pazova': inBig,
  'Kragujevac - sve opštine-BIG Fashion': kgBig,
  'Kragujevac - sve opštine-Roda Centar': kgRoda,
  'Kragujevac - sve opštine-Delta Park': kgDeltaPark,
  'Kragujevac - sve opštine-Radnički': kgRadnicki,
  'Kraljevo-Nest': krNest,
  'Kruševac-BIG': ksBig,
  'Lazarevac-Stop Shop': lzSS,
  'Leskovac-Stop Shop': leSS,
  'Niš - sve opštine-Delta Planet': niDelta,
  'Niš - sve opštine-Forum Shopping Center': niForum,
  'Niš - sve opštine-Roda Centar': niRoda,
  'Niš - sve opštine-Stop Shop': niSS,
  'Nova Pazova-BIG Pazova': npBig,
  'Novi Sad - sve opštine-Bazaar': nsBazaar,
  'Novi Sad - sve opštine-BIG Novi Sad': nsBig,
  'Novi Sad - sve opštine-Mercator Centar': nsMercator,
  'Novi Sad - sve opštine-Merkur': nsMerkur,
  'Novi Sad - sve opštine-Promenada': nsPromenada,
  'Obrenovac-Nest': obrNest,
  'Pirot-Puls': piPulsPark,
  'Požarevac-Stop Shop': poSS,
  'Beograd - sve opštine-BIG Rakovica': raBig,
  'Smederevo-Stop Shop': smSS,
  'Sombor-Stop Shop': soSS,
  'Sremska Mitrovica-Stop Shop': srMiSS,
  'Subotica-Stop Shop': suSS,
  'Užice-Nest': uzNest,
  'Valjevo-Stop Shop': vaSS,
  'Valjevo-Roda Centar': vaRodaCentar,
  'Vranje-Zona': vrZona,
  'Vršac-Nest': vrNest,
  'Vršac-Stop Shop': vrSS,
  'Zaječar-Stop Shop': zaSS,
  'Zrenjanin-BIG': zrBig,
  'Čačak-Stop Shop': caSS,
  'Čačak-Roda Centar': caRoda,
  'Šabac-Stop Shop': saSS,
  'Šabac-Roda Centar': saRodaCentar,
  default: bgAdaMall,
};
