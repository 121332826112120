import { useEffect, useState } from 'react';
import { DisplayedIn, Offer } from '../types/types';
import OfferActionJustSaved from './OfferActionJustSaved';
import OfferActionSave from './OfferActionSave';
import OfferActionSaveLoggedOut from './OfferActionSaveLoggedOut';
import OfferActionUseCoupon from './OfferActionUseCoupon';

interface Props {
  offer: Offer;
  isUserLoggedIn: boolean;
  displayedIn: DisplayedIn;
}

const OfferAction: React.FC<Props> = ({ offer, isUserLoggedIn, displayedIn }) => {
  const showUseCoupon = (isUserLoggedIn && offer.code) || displayedIn === 'Aktivni kuponi';
  const [showOfferJustSaved, setShowOfferJustSaved] = useState(false);
  const [forceShowUseCoupon, setForceShowUseCoupon] = useState(false);
  const showSaveCouponButton = isUserLoggedIn && !showOfferJustSaved && !showUseCoupon && !forceShowUseCoupon;
  const showSaveCouponLoginPromptButton = !isUserLoggedIn && !showOfferJustSaved;
  const showUseCouponButton = (showUseCoupon && !showOfferJustSaved) || forceShowUseCoupon;

  useEffect(() => {
    if (showOfferJustSaved) {
      setTimeout(() => {
        setShowOfferJustSaved(false);
        setForceShowUseCoupon(true);
      }, 1200);
    }
  }, [showOfferJustSaved]);

  return (
    <div className="position-relative">
      {showUseCouponButton && <OfferActionUseCoupon offerId={offer.id} />}

      {showSaveCouponButton && (
        <OfferActionSave offer={offer} displayedIn={displayedIn} setShowOfferJustSaved={setShowOfferJustSaved} />
      )}
      {showOfferJustSaved && <OfferActionJustSaved />}

      {showSaveCouponLoginPromptButton && <OfferActionSaveLoggedOut offer={offer} displayedIn={displayedIn} />}
    </div>
  );
};

export default OfferAction;
