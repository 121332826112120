import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '../constants/queryKeys';
import { UserSelectedLocationResponse } from '../types/types';
import { callPublicAPI } from '../utils/api';

export const useFetchUserSelectedLocations = (userLoggedIn: boolean) => {
  return useQuery<UserSelectedLocationResponse | []>({
    queryKey: [QUERY_KEYS.GET_USER_SELECTED_LOCATIONS],
    queryFn: async () => {
      const response = await callPublicAPI<UserSelectedLocationResponse | []>('GET', '/user-selected-location-groups');
      return response.data;
    },
    enabled: userLoggedIn
  });
};
