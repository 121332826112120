import { useQuery } from '@tanstack/react-query';
import { Offer } from '../types/types';
import { QUERY_KEYS } from '../constants/queryKeys';
import OfferDisplay from './OfferDisplay';
import { useSearchParams } from 'react-router-dom';
import HomepageSectionContainer from './HomepageSectionContainer';
import HomepageSectionTitle from './HomepageSectionTitle';
import ErrorMessage from './ErrorMessage';
import OfferList from './OfferList';
import { callPublicAPI } from '../utils/api';
import ShowAllOffersLink from './ShowAllOffersLink';
import { SEARCH_PARAMS } from '../constants/searchParamNames';
import { pushImpressionsToAnalytics } from '../utils/analytics';
import { isUserLoggedIn } from '../utils/auth';

const BiggestDiscountList = () => {
  const isLoggedIn = isUserLoggedIn();
  const [searchParams] = useSearchParams();
  const location = searchParams.get(SEARCH_PARAMS.LOCATION) || undefined;

  const { error, data: offers } = useQuery<Offer[]>({
    queryKey: [QUERY_KEYS.GET_OFFERS, QUERY_KEYS.GET_OFFERS_VARIATION.SORT_DISCOUNT, location],
    queryFn: async () => {
      const searchParams = new URLSearchParams({
        sort: 'sort_discount',
        limit: '6'
      });

      if (location) {
        searchParams.set('location', location);
      }

      const response = await callPublicAPI<Offer[]>('GET', '/offers', searchParams);

      pushImpressionsToAnalytics(response.data, 'Najveći popusti');
      return response.data;
    }
  });

  return (
    <>
      {error && <ErrorMessage>{error.message}</ErrorMessage>}

      <HomepageSectionContainer className="titled-row-of-offers">
        <HomepageSectionTitle>Najveći popusti</HomepageSectionTitle>
        <OfferList>
          {offers &&
            Boolean(offers.length) &&
            offers.map(offer => (
              <OfferDisplay
                key={offer.id}
                offer={offer}
                isUserLoggedIn={isLoggedIn}
                imgFetchPriority="high"
                imgLoadingType="eager"
                displayedIn="Najveći popusti"
              />
            ))}
          <ShowAllOffersLink sortType="discount" />
        </OfferList>
      </HomepageSectionContainer>
    </>
  );
};

export default BiggestDiscountList;
