import "../scss/commercial-banner.scss";
import { Banner } from '../types/types.ts';
import { isDesktopView } from '../utils/util.ts';

interface Props {
  banner: Banner;
}

const CommercialBanner: React.FC<Props> = ({ banner }) => {
  const imageSrc = isDesktopView() ? banner.desktopImage || banner.mobileImage : banner.mobileImage || banner.desktopImage;
  return (
    <a className="commercial-banner" href={banner.url} >
      <img
        width={268}
        height={316}
        src={imageSrc}
        alt={banner.name}
        loading="lazy"
        fetch-priority="low"
      />
    </a>
  )
}

export default CommercialBanner;
