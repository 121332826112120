import { useParams, useSearchParams } from 'react-router-dom';
import PageTitle from './PageTitle';
import { ApiResponse, Offer, QueryPageFn, YSHParams } from '../types/types';
import { callPublicAPI } from '../utils/api';
import { useInfiniteQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '../constants/queryKeys';
import { getNextPageParamHelper } from '../utils/scrolling-queries';
import { useFetchOnVerticalScroll } from '../hooks/useFetchOnVerticalScroll';
import ErrorMessage from './ErrorMessage';
import OfferGrid from './OfferGrid';
import OfferDisplayWide from './OfferDisplayWide';
import LoadingIndicator from './LoadingIndicator';
import { SEARCH_PARAMS } from '../constants/searchParamNames';
import { pushImpressionsToAnalytics } from '../utils/analytics';
import { isUserLoggedIn } from '../utils/auth';

const PAGE_SIZE = 24;

const SearchResultsPage = () => {
  const isLoggedIn = isUserLoggedIn();
  const [searchParams] = useSearchParams();
  const { searchTerm } = useParams<YSHParams>();
  const searchTermPartnerId = searchParams.get(SEARCH_PARAMS.SEARCH_TERM_PARTNER_ID) ?? undefined;

  const fetchOffersPage: QueryPageFn<Offer> = async (page = 1) => {
    const searchParams = new URLSearchParams({
      limit: PAGE_SIZE.toString(),
      offset: (page === 1 ? 0 : (page - 1) * PAGE_SIZE).toString()
    });

    if (searchTermPartnerId) {
      searchParams.set('partner_id', searchTermPartnerId);
    }

    if (searchTerm) {
      searchParams.set('keyword', searchTerm);
    }

    const response = await callPublicAPI<Offer[]>('GET', '/offers', searchParams);

    pushImpressionsToAnalytics(response.data, `Pretraga za termin: ${searchTerm}`);
    return response;
  };

  const { data, isLoading, isFetching, error, fetchNextPage, hasNextPage } = useInfiniteQuery<ApiResponse<Offer[]>>({
    queryKey: [QUERY_KEYS.GET_OFFERS, QUERY_KEYS.GET_OFFERS_VARIATION.FOR_SEARCH_TERM, location],
    queryFn: ({ pageParam = 1 }) => fetchOffersPage(pageParam as number | undefined),
    initialPageParam: 1,
    getNextPageParam: (lastPage: ApiResponse<Offer[]>, allPages: ApiResponse<Offer[]>[]) => {
      return getNextPageParamHelper(lastPage, allPages, PAGE_SIZE);
    },
    enabled: Boolean(searchTerm)
  });

  useFetchOnVerticalScroll<ApiResponse<Offer[]>>(fetchNextPage, isFetching, hasNextPage);

  return (
    <section>
      <PageTitle className="mb-2">Rezultati pretrage : {searchTerm}</PageTitle>

      {error && <ErrorMessage>{error.message}</ErrorMessage>}

      <section className="mb-5">
        {data && data.pages.length > 0 && (
          <OfferGrid>
            {data.pages.map(page =>
              page.data.map(offer => (
                <OfferDisplayWide
                  key={offer.id}
                  offer={offer}
                  isUserLoggedIn={isLoggedIn}
                  displayedIn="Rezultati pretrage"
                />
              ))
            )}
          </OfferGrid>
        )}

        {data && data.pages.length === 0 && <>Trenutno nema ponuda po zadatim kriterijumima</>}
      </section>

      {isLoading && <LoadingIndicator />}
    </section>
  );
};

export default SearchResultsPage;
