import { Category } from "../types/types";
import { SEARCH_PARAMS_FILTERS } from "../constants/searchParamsFilters";
import { useSearchParams } from "react-router-dom";
import { useFetchCategories } from "../hooks/useFetchCategories";
import "../scss/filter-list-container.scss";
import { modifySearchParamsForId } from "../utils/filters";

const FilterCategory: React.FC = () => {
  const { data: categories } = useFetchCategories();
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedCategoriesString = searchParams.get(SEARCH_PARAMS_FILTERS.CATEGORY_PARAM) || "";

  const categoriesList = searchParams.get(SEARCH_PARAMS_FILTERS.CATEGORY_PARAM) || "";

  const handleCategoryClick = (category: Category) => {
    const alteredSearchParams = modifySearchParamsForId(searchParams, selectedCategoriesString, category.id, SEARCH_PARAMS_FILTERS.CATEGORY_PARAM);
    setSearchParams(alteredSearchParams);
  };

  return (
    <>
      {categories && (
        <div className="filter-list__item">
          <div className="filter-list__name d-flex font-yettel-bold">Kategorije</div>
          {categories?.map((category) => (
            <div key={category.id} className={`${categoriesList.includes(category.id) ? "filter-list__round-button--active" : "filter-list__round-button"}`} onClick={() => handleCategoryClick(category)}>
              {category.name}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default FilterCategory;
