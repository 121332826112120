import { InfiniteData, InfiniteQueryObserverResult } from '@tanstack/react-query';
import { debounce } from 'lodash';
import { useEffect } from 'react';

type Hook = <T>(
  fetchCallback: () => Promise<InfiniteQueryObserverResult<InfiniteData<T>>>,
  isFetching: boolean,
  hasNextPage: boolean
) => void;

export const useFetchOnVerticalScrollHomepageNewestOffers: Hook = (fetchCallback, isFetching, hasNextPage) => {
  useEffect(() => {
    const marker = document.querySelector("[data-infinite-scroll-marker='najnovije-ponude']") as HTMLElement;
    let lastScrollTop = 0;

    const onScroll = async (event: Event) => {
      if (event.target instanceof Document) {
        const { scrollTop, clientHeight } = event.target.scrollingElement as Element;
        const portionScrolled: number = scrollTop + clientHeight;
        const offerListStartsAt: number = marker.getBoundingClientRect().top + window.scrollY;

        const isScrollingDown = scrollTop > lastScrollTop;
        lastScrollTop = scrollTop;

        // If we're at least half a screen heights' away from seeing offers, start fetching
        const shouldFetchMoreOffers = portionScrolled * 1.5 >= offerListStartsAt;

        if (hasNextPage && !isFetching && isScrollingDown && shouldFetchMoreOffers) {
          await fetchCallback();
        }
      }
    };

    const debouncedOnScroll = debounce(onScroll, 100);

    document.addEventListener('scroll', debouncedOnScroll);

    return () => {
      document.removeEventListener('scroll', debouncedOnScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching, hasNextPage]);
};
