import { useQuery } from "@tanstack/react-query"
import { QUERY_KEYS } from "../constants/queryKeys"
import { Category, CategoryWithCount } from "../types/types"
import ErrorMessage from "./ErrorMessage";
import { callPublicAPI } from "../utils/api";
import CategoryBox from "./CategoryBox";
import { STALE_TIME } from "../constants/staleTime";
import "../scss/category-box-list.scss";

const CategoryBoxList = () => {
  const { error, data } = useQuery<Category[]>({
    queryKey: [QUERY_KEYS.GET_CATEGORIES_WITH_COUNT, "withLimit7"],
    queryFn: async () => {
      const requestSearchParams = new URLSearchParams({
        withCount: "1",
        sort: "priority",
        limit: "7"
      });

      const response = await callPublicAPI<CategoryWithCount[]>("GET", "/categories", requestSearchParams);
      return response.data.filter((category) => category.ad_count > 0);
    },
    staleTime: STALE_TIME.HOUR
  })

  return (
    <>
      {error && <ErrorMessage>{error.message}</ErrorMessage>}

      <div className="category-box-list-container">
        <div className="category-box-list d-flex">
          {data?.map((category) => (
            <CategoryBox key={category.id} category={category} />
          ))}
          <CategoryBox key="default" isDefault />
        </div>
      </div>
    </>
  )
}

export default CategoryBoxList
