import { CATEGORY_IMAGE_SOURCES } from '../constants/categories';
import { MarkerEnums } from '../constants/googleMapMarkers';
import { PrizeType, PrizeTypeAddonSubtype, PrizeTypeVoucherSubtype } from '../constants/prizes';

export type ApiResponse<T> = {
  data: T;
  meta: {
    count: number;
    limit: number;
    total: number;
    offset: number;
  };
  statusCode: number;
  session?: {
    gtmUserId: string;
  };
  metadata: {
    trace: string;
    duration: string;
  };
};

export type SuggestionResult = {
  id: string;
  name: string;
};

export type SearchSuggestionResult = {
  categories: SuggestionResult[];
  partners: SuggestionResult[];
};

export type SearchSuggestionResultWithTerm = SearchSuggestionResult & {
  term: string;
};

export type Location = {
  location_group: string;
  location_group_locative: null | string;
};

export type UserSelectedLocationResponse = {
  location_groups: string;
  user_id: string;
};

type CategoryImageSourceKey = keyof typeof CATEGORY_IMAGE_SOURCES;

export type Category = {
  id: string;
  icon: CategoryImageSourceKey;
  name: string;
  ad_count?: number;
};

export type CategoryWithCount = Category & {
  ad_count: number;
};

export type ScratchOffer = {
  id: string;
  status: string;
  type: string;
  category: PrizeTypeAddonSubtype | PrizeTypeVoucherSubtype;
  activationMessage: string;
  value: string;
  genericProductName: string | null;
  renderType: string;
  next_scratch_at?: string;
};

export type ApiErrorResponse = {
  code: string;
  error: {
    type: string;
    description: string;
  };
  message: string;
  statusCode: number;
  userMessage: string;
  metadata: {
    timestamp: string;
    trace: string;
  };
};

export const isApiErrorResponse = (data: any): data is ApiErrorResponse => {
  return data && data.error !== undefined && data.code !== undefined;
};

export type ScratchOfferResponse = {
  data: ScratchOffer;
};

export interface WinTypeYettelPetak {
  promotionDate: string;
  promotionType: string;
  promotionCategory: string;
  promotionActivationMessage: string;
  promotionStatusMessage: string;
  promotionProvisioningStatus: string;
  typeId?: null;
  value: string;
  voucherDetails?: null[] | null;
  renderType: string;
}

export interface ConsentRequest {
  marketingConsent?: boolean;
  emailConsent?: boolean;
  phoneConsent?: boolean;
  redirectConsent?: boolean;
}

export type YettelPetakActivationsHistory = {
  activations: YettelPetakHistoryActivation[];
};
export type YettelPetakHistoryActivation = {
  promotionDate: string;
  promotionType: string;
  promotionCategory: PrizeTypeAddonSubtype | PrizeTypeVoucherSubtype;
  promotionActivationMessage: string;
  promotionStatusMessage: string;
  promotionProvisioningStatus: string;
  typeId?: null | string | number;
  value: string;
  voucherDetails?: {
    validTo: string;
    barcode: string;
    status: string;
  };
  renderType: PrizeType | 'link' | 'linkAndButton';
  metadata?: {
    code: string;
    link: string;
  };
};

export type ItemYettelPetak = {
  id: number;
  title: string;
  icon: string;
  page: string;
};

export type WithCode = 'common' | 'personalized' | 'no-code';

export type Offer = {
  id: string;
  code?: string;
  name: string;
  image0: string;
  date_start: string;
  date_end: Date;
  date_code_end?: string;
  discount_description: string;
  discount_percentage: number;
  discount_value: null | number;
  label_color: null | string;
  label_text: null | string;
  original_price: null | number;
  partner_id: string;
  partner_name: string;
  partner_locations: Store[];
  partner_category_name: keyof typeof MarkerEnums;
  pay: boolean;
  recommended: 0 | 1;
  url: string;
  used_code_count: number;
  with_code: WithCode;
};

export type DisplayedIn =
  | 'Preporučujemo'
  | 'Najveći popusti'
  | 'Najpopularnije'
  | 'Najnovije ponude'
  | 'Aktivni kuponi'
  | string;

export type SingleOfferCategory = {
  id: string;
  active: '1' | '0';
  icon: string;
  name: string;
  sort: string;
  subcategory_id: string;
  subcategory_name: string;
};

export type ExpirationType = 'coupon' | 'offer';

export type Partner = {
  id: string;
  name: string;
  pay: number;
  web: string;
  customer_contact_email: string;
  customer_contact_phone: string;
};

export type PartnerMinimalInfo = Pick<Partner, 'id' | 'name'> & { index?: number };

export type PartnerWithCount = {
  id: string;
  name: string;
  adCount?: number;
};

export type SortedPartnerMinimalInfo = {
  letters: string[];
  items: {
    [key: string]: PartnerMinimalInfo[];
  };
};

export type OfferStatus = 'expired' | 'published' | 'published_soon' | 'published_update' | 'code_spent' | 'stopped';

export type StoreLocationType = 'all' | 'offline' | 'online' | 'selected' | 'online-sel';

export type SingleOffer = Omit<Offer, 'partner_name' | 'partner_category_name'> & {
  description: string;
  categories: SingleOfferCategory[];
  conditions: string;
  date_code_end: string;
  date_code_start: string;
  image1: string;
  image2: string;
  image3: string;
  partner: Partner;
  status: OfferStatus;
  store: Store;
  store_location_type: StoreLocationType;
  used_code_count: number;
};

export type CodeDisplayData = {
  code: string;
  qr?: string;
  barcode?: string;
};

export type UserCoupon = {
  id: string;
  image0: string;
  name: string;
  date_start: string;
  with_code: WithCode;
  url: string;
  discount_value: number | null;
  original_price: number | null;
  date_end: string;
  date_code_end: string;
  partner_name: string;
  pay: false;
};

export type ShoppingMall = {
  id: string;
  name: string;
  full_name: string;
  address: string;
  location_group: string;
  count: number;
};

export type PartnerLocation = Pick<Store, 'id' | 'name' | 'address' | 'city' | 'lat' | 'lng'>;

export type Store = {
  id: string;
  address: string;
  partner_category_name: keyof typeof MarkerEnums;
  city: string;
  email: string;
  lat: number;
  lng: number;
  name: string;
  partner_id: string;
  partner_name: string;
  phone: string;
  saturday: string;
  sunday: string;
  work_days: string;
  isInCluster: false;
  used_code_count: number;
  label_text: string;
  label_color: string;
  recommended: number;
  category_id: string;
  isVisited: boolean;
};

export type MapStore = Store & {
  isVisited: boolean;
};

export type QueryPageFn<T> = (page?: number) => Promise<ApiResponse<T[]>>;
export type QueryPageWithAnalyticsFlagFn<T> = (page?: number, pushToAnalytics?: boolean) => Promise<ApiResponse<T[]>>;

export type CallAPIReturn = CategoryWithCount[];

export type CallAPIArgs = [method: RequestInit['method'], url: string, params?: URLSearchParams, data?: RequestData];

export type CallAPI<CallAPIReturn> = (...args: CallAPIArgs) => ApiResponse<CallAPIReturn>;

type RefreshTokenRequestData = {
  access_token: string;
  refresh_token: string;
};

type JWETokenExchangeRequestData = {
  jwe_token: string;
};

type UpdateUserRequestData = Partial<Omit<User, 'id' | 'user_id'>>;

type FeedbackRequestData = {
  feedback: string;
};

type UpdateSelectedCategoriesRequestData = string[];

export type UpdateConsentRequestData = Partial<{
  direct_marketing_consented: 0 | 1;
  phone_contact_consented: 0 | 1;
  email_contact_consented: 0 | 1;
  terms_and_conditions: 0 | 1;
}>;

export type SaveAllOffersRequestData = Partial<{
  section: string;
  category: string;
  partner: string;
  'shopping-mall': string;
  recommended: 0 | 1;
  store: string;
}>;

type ScratchCardSuccessRequestData = {
  id: string;
};

type SaveLocationRequestData = {
  location_groups: string;
};

type RequestErrorData = {
  errorMessage: string;
};

export type RequestData =
  | RefreshTokenRequestData
  | JWETokenExchangeRequestData
  | FeedbackRequestData
  | UpdateUserRequestData
  | UpdateSelectedCategoriesRequestData
  | UpdateConsentRequestData
  | ScratchCardSuccessRequestData
  | SaveLocationRequestData
  | SaveAllOffersRequestData
  | RequestErrorData;

export type GetRefreshTokenRequestData = {
  client_id: 'market';
  grant_type: 'refresh_token';
  refresh_token: string;
};

export type GetTokensRequestData = {
  client_id: 'market';
  grant_type: 'authorization_code';
  redirect_uri: string;
  code: string;
  code_verifier: string;
};

export type NalogRequestData = GetRefreshTokenRequestData | GetTokensRequestData;

export type Authorization = {
  sessionId: string;
  refresh_token: string;
  gtm_user_id: string;
  external_consent: boolean;
  legal_type: string;
  type: string;
  tp_name: string;
  payment_type: string;
};

export type JsonWebToken = {
  iss: string;
  aud: string;
  jti: string;
  iat: number;
  nbf: number;
  exp: number;
};

export type Tokens = {
  access_token: string;
  refresh_token: string;
};

export type User = {
  id: string;
  age: number;
  email: string;
  gender: 'M' | 'Ž' | '-';
  name?: string;
  user_id: string;
  msisdn?: string;
};

export type UserConsent = {
  uid: string;
  phone_contact_consented: 0 | 1;
  email_contact_consented: 0 | 1;
  direct_marketing_consented: 0 | 1;
  terms_and_conditions: 0 | 1;
  partner_redirection: 0 | 1;
  update_count: number;
  consent_shown: string;
};

export type UserConsentUpdated = {
  uid: string;
  consent_shown: string;
  direct_marketing_consented: 0 | 1;
  msisdn: string;
  partner_redirection: 0 | 1;
  update_count: number;
};

export type OfferSaved = {
  code: string;
};

export type OfferDeleted = {
  id: string;
  ad_id: string;
  user_id: string;
  code: string;
  claimed_at: string | null;
  deleted_at: null;
  award: string;
  section: string;
};

export type YSHParams = {
  shoppingMallId: string;
  partnerId: string;
  offerId: string;
  categoryName: string;
  searchTerm: string;
};

export const YSH_GTM_EVENTS = {
  COUPON_IMPRESSION: 'ysh_coupon_impression',
  SELECT_COUPON_CATEGORY: 'ysh_select_coupon_category',
  COUPON_DETAILS: 'ysh_coupon_details',
  COUPON_CONTACT_CLICK: 'ysh_coupon_contact_click',
  DOWNLOAD_COUPON: 'ysh_download_coupon',
  DOWNLOAD_COUPON_LOGIN_REMINDER: 'ysh_download_coupon_login_reminder',
  USE_THE_COUPON_ONLINE: 'ysh_use_the_coupon_online',
  CLOSEST_STORES: 'ysh_closest_stores',
  SHARE_COUPON: 'ysh_share_coupon',
  SCRATCH_AND_WIN_NOTIFICATION: 'ysh_pop_up_notification',
  SCRATCH_AND_WIN_OPEN: 'ysh_scratch_and_win_open',
  SCRATCH_AND_WIN_DONE: 'ysh_scratch_and_win_done',
  FILTERS_APPLIED: 'ysh_filter_applied',
  SEARCH_APPLIED: 'ysh_search'
} as const;

export type YshGtmEvent = (typeof YSH_GTM_EVENTS)[keyof typeof YSH_GTM_EVENTS];

export type CouponAnalyticsData = {
  coupon_id: string;
  coupon_name: string;
  coupon_code: string;
  coupon_brand: string;
  coupon_category: string;
  coupon_list_name: string;
  coupon_list_id: string;
  coupon_date: string;
  coupon_url: string;
};

export type FiltersAnalyticsReturnData = Partial<{
  category: Category[];
  partner: PartnerMinimalInfo[];
  'shopping-mall': ShoppingMall[];
  recommended: string[];
  store: string[];
  location: string[];
}>;

export type FiltersAnalyticsData = Omit<SaveAllOffersRequestData, 'section'> & { location?: string };

type SearchCategoryAnalyticsData = {
  coupon_category: string;
  coupon_category_id: string;
};

type SearchBrandAnalyticsData = {
  coupon_brand: string;
  coupon_brand_id: string;
};

export type SearchAnalyticsData = {
  search_results_category: SearchCategoryAnalyticsData[];
  search_results_brand: SearchBrandAnalyticsData[];
};

export type CouponAnalyticsDataForDownload = CouponAnalyticsData & {
  download_click_location: 'coupon details' | 'coupon list';
};

export type ShareOption = 'Viber' | 'Facebook' | 'WhatsApp' | 'LinkedIn' | 'Telegram' | 'Copy link';

export type CouponAnalyticsDataForShare = CouponAnalyticsData & {
  coupon_share_option_selected: ShareOption;
};

export type CouponDetailsAnalyticsData = {
  coupon_details: CouponAnalyticsData[];
};

export type SelectCouponCategoryAnalyticsData = {
  coupon_category: string;
};

export type CouponContactClickAnalyticsData = {
  option_selected: 'phone' | 'email' | 'website';
  coupon_details: CouponAnalyticsData[];
};

export type DownloadCouponAnalyticsData = {
  coupon_details: CouponAnalyticsDataForDownload[];
};

export type ShareCouponAnalyticsData = {
  coupon_details: CouponAnalyticsDataForShare[];
};

type AnalyticsData =
  | CouponDetailsAnalyticsData
  | SelectCouponCategoryAnalyticsData
  | CouponContactClickAnalyticsData
  | DownloadCouponAnalyticsData
  | ShareCouponAnalyticsData;

type SearchQueryData = {
  searchTerm: string;
  numberOfResults: string;
};

export type AnalyticsUtility = (
  eventName: YshGtmEvent,
  data: AnalyticsData | Record<string, unknown>,
  searchObj?: SearchQueryData
) => void;

export type DataLayerItem = {
  event: YshGtmEvent;
  user_data: {
    user_id: string;
    actualTariffName: string;
    carrierType: string;
    customerType: string;
    interactionType: string;
    propertyType: string;
    planType: string;
    search_term?: string;
    number_of_results?: string;
  };
} & Partial<AnalyticsData>;

export type WindowWithDatalayer = Window & typeof globalThis & { dataLayer: DataLayerItem[] };

export type PreviousSearchCacheResult = {
  link: string;
  html: string;
};

export type Banner = {
  id: number;
  order: number;
  name: string;
  alt: string;
  url: string;
  desktopImage: string;
  mobileImage: string;
  bannerType: '0' | '1';
  showEveryTime: 0 | 1;
  htmlContent: string;
};
