import '../scss/offer-grid.scss';
import { isDesktopView } from '../utils/util.ts';
import ArrowImage from '../assets/images/arrows/icon-arrow-r.svg';

interface Props {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

const OfferGrid: React.FC<Props> = ({ children, className, style }) => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  return (
    <div className={`offer-grid ${className ?? ''}`} style={style ?? {}}>
      {children}
      <img
        src={ArrowImage}
        className="offer-grid-floating-button"
        onClick={scrollToTop}
        style={{ right: isDesktopView() ? 50 : 5 }}
      />
    </div>
  );
};

export default OfferGrid;
