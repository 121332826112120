import OfferClosestStoresOverlay from "./OfferClosestStoresOverlay";
import OfferOverlay from "./OfferOverlay";
import ShareOfferOverlay from "./ShareOfferOverlay";

const OfferOverlayGroup = () => (
  <>
    <OfferOverlay />
    <OfferClosestStoresOverlay />
    <ShareOfferOverlay />
  </>
);

export default OfferOverlayGroup;
