import { useLocation, useParams } from 'react-router-dom';
import PageTitle from './PageTitle';
import { ApiResponse, Offer, QueryPageFn, YSHParams } from '../types/types';
import { callPublicAPI } from '../utils/api';
import { useInfiniteQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '../constants/queryKeys';
import ErrorMessage from './ErrorMessage';
import OfferGrid from './OfferGrid';
import OfferDisplayWide from './OfferDisplayWide';
import LoadingIndicator from './LoadingIndicator';
import { useFetchOnVerticalScroll } from '../hooks/useFetchOnVerticalScroll';
import { getNextPageParamHelper } from '../utils/scrolling-queries';
import { pushImpressionsToAnalytics } from '../utils/analytics';
import { isUserLoggedIn } from '../utils/auth';

const PAGE_SIZE = 12;

const ShoppingMallPage = () => {
  const isLoggedIn = isUserLoggedIn();
  const { state } = useLocation();
  const shoppingMallName = state?.shoppingMallName || '';
  const { shoppingMallId: id } = useParams<YSHParams>();

  const fetchOffersPage: QueryPageFn<Offer> = async (page = 1) => {
    const searchParams = new URLSearchParams({
      limit: PAGE_SIZE.toString(),
      offset: page === 1 ? '0' : ((page - 1) * PAGE_SIZE).toString(),
      shopping_mall_id: id as string
    });

    const response = await callPublicAPI<Offer[]>('GET', '/offers', searchParams);

    pushImpressionsToAnalytics(response.data, shoppingMallName);
    return response;
  };

  const { data, isLoading, isFetching, error, fetchNextPage, hasNextPage } = useInfiniteQuery<ApiResponse<Offer[]>>({
    queryKey: [QUERY_KEYS.GET_OFFERS, QUERY_KEYS.GET_OFFERS_VARIATION.FOR_SHOPPING_MALL, id],
    queryFn: ({ pageParam = 1 }) => fetchOffersPage(pageParam as number | undefined),
    initialPageParam: 1,
    getNextPageParam: (lastPage: ApiResponse<Offer[]>, allPages: ApiResponse<Offer[]>[]) => {
      return getNextPageParamHelper(lastPage, allPages, PAGE_SIZE);
    }
  });

  useFetchOnVerticalScroll<ApiResponse<Offer[]>>(fetchNextPage, isFetching, hasNextPage);

  return (
    <section className="mb-5">
      <PageTitle className="mb-2">{shoppingMallName}</PageTitle>
      <>
        {error && <ErrorMessage>{error.message}</ErrorMessage>}

        {data && data.pages.length > 0 && (
          <OfferGrid>
            {data.pages.map(page =>
              page.data.map(offer => (
                <OfferDisplayWide key={offer.id} offer={offer} isUserLoggedIn={isLoggedIn} displayedIn="Tržni centri" />
              ))
            )}
          </OfferGrid>
        )}

        {isLoading && <LoadingIndicator />}
      </>
    </section>
  );
};

export default ShoppingMallPage;
