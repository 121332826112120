import { useSearchParams } from "react-router-dom";
import { logIn } from "../utils/auth";
import { SEARCH_PARAMS } from "../constants/searchParamNames";
import redirect from "../assets/redirect.png";
import Button from "./Button";
import "../scss/login-prompt-modal.scss";

const LoginPromptModal: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleContinue = () => {
    searchParams.delete(SEARCH_PARAMS.PROMPT_LOGIN);
    setSearchParams(searchParams);
    logIn();
  }

  return (
    <section className="login-prompt-modal text-center" onClick={(e) => e.stopPropagation()}>
      <img className="mt-3 mb-3" src={redirect} alt="please login" width={227} height={192} />
      <p className="mb-3">Da biste preuzeli kupon potrebno je<br /> da se ulogujete na Yettel nalog.</p>
      <Button onClick={handleContinue}>Nastavi</Button>
    </section>
  )
}

export default LoginPromptModal;
