import { useSearchParams } from 'react-router-dom';
import { SEARCH_PARAMS } from '../constants/searchParamNames';
import SearchOverlayContent from './SearchOverlayContent';

const SearchOverlay = () => {
  const [searchParams] = useSearchParams();
  const showSearchOverlayContent = searchParams.get(SEARCH_PARAMS.SEARCH);

  return <>{showSearchOverlayContent && <SearchOverlayContent />}</>;
};

export default SearchOverlay;
