import { useMatch, useNavigate, useSearchParams } from 'react-router-dom';
import HeaderDesktop from './HeaderDesktop';
import HeaderMobile from './HeaderMobile';
import CategoryBoxList from './CategoryBoxList';
import HeaderLocationList from './HeaderLocationList';
import { SEARCH_PARAMS } from '../constants/searchParamNames';
import HeaderLocationListDynamic from './HeaderLocationListDynamic';

const Header = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isHomePage = useMatch('/');
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream;

  const navigateToHomepage = () => {
    const locationParams = searchParams.get(SEARCH_PARAMS.LOCATION);

    if (locationParams) {
      const path = ['/', `?${SEARCH_PARAMS.LOCATION}=`, encodeURIComponent(locationParams)].join('');
      navigate(path);
    } else {
      navigate('/');
    }
  };

  return (
    <>
      <HeaderMobile navigateToHomepage={navigateToHomepage} />
      <HeaderDesktop navigateToHomepage={navigateToHomepage} />

      {isHomePage && isIOS && <HeaderLocationList />}
      {isHomePage && !isIOS && <HeaderLocationListDynamic />}

      {isHomePage && <CategoryBoxList />}
    </>
  );
};

export default Header;
