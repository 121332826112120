import { CATEGORY_IMAGE_SOURCES } from "../constants/categories";
import { Category } from "../types/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { callPublicAPI } from "../utils/api";
import { QUERY_KEYS } from "../constants/queryKeys";
import Close from "../assets/close.svg";
import "../scss/user-selected-category-preview.scss";

interface Props {
  category: Category;
  selectedCategories: string[];
}

const UserSelectedCategoryPreview: React.FC<Props> = ({ 
  category,
  selectedCategories
}) => {
  const queryClient = useQueryClient();
  
  const saveSelectedCategories = useMutation({
    mutationFn: async () => {
      const data = selectedCategories.filter((categoryId) => categoryId !== category.id);
      const response = await callPublicAPI<string[]>("POST", "/user-selected-categories", null, data);
      return response;
    },
    onSuccess: () => {
      queryClient.setQueryData([QUERY_KEYS.GET_USER_SELECTED_CATEGORY_IDS], selectedCategories.filter((categoryId) => categoryId !== category.id));
    },
  });
  
  const handleCategoryDismiss = () => {
    saveSelectedCategories.mutate();
  }

  return (
    <li className="user-selected-category-preview pt-2 pb-2 ps-2 pe-2 mb-2 d-flex align-items-center">
      <div className="user-selected-category-preview__title d-flex align-items-center">
        <img width={30} height={30} src={CATEGORY_IMAGE_SOURCES[category.icon]} />
        <span className="ps-2">{category.name}</span>
      </div>

      <img 
        src={Close}
        width={24} 
        height={24} 
        alt="delete category" 
        className="cursor-pointer"
        onClick={() => handleCategoryDismiss()}
      />
    </li>
  )
}

export default UserSelectedCategoryPreview;