import '../scss/loading-indicator.scss';

interface Props {
  inverseColor?: boolean;
  miniVersion?: boolean;
  customMarginClasses?: string;
}

const LoadingIndicator: React.FC<Props> = ({ inverseColor, miniVersion, customMarginClasses }) => {
  return (
    <div className={`${customMarginClasses ? customMarginClasses : 'mb-2'}`}>
      <div
        className={`loading-indicator ${inverseColor && 'loading-indicator--alt'} ${miniVersion && 'loading-indicator--mini'}`}
      ></div>
    </div>
  );
};

export default LoadingIndicator;

