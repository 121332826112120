import { useQuery } from "@tanstack/react-query";
import { Banner } from '../types/types';
import { QUERY_KEYS } from "../constants/queryKeys";
import { callPublicAPI } from "../utils/api";

export const useFetchBanners = () => {
  return useQuery<Banner[]>({
    queryKey: [QUERY_KEYS.GET_BANNERS],
    queryFn: async () => {
      const response = await callPublicAPI<Banner[]>("GET", "/banners/active");
      return response.data;
    },
  });
};
