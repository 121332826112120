import { Helmet } from "react-helmet"
import { isUserLoggedIn, logIn, removeUserDataFromLocalStorage } from "../utils/auth"
import FullWidthPageContent from "./FullWidthPageContent"
import ProfilePageContent from "./ProfilePageContent"
import Button from "./Button"
import PageTitle from "./PageTitle"
import { NALOG, PUBLIC_API } from "../constants/config"
import "../scss/yettel-content.scss"

const ProfilePage = () => {
  const isLoggedIn = isUserLoggedIn();

  const handleLogin = () => {
    logIn();
  }

  const handleLogout = async () => {
    removeUserDataFromLocalStorage();
    await fetch(`${PUBLIC_API}/auth`, { method: "DELETE" });

    if (import.meta.env.PROD === true) {
      window.location.replace(`${NALOG}/app/logout?post_logout_redirect_uri=${window.location.href}`);
    } else {
      window.location.reload();
    }
  }

  return (
    <>
      <Helmet>
        <title>Moji podaci | Yettel Shopping</title>
      </Helmet>
      <FullWidthPageContent>
        <PageTitle className="mb-4">Profil</PageTitle>

        <div className="page-center-wrapper">
          {!isLoggedIn && (
            <>
              Prijavi se kako bi video svoj profil.
              <Button className="mt-4 ms-0 mx-auto" onClick={handleLogin}>Prijavi se</Button>
            </>
          )}

          {isLoggedIn && (
            <>
              <ProfilePageContent />
              <Button secondary className="mt-4 ms-0 mx-auto" onClick={handleLogout}>Odjavi se</Button>
            </>
          )}
        </div>


      </FullWidthPageContent>
    </>
  )
}

export default ProfilePage
