import { User } from "../types/types";
import UserProfileItem from "./UserProfileItem";

interface Props {
  user: User;
}

const UserProfileDisplay: React.FC<Props> = ({ 
  user 
}) => {
  return (
    <section>
      <UserProfileItem label="Ime i prezime" value={user.name || " "} />
      <UserProfileItem label="Pol" value={user.gender === "M" ? "Muški" : "Ženski"} />
      <UserProfileItem label="Godina" value={user.age} />
      <UserProfileItem label="E-mail" value={user.email} />
    </section>
  )
}

export default UserProfileDisplay;