import { useEffect, useRef } from "react";
import close from "../assets/close.svg";
import "../scss/popup-container.scss";
import "../scss/popup-container-wide.scss";

interface Props {
  isActive: boolean;
  closeIcon?: string;
  hasHeader?: boolean;
  setIsActive: (isActive: boolean) => void;
  handlePopupClosure?: () => void;
  children: React.ReactNode;
  baseClassName?: string;
  contentClassName?: string;
  contentStyle?: React.CSSProperties;
}

const PopupContainer: React.FC<Props> = ({
  isActive,
  closeIcon = undefined,
  hasHeader = false,
  setIsActive,
  handlePopupClosure,
  children,
  baseClassName,
  contentClassName,
  contentStyle,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const handleClose: React.MouseEventHandler = (e) => {
    e.stopPropagation();
    setIsActive(false);
    handlePopupClosure && handlePopupClosure();
  }

  const baseClass = baseClassName ?? "popup-container";

  useEffect(() => {
    if(isActive) {
      setTimeout(() => {
        containerRef.current?.classList.add('vertical-scroll');
      }, 300);
    } else {
      containerRef.current?.classList.remove('vertical-scroll');
    }
  }, [isActive]);

  return (
    <div className={baseClass} >
      <div 
        ref={containerRef}
        style={contentStyle} 
        className={`${baseClass}__content ${isActive ? `${baseClass}__content--active` : ""} ${contentClassName ?? ""}`} 
        onClick={(e) => e.stopPropagation()}
      >
        {hasHeader && (
          <div
            style={contentStyle}
            className={`${baseClass}__header`}
            onClick={(e) => e.stopPropagation()}
          >
            <img
              className={`${baseClass}__close cursor-pointer popup-container__close-header__close-img`}
              src={!closeIcon ? close : closeIcon}
              alt="close popup"
              width={25}
              height={25}
              onClick={handleClose}
            />
          </div>
        )}
      
        {!hasHeader && (
          <div
            className="popup-container__close-header"
            style={{background:"white"}}
          >
            <img
              className={`popup-container__close-header__close-img cursor-pointer`}
              src={!closeIcon ? close : closeIcon}
              alt="close popup"
              width={25}
              height={25}
              onClick={handleClose}
            />
          </div>
        )}
        <div className="pt-0 pe-3 ps-3 pb-1">
          {children}
        </div>
      </div>
    </div>
  )
}

export default PopupContainer
