import { useEffect, useState } from 'react';
import useReportError from '../hooks/useReportError';
import Button from './Button';
import ReCAPTCHA from 'react-google-recaptcha';
import { CAPTCHA_SITE_KEY } from '../constants/config';

interface Props {
  error: unknown;
}

const stringifiedError = (error: unknown): string => {
  if (error?.hasOwnProperty('toString')) {
    return (error as any).toString();
  }

  if (error?.hasOwnProperty('message')) {
    return (error as Error).message;
  }

  return typeof error;
};

const ErrorReport: React.FC<Props> = ({ error }) => {
  const [captchaToken, setCaptchaToken] = useState<string>('');
  const errorMessage = stringifiedError(error);
  const [shouldRunReport, setShouldRunReport] = useState(false);
  const [shouldShowCaptcha, setShouldShowCaptcha] = useState(false);

  const { data, isLoading, isFetched, isSuccess, isError } = useReportError(
    shouldRunReport,
    errorMessage,
    captchaToken
  );

  const reportHasBeenTriggerdAndWasSuccessful = isSuccess && !shouldShowCaptcha;

  const reportError = () => {
    setShouldRunReport(true);
  };

  const onChangeCaptcha = (token: string | null) => {
    if (token) {
      setCaptchaToken(token);
    }
  };

  const isButtonDisabled = (): boolean => {
    if (shouldShowCaptcha) {
      return !(captchaToken.length > 0);
    }

    return isLoading;
  };

  useEffect(() => {
    if (isFetched) {
      setShouldRunReport(false);
    }
  }, [isFetched]);

  useEffect(() => {
    if (data) {
      setShouldShowCaptcha(false);
      if (data.statusCode === 429) {
        setShouldShowCaptcha(true);
      }
    }
  }, [data]);

  return (
    <>
      {!reportHasBeenTriggerdAndWasSuccessful && (
        <>
          {/* Raport još nije okinut ili nije prouzrokovao serversku grešku */}
          {!isError && (
            <>
              <h2 className="fz-24 font-yettel mb-4">
                Došlo je do neočekivane greške. Pomozi nam da poboljšamo tvoje iskustvo klikom na dugme Prijavi grešku.
              </h2>

              {shouldShowCaptcha && (
                <div className="d-flex justify-content-center mb-2">
                  <ReCAPTCHA sitekey={CAPTCHA_SITE_KEY} onChange={onChangeCaptcha} hl="sr" />
                </div>
              )}

              <Button disabled={isButtonDisabled()} onClick={reportError}>
                Prijavi grešku
              </Button>
            </>
          )}

          {/* Raport jeste okinut, i došlo je do serverske greške */}
          {isError && (
            <h2 className="fz-24 font-yettel mb-4">
              Prijava greške nije uspela. Molim te osveži aplikaciju i pokušaj kasnije.
            </h2>
          )}
        </>
      )}

      {reportHasBeenTriggerdAndWasSuccessful && (
        <h2 className="fz-24 font-yettel mb-4">
          Greška je prijavljena. Hvala ti na pomoći - potrudićemo se da otklonimo kvar i poboljšamo tvoje iskustvo!
        </h2>
      )}
    </>
  );
};

export default ErrorReport;
