import Sheet from 'react-modal-sheet';
import close from '../assets/images/icons/icon-close-green.png';
import '../scss/yettel-petak-award-modal.scss';

interface Props {
  isPopupOpen: boolean;
  setPopupOpen: (open: boolean) => void;
  text: string;
}

const YettelPetakScratchAward: React.FC<Props> = ({ isPopupOpen, setPopupOpen, text }) => {
  const handleClose = () => {
    setPopupOpen(false);
  };

  return (
    <Sheet isOpen={isPopupOpen} onClose={handleClose} className='award-modal'>
      <Sheet.Container>
        <Sheet.Header>
          <div className="d-flex justify-content-end pt-2">
            <img
              className="cursor-pointer"
              width={25}
              height={25}
              src={close}
              alt="close modal"
              onClick={handleClose}
            />
          </div>
        </Sheet.Header>
        <Sheet.Content>
          <div className="award-text pb-3">
            <p>{text}</p>
          </div>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop onTap={handleClose} />
    </Sheet>
  );
};

export default YettelPetakScratchAward;
