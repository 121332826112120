import { ALPHABET } from "../constants/alphabetLetters";

export const isSpecialCharacter = (char: string) => {
  return !ALPHABET.includes(char);
};

export const modifySearchParamsForId = (
  searchParams: URLSearchParams, 
  selectedIDsString: string, 
  id: string,
  searchParamName: string
): URLSearchParams => {
  const selectedIDsArray = selectedIDsString.split(",").filter(Boolean);
  const selectedIDsMap = new Map(selectedIDsArray.map((id) => [id, true]));

  if (selectedIDsMap.has(id)) {
    selectedIDsMap.delete(id);
  } else {
    selectedIDsMap.set(id, true);
  }

  const newSelectedIDsString = Array.from(selectedIDsMap.keys()).join(",");

  if(!newSelectedIDsString) {
    searchParams.delete(searchParamName);
  } else {
    searchParams.set(searchParamName, newSelectedIDsString);
  }

  return searchParams;
}
