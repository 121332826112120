export const NUMBERS = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

export const ALPHABET = [
  'A',
  'B',
  'C',
  'Č',
  'Ć',
  'D',
  'Dž',
  'Đ',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'Lj',
  'M',
  'N',
  'Nj',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'Š',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
  'Ž'
];

export const CYRYLIC_ALPHABET = [
  'А',
  'Б',
  'В',
  'Г',
  'Д',
  'Ђ',
  'Е',
  'Ж',
  'З',
  'И',
  'Ј',
  'К',
  'Л',
  'Љ',
  'М',
  'Н',
  'Њ',
  'О',
  'П',
  'Р',
  'С',
  'Т',
  'Ћ',
  'У',
  'Ф',
  'Х',
  'Ц',
  'Ч',
  'Џ',
  'Ш'
];
