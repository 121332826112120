import { Location } from "../types/types"
import "../scss/location-switch.scss"

interface Props {
  location: Location;
  handleClick: (location: Location) => void;
  selectedLocations: string[];
}

const LocationSwitch: React.FC<Props> = ({
  handleClick,
  location: locationData,
  selectedLocations
}) => {

  const isActive = selectedLocations?.includes(locationData.location_group) || false;

  const src = isActive ?
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAsCAYAAACKYvPzAAAABHNCSVQICAgIfAhkiAAAARVJREFUSEtjZFC2/89AZcA4auhomFI3TY0mqdFsSt0UxTDMk5SBpgrD/qUTGBau28FQ0DIFI/DI8v6FzXMY9IEGN05awNAAxOiAZEMb8hIY6oH44dMXDAY+KQwfPn+hzFCQt88DXQkCjtEFDAdOXsCaboh2qQAvDzgcDbRUGCYuWIM1LGE2YDV0Qk0OQ3yQB9g1F67fAasFieUnhDBcBPINfFPwpmyshsLC7cOnL2CDBfggrgQBQ6CBMItwmYzT+wu6KsCu/Qg0+Ov3HwxS4iI4Y5uk2IcZDNJ0/c5DBi2PeKIyNMGIurZjIYOmijyDd3I5w7aDJ6ljKFGmoCki6NJRQ0kOgdEwHa33SU40+DWMJqkhkqQAB1aN2aysaHAAAAAASUVORK5CYII=" :
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAsCAYAAACKYvPzAAAABHNCSVQICAgIfAhkiAAAANFJREFUSEvtld0KhCAQhY/Sj/r+bxoZFuYyghC7Kdq6N8sMeJGM3zhnTiWWZQnoHIKhrGlfT7Gl+DXt6yj8uaWklDDG4DgOOOc+xHvUPgEJvO97XO/RDJ2mCbRCCFjX9XbETdDUNpG2bYP3/ntoajunZapwe9N5njGOI6y1OM8z5qY9eqb9UtxCk250kABCCGitI+daKAfOaqqUwjAM8RwNhcC5aTdN/wquabuo6bVyGk5p2k03ffqdafJpbRGG8o+v1iuVeWwptlSlVWrTfmKpFzP48U1kJjI4AAAAAElFTkSuQmCC"

  return (
    <div
      onClick={() => handleClick(locationData)}
      className="location-switch d-flex justify-content-between align-items-center"
    >
      <span>
        {locationData.location_group.replace(" - sve opštine", "")}
      </span>
      <img width={21} height={44} alt="checkmark" src={src} />
    </div>
  )
}

export default LocationSwitch;
