import { NavLink, useMatch } from 'react-router-dom';
import SearchBarTrigger from './SearchBarTrigger';
import LocationListContainer from './LocationListContainer';
import '../scss/yettel-header.scss';

interface Props {
  navigateToHomepage: Function;
}

const HeaderDesktop: React.FC<Props> = ({ navigateToHomepage }) => {
  const isHomePage = useMatch('/');

  return (
    <header className={`yettel-header bg-primary-color ${!isHomePage && 'mb-5'}`}>
      <div className="yettel-header__container container d-flex align-items-center">
        <div className="yettel-header__logo-container">
          <div className="cursor-pointer" onClick={() => navigateToHomepage()}>
            <span className="d-block color-secondary-color font-yettel-bold">Y. Shopping</span>
          </div>
        </div>

        <nav>
          <ul className="yettel-header__navigation d-flex">
            <li>
              <NavLink className={({ isActive }) => (isActive ? 'active' : '')} to="/">
                Pregled
              </NavLink>
            </li>
            <li>
              <NavLink className={({ isActive }) => (isActive ? 'active' : '')} to="/near-by">
                Mapa
              </NavLink>
            </li>
            <li>
              <NavLink className={({ isActive }) => (isActive ? 'active' : '')} to="/kuponi">
                Moji kuponi
              </NavLink>
            </li>
            <li>
              <NavLink className={({ isActive }) => (isActive ? 'active' : '')} to="/yettel-petak">
                Y. Petak
              </NavLink>
            </li>
            <li>
              <NavLink className={({ isActive }) => (isActive ? 'active' : '')} to="/profil">
                Profil
              </NavLink>
            </li>
          </ul>
        </nav>

        <div className="yettel-header__searchbar-container">
          {isHomePage && <LocationListContainer />}
          <SearchBarTrigger />
        </div>
      </div>
    </header>
  );
};

export default HeaderDesktop;
