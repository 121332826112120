import { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Helmet } from 'react-helmet';
import Slider, { Settings } from 'react-slick';
import { CodeDisplayData, OfferSaved, SingleOffer, StoreLocationType, YSH_GTM_EVENTS } from '../types/types';
import OfferSection from './OfferSection';
import OfferLocationAndContact from './OfferLocationAndContact';
import { IMAGE_BASE_URL } from '../constants/config';
import ShareOfferLink from './ShareOfferLink';
import ExpirationInfo from './ExpirationInfo';
import Button from './Button';
import { callPublicAPI } from '../utils/api';
import { Link, useSearchParams } from 'react-router-dom';
import { QUERY_KEYS } from '../constants/queryKeys';
import { SEARCH_PARAMS } from '../constants/searchParamNames';
import CodeDisplay from './CodeDisplay';
import { mapSingleOfferToAnalyticsObject, pushToAnalytics } from '../utils/analytics';
import { isUserLoggedIn } from '../utils/auth';
import { CODE_SPENT, EXPIRED, STOPPED } from '../constants/offerStatuses';
import SHOPPING_CART_ICON from '../assets/images/icons/offer/cart.png';
import CLOCK_ICON from '../assets/images/icons/offer/clock.png';
import DOWNLOAD_ICON from '../assets/images/icons/offer/download.png';
import CouponsUsedCount from './CouponsUsedCount';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../scss/offer-overlay-content.scss';

interface Props {
  offer: SingleOffer;
}

const SLICK_SETTINGS: Settings = {
  arrows: false,
  dots: true,
  infinite: true,
  autoplay: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  className: 'offer-overlay-content__slider',
  dotsClass: 'offer-overlay-content__slider-dots'
};

const OfferDescriptionTitle: React.FC<{ url: string }> = ({ url }) => {
  return (
    <div className="d-flex align-items-center justify-content-between">
      <span>Opis ponude</span>
      {url && (
        <a href={url} target="_blank" rel="noreferrer" className="text-decoration-underline">
          Link ka ponudi
        </a>
      )}
    </div>
  );
};

const OfferOverlayContent: React.FC<Props> = ({ offer }) => {
  const isLoggedIn = isUserLoggedIn();
  const displayCouponExpiration = Boolean(isLoggedIn && offer.code);
  const [isCouponSaving, setIsCouponSaving] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const images = [offer.image0, offer.image1, offer.image2, offer.image3].filter(Boolean);
  const isCouponActive = offer.status !== CODE_SPENT && offer.status !== EXPIRED && offer.status !== STOPPED;

  const handleSaveOfferSuccess = async () => {
    await queryClient.invalidateQueries({
      predicate: query =>
        query.queryKey[0] === QUERY_KEYS.GET_OFFERS ||
        (query.queryKey[0] === QUERY_KEYS.GET_OFFER && query.queryKey[1] === offer.id)
    });
    setSearchParams(searchParams);
    pushToAnalytics(YSH_GTM_EVENTS.DOWNLOAD_COUPON, {
      coupon_details: [mapSingleOfferToAnalyticsObject(offer, 'Coupon details')]
    });
    setIsCouponSaving(false);
  };

  const { data: codeData } = useQuery<CodeDisplayData>({
    queryKey: [QUERY_KEYS.GET_OFFER_CODE, offer.code],
    queryFn: async () => {
      const response = await callPublicAPI<CodeDisplayData>('GET', `/offers/${offer.id}/code`);
      return response.data;
    },
    enabled: isLoggedIn && Boolean(offer?.code)
  });

  const queryClient = useQueryClient();
  const saveOffer = useMutation<OfferSaved>({
    mutationFn: async () => {
      const response = await callPublicAPI<OfferSaved>('PUT', `/offers/${offer.id}/code`);
      return response.data;
    },
    onSuccess: handleSaveOfferSuccess,
    onError: () => {
      alert('Žao nam je, nismo uspeli da sačuvamo kupon');
    }
  });

  const handleContainerClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  const handleSaveOfferClick = () => {
    if (isUserLoggedIn()) {
      setIsCouponSaving(true);
      setTimeout(() => {
        saveOffer.mutate();
      }, 350);
    } else {
      searchParams.set(SEARCH_PARAMS.PROMPT_LOGIN, 'true');
      setSearchParams(searchParams);
      pushToAnalytics(YSH_GTM_EVENTS.DOWNLOAD_COUPON_LOGIN_REMINDER, {
        coupon_details: [mapSingleOfferToAnalyticsObject(offer, 'Coupon details')]
      });
    }
  };

  const getStoreLocationTypeText = (storeLocationType: StoreLocationType, url: string) => {
    switch (storeLocationType) {
    case 'all':
    case 'online-sel':
      return (
        <span>
            Kupovina{' '}
          <Link className="text-decoration-underline" to={url} target="_blank">
              online
          </Link>{' '}
            i u prodavnici
        </span>
      );
    case 'online':
      return (
        <span>
            Kupovina{' '}
          <Link className="text-decoration-underline" to={url} target="_blank">
              online
          </Link>
        </span>
      );
    case 'offline':
    case 'selected':
      return <span>Kupovina u prodavnici</span>;
    default:
      return null;
    }
  };

  const pushUseCouponToAnalytics = () => {
    pushToAnalytics(YSH_GTM_EVENTS.USE_THE_COUPON_ONLINE, {
      coupon_details: [mapSingleOfferToAnalyticsObject(offer, 'Coupon details')]
    });
  };

  const showVoucherImage = codeData?.qr || codeData?.barcode;

  useEffect(() => {
    pushToAnalytics(YSH_GTM_EVENTS.COUPON_DETAILS, {
      coupon_details: [mapSingleOfferToAnalyticsObject(offer, 'Coupon details')]
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {offer.name} | {offer.partner.name} | Kuponi | Yettel Shopping
        </title>
      </Helmet>
      <div className="offer-overlay-content-container font-yettel-light" onClick={handleContainerClick}>
        <OfferSection>
          <Slider {...SLICK_SETTINGS}>
            {images.map(image => (
              <img key={image} src={`${IMAGE_BASE_URL}/${image}`} />
            ))}
          </Slider>
          {Boolean(images.length) && <div className="offer-overlay-content__slider-dots"></div>}
        </OfferSection>

        <OfferSection className="offer-overlay-content__coupon font-yettel position-relative">
          <div style={{ padding: '10px 85px 0 40px' }}>
            <h3 className="fz-16 mb-1">{offer.partner.name}</h3>
            <p className="fz-16 mb-5">{offer.name}</p>
          </div>

          {isLoggedIn && offer.code && (
            <section className="mt-1 mb-1 ms-4 me-4">
              <CodeDisplay
                code={offer.code}
                url={offer.url}
                pushUseCouponToAnalytics={pushUseCouponToAnalytics}
                showCopyButton={['all', 'online', 'online-sel'].includes(offer.store_location_type)}
              />
            </section>
          )}

          <div className="offer-overlay-content__coupon__rip" />
          {isCouponActive && <ShareOfferLink />}
          {!offer.code &&
            isCouponActive &&
            (isCouponSaving ? (
              <span className="fs-20 offer-overlay-content__coupon__code--copied me-4 ms-4">Kupon sačuvan</span>
            ) : (
              <Button className="mb-3" onClick={handleSaveOfferClick}>
                <span className="d-block pt-2 pb-2 ps-3 pe-3">Sačuvaj kupon</span>
              </Button>
            ))}
          {offer.status === CODE_SPENT && (
            <p className="orange-text d-block pt-2 pb-2 ps-3 pe-3">Svi kuponi su preuzeti</p>
          )}
          {offer.status === EXPIRED && <p className="orange-text d-block pt-2 pb-2 ps-3 pe-3">Kupon je istekao</p>}
          {offer.status === STOPPED && <p className="orange-text d-block pt-2 pb-2 ps-3 pe-3">Ponuda je istekla.</p>}
        </OfferSection>

        {showVoucherImage && (
          <section className="pt-3 pb-3 text-center" style={{ background: 'white' }}>
            {codeData?.qr && (
              <div>
                <img src={`data:image/webp;base64,${codeData.qr}`} alt="qr code" />
              </div>
            )}
            <br />
            {codeData?.barcode && (
              <div>
                <img src={`data:image/webp;base64,${codeData.barcode}`} alt="barcode" />
              </div>
            )}
            <br />
          </section>
        )}

        <OfferSection className="offer-info">
          <div className="d-flex align-items-center mb-2">
            <img src={SHOPPING_CART_ICON} height={15} width={15} alt="icon" />
            <span className="ms-1 font-yettel-light">
              {getStoreLocationTypeText(offer.store_location_type, offer.url)}
            </span>
          </div>

          {Boolean(offer.used_code_count) && (
            <div className="d-flex align-items-center mb-2">
              <img src={DOWNLOAD_ICON} height={15} width={15} alt="icon" />
              <span className="ms-1 font-yettel-light">
                <CouponsUsedCount usedCount={offer.used_code_count} isOfferOverlay={true} />
              </span>
            </div>
          )}

          <div className="d-flex align-items-center mb-2">
            <img src={CLOCK_ICON} height={15} width={15} alt="icon" />
            <ExpirationInfo offer={offer} lookForExpirationOf={displayCouponExpiration ? 'coupon' : 'offer'} />
          </div>
        </OfferSection>

        <OfferSection title={<OfferDescriptionTitle url={offer.url} />}>
          <div dangerouslySetInnerHTML={{ __html: offer.description }}></div>
        </OfferSection>

        <OfferSection title="Uslovi ponude">
          <div dangerouslySetInnerHTML={{ __html: offer.conditions }}></div>
        </OfferSection>

        <OfferSection title="Lokacija i kontakt">
          <OfferLocationAndContact offer={offer} />
        </OfferSection>
      </div>
    </>
  );
};

export default OfferOverlayContent;
