import { Link } from "react-router-dom";
import { BsChevronRight } from "react-icons/bs";
import "../scss/show-all-offers-link.scss";

interface Props {
  sortType?: "discount" | "popular" | "latest" | "expiration";
  recommended?: string;
}

const ShowAllOffersLink: React.FC<Props> = ({
  sortType,
  recommended
}) => {
  const sortTypeParams = `sort=${sortType}`;
  const recommendedParams = "recommended=1"; 
  
  const params = Boolean(recommended) ? recommendedParams : sortTypeParams; 
  
  return (
    <Link to={`/ponude?${params}`} className="offer mb-2 show-all-offers-link">
      <div className="show-all-offers-container">
        <p className="font-yettel-bold mb-2">Pogledaj sve</p>
        <div className="show-all-offers__button">
          <div className="show-all-offers__button__inner">
            <BsChevronRight />
          </div>
        </div>
      </div>
    </Link>
  )
}

export default ShowAllOffersLink;
