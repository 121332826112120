import { useSearchParams } from "react-router-dom";

const useDynamicParamsLink = (paramName?: string, paramValue?: string) => {
  const [searchParams] = useSearchParams();
  const searchParamsExist = searchParams.toString() ?? "";

  if(!paramName || !paramValue) {
    return searchParamsExist ? `?${searchParams.toString()}` : "";
  }

  if(!searchParamsExist) {
    return `?${paramName}=${paramValue}`;
  }

  const searchParamIsAlreadyInUse = searchParamsExist && searchParams.has(paramName);

  if(searchParamIsAlreadyInUse) {
    searchParams.delete(paramName);
  }

  return `?${searchParams.toString()}&${paramName}=${paramValue}`;
}

export default useDynamicParamsLink;
