import "../scss/content-container.scss";

interface Props {
  children: React.ReactNode;
}

const ContentContainer: React.FC<Props> = ({
  children
}) => {
  return (
    <section className="content-container">
      {children}
    </section>
  )
}

export default ContentContainer;