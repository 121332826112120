export interface Option {
  value: string;
  label: string;
}

export const options: Option[] = [
  { value: "online", label: "Samo online" },
  { value: "offline", label: "Samo prodavnice" },
  { value: "all", label: "Prodavnice i online" },
];
