import { useQuery } from '@tanstack/react-query';
import { LOCAL_STORAGE_KEYS } from '../constants/localStorageKeys';
import { removeUserDataFromLocalStorage } from '../utils/auth';
import { GetRefreshTokenRequestData, Tokens } from '../types/types';
import { QUERY_KEYS } from '../constants/queryKeys';
import { callNalogAPI } from '../utils/api';
import { useSearchParams } from 'react-router-dom';
import { SEARCH_PARAMS } from '../constants/searchParamNames';

const useFetchTokensFromRefreshToken = (refreshToken: string) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { isError } = useQuery<Tokens>({
    queryKey: [QUERY_KEYS.GET_TOKENS_FROM_REFRESH_TOKEN, refreshToken],
    queryFn: async () => {
      const data: GetRefreshTokenRequestData = {
        client_id: 'market',
        grant_type: 'refresh_token',
        refresh_token: refreshToken
      };

      const response = await callNalogAPI<Tokens>('POST', '/token', data);

      const { access_token, refresh_token } = response;

      if (access_token && refresh_token) {
        localStorage.setItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, access_token);
        localStorage.setItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN, refresh_token);

        if (searchParams.has(SEARCH_PARAMS.TOKEN)) {
          searchParams.delete(SEARCH_PARAMS.TOKEN);
          setSearchParams(searchParams);
        }

        window.location.reload();
      }

      return response;
    },
    retry: 3,
    throwOnError: (_, __) => {
      removeUserDataFromLocalStorage();
      return false;
    }
  });

  return {
    isError
  };
};

export default useFetchTokensFromRefreshToken;
