import { InfiniteData, InfiniteQueryObserverResult } from "@tanstack/react-query";
import { ApiResponse } from "../types/types";

type GetNextPageParamHelper = <T>(lastPage: ApiResponse<T>, allPages: ApiResponse<T>[], pageSize: number) => number | undefined;

export const getNextPageParamHelper: GetNextPageParamHelper = (lastPage, allPages, pageSize) => { 
  const maxPages = Math.ceil((lastPage?.meta?.total || pageSize) / pageSize);
  const nextPage = allPages.length + 1;
  return nextPage <= maxPages ? nextPage : undefined;
};

type HandleHorizontalScrollHelper = <T>(
  event: React.UIEvent<HTMLDivElement, UIEvent>, 
  fetchNextPage: () => Promise<InfiniteQueryObserverResult<InfiniteData<T>>>, 
  isFetching: boolean
) => Promise<void>;

export const handleHorizontalScrollHelper: HandleHorizontalScrollHelper = async (event, fetchNextPage, isFetching) => {
  if (event.target instanceof HTMLElement) {
    const { scrollWidth, scrollLeft, clientWidth } = event.target as Element;

    if (!isFetching && scrollWidth - scrollLeft <= clientWidth * 1.5) {
      await fetchNextPage();
    }
  }
}
