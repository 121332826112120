import Kancelarija from "../assets/maps/pins/categoryScaledPins/Kancelarija.png";
import AutoMoto from "../assets/maps/pins/categoryScaledPins/Auto moto.png";
import TehnickaRoba from "../assets/maps/pins/categoryScaledPins/Tehnička roba.png";
import DecijiSvet from "../assets/maps/pins/categoryScaledPins/Dečiji svet.png";
import HranaIPice from "../assets/maps/pins/categoryScaledPins/Hrana i piće.png";
import Knjizara from "../assets/maps/pins/categoryScaledPins/Knjižara.png";
import KucniLjubimci from "../assets/maps/pins/categoryScaledPins/Kućni ljubimci.png";
import LepotaINega from "../assets/maps/pins/categoryScaledPins/Lepota i nega.png";
import LepotaIZdravlje from "../assets/maps/pins/categoryScaledPins/Lepota i zdravlje.png";
import Moda from "../assets/maps/pins/categoryScaledPins/Moda.png";
import Obrazovanje from "../assets/maps/pins/categoryScaledPins/Obrazovanje.png";
import Sezona from "../assets/maps/pins/categoryScaledPins/Sezona.png";
import SportIRekreacija from "../assets/maps/pins/categoryScaledPins/Sport i rekreacija.png";
import SveZaKucu from "../assets/maps/pins/categoryScaledPins/Sve za kuću.png";
import Turizam from "../assets/maps/pins/categoryScaledPins/Turizam.png";
import Usluge from "../assets/maps/pins/categoryScaledPins/Usluge.png";
import Yettel from "../assets/maps/pins/categoryScaledPins/Yettel.png";
import ZabavaIKarte from "../assets/maps/pins/categoryScaledPins/Zabava i karte.png";
import Zdravlje from "../assets/maps/pins/categoryScaledPins/Zdravlje.png";

export const MarkerEnums = {
  'Auto-moto': AutoMoto,
  'Dečiji svet': DecijiSvet,
  'Hrana i piće': HranaIPice,
  'Kancelarija': Kancelarija,
  'Knjižara': Knjizara,
  'Kućni ljubimci': KucniLjubimci,
  'Lepota i nega': LepotaINega,
  'Lepota i zdravlje': LepotaIZdravlje,
  'Moda': Moda,
  'Obrazovanje': Obrazovanje,
  'Obrazovanje i knjige': Obrazovanje,
  'Sezona': Sezona,
  'Sport i rekreacija': SportIRekreacija,
  'Sve za kuću': SveZaKucu,
  'Tehnička roba': TehnickaRoba,
  'Turizam': Turizam,
  'Usluge': Usluge,
  'Yettel': Yettel,
  'Zabava i karte': ZabavaIKarte,
  'Zdravlje': Zdravlje,
} as const;
