import PageTitle from "./PageTitle";
import UserProfile from "./UserProfile";
import UserSelectedCategoriesView from "./UserSelectedCategories";

const ProfileSettings = () => {
  return (
    <>
      <PageTitle className="mb-4">Moji podaci i podešavanja</PageTitle>

      <UserProfile />
      <UserSelectedCategoriesView />
    </>
  );
}

export default ProfileSettings