import checkedImage from "../assets/checked.png";
import uncheckedImage from "../assets/unchecked.png";
import { SEARCH_PARAMS_FILTERS } from "../constants/searchParamsFilters";
import { useSearchParams } from "react-router-dom";
import "../scss/filter-list-container.scss";
import "../scss/filter-list-checkbox.scss";

const FilterTypeOffer: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const recommended = searchParams.get(SEARCH_PARAMS_FILTERS.RECOMMENDED_PARAM) || "";

  const handleClick = () => {
    if(recommended === "1") {
      searchParams.delete(SEARCH_PARAMS_FILTERS.RECOMMENDED_PARAM);
    } else {
      searchParams.set(SEARCH_PARAMS_FILTERS.RECOMMENDED_PARAM, "1");
    }

    setSearchParams(searchParams);
  };

  return (
    <>
      <div className="filter-list__name font-yettel-bold">Tip ponude</div>
      <div className={"pt-2 pb-2 filter-list__checkbox-item"} onClick={handleClick}>
        <div className="filter-list__checkbox-item__box">
          <img width={20} height={20} alt="checkmark" src={recommended ==="1" ? checkedImage : uncheckedImage} />
          <div className="filter-list__checkbox-item__name d-inline-block">Preporuke</div>
        </div>
      </div>
    </>
  );
};

export default FilterTypeOffer;
