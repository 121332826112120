import { useSearchParams } from "react-router-dom";
import { useFetchCategories } from "../hooks/useFetchCategories";
import { useShoppingMallsQuery } from "../hooks/useFetchShoppingMalls";
import { useFetchPartners } from "../hooks/useFetchPartners";
import { SEARCH_PARAMS_FILTERS } from "../constants/searchParamsFilters";
import SelectedFilterButton from "./SelectedFilterButton ";
import { modifySearchParamsForId } from "../utils/filters";
import { Option, options } from "../constants/sortOptions";
import CouponRecap from "./CouponRecap";
import FilterBox from "./FilterBox";
import { SEARCH_PARAMS } from "../constants/searchParamNames";
import OffersPageSearchResultsFor from "./OffersPageSearchResultsFor";
import "../scss/offers-with-filters.scss";

interface Props {
  setIsPopupOpen: (active: boolean) => void;
  totalNumber: number;
}

const FilterSection: React.FC<Props> = ({
  setIsPopupOpen, 
  totalNumber
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const word = searchParams.get(SEARCH_PARAMS.WORD) || undefined;

  const { data: categories } = useFetchCategories();
  const { data: shoppingMalls } = useShoppingMallsQuery();
  const { data: partnersList } = useFetchPartners();
  const partners = partnersList ? partnersList && Object.values(partnersList).flat() : [];

  const selectedCategories = searchParams.get(SEARCH_PARAMS_FILTERS.CATEGORY_PARAM)?.split(",").filter(Boolean) || [];
  const selectedShoppingMalls = searchParams.get(SEARCH_PARAMS_FILTERS.SHOPPING_MALL_PARAM)?.split(",").filter(Boolean) || [];
  const selectedPartners = searchParams.get(SEARCH_PARAMS_FILTERS.PARTNER_PARAM)?.split(",").filter(Boolean) || [];

  const recommended = searchParams.get(SEARCH_PARAMS_FILTERS.RECOMMENDED_PARAM) || "";
  const store = searchParams.get(SEARCH_PARAMS_FILTERS.STORE_PARAM) || "";

  const triggerSearch = () => {
    searchParams.set(SEARCH_PARAMS.SEARCH, 'true');
    setSearchParams(searchParams);
  };

  return (
    <>
      {word && (
        <OffersPageSearchResultsFor forWord={word} triggerSearch={triggerSearch} />
      )}
      
      <div>
        {categories &&
            selectedCategories?.map((id) => (
              <SelectedFilterButton
                key={id}
                name={categories.find((category) => category.id === id)?.name}
                handleOnClick={() => {
                  const alteredSearchParams = modifySearchParamsForId(searchParams, selectedCategories.join(","), id, SEARCH_PARAMS_FILTERS.CATEGORY_PARAM);
                  setSearchParams(alteredSearchParams);
                }}
              />
            ))}

        {shoppingMalls &&
            selectedShoppingMalls?.map((id) => (
              <SelectedFilterButton
                key={id}
                name={shoppingMalls.find((shoppingMall) => shoppingMall.id === id)?.name}
                handleOnClick={() => {
                  const alteredSearchParams = modifySearchParamsForId(searchParams, selectedShoppingMalls.join(","), id, SEARCH_PARAMS_FILTERS.SHOPPING_MALL_PARAM);
                  setSearchParams(alteredSearchParams);
                }}
              />
            ))}

        {Boolean(partners) &&
            selectedPartners?.map((id) => (
              <SelectedFilterButton
                key={id}
                name={partners.find((partner) => partner.id === id)?.name}
                handleOnClick={() => {
                  const alteredSearchParams = modifySearchParamsForId(searchParams, selectedPartners.join(","), id, SEARCH_PARAMS_FILTERS.PARTNER_PARAM);
                  setSearchParams(alteredSearchParams);
                }}
              />
            ))}

        {recommended === "1" && (
          <SelectedFilterButton
            key="recommended"
            name="Preporuka"
            handleOnClick={() => {
              searchParams.delete(SEARCH_PARAMS_FILTERS.RECOMMENDED_PARAM);
              setSearchParams(searchParams);
            }}
          />
        )}

        {options.map(
          (option: Option) =>
            store === option.value && (
              <SelectedFilterButton
                key="store"
                name={option.label}
                handleOnClick={() => {
                  searchParams.delete(SEARCH_PARAMS_FILTERS.STORE_PARAM);
                  setSearchParams(searchParams);
                }}
              />
            )
        )}
      </div>

      <div className="d-flex justify-content-between align-items-center">
        <CouponRecap totalNumber={totalNumber} />
        <FilterBox setIsPopupOpen={setIsPopupOpen} />
      </div>

    </>
  );
};

export default FilterSection;
