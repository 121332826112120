import Sheet from 'react-modal-sheet';
import close from '../assets/images/icons/icon-close.svg';
import FilterCategory from './FilterCategory';
import FilterPartner from './FilterPartner';
import { useNavigate, useSearchParams } from 'react-router-dom';

const MapCategoryAndPartnerPickerOverlay = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const handleConfirm = () => {
    navigate(`/near-by?${searchParams.toString()}`, { replace: true });
  };

  const handleClosePopup = () => {
    handleConfirm();
  };

  return (
    <>
      <Sheet isOpen={true} onClose={handleClosePopup} className="map-category-picker">
        <Sheet.Container>
          <Sheet.Header>
            <div className="d-flex justify-content-end ps-3 pe-3 pt-3">
              <img
                className="cursor-pointer"
                width={25}
                height={25}
                src={close}
                alt="close modal"
                onClick={handleClosePopup}
              />
            </div>
          </Sheet.Header>
          <Sheet.Content>
            <div className="ms-2 me-2">
              <FilterCategory />
              <FilterPartner />
            </div>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={handleClosePopup} />
      </Sheet>
    </>
  );
};

export default MapCategoryAndPartnerPickerOverlay;
