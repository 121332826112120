import { Location } from '../types/types';
import ErrorMessage from './ErrorMessage';
import LocationSwitch from './LocationSwitch';
import Button from './Button';
import '../scss/location-list.scss';

interface Props {
  handleLocationClick: (location: Location) => void;
  selectedLocations: string[];
  handleSubmit: () => void;
  error: Error | null;
  allAvailableLocations?: Location[];
}

const LocationList: React.FC<Props> = ({
  handleSubmit,
  handleLocationClick,
  selectedLocations,
  error,
  allAvailableLocations
}) => {
  return (
    <>
      <div className="location-list mt-5">
        {error && <ErrorMessage>{error.message}</ErrorMessage>}
        {allAvailableLocations &&
          formatLocationsList(allAvailableLocations).map(location => (
            <LocationSwitch
              key={location.location_group}
              location={location}
              handleClick={handleLocationClick}
              selectedLocations={selectedLocations}
            />
          ))}
      </div>
      <div className="d-flex justify-content-center full-width mt-5 mb-5">
        <Button onClick={handleSubmit}>Prikaži ponude</Button>
      </div>
    </>
  );
};

function formatLocationsList(locations: Location[] = []) {
  const locationsWithSveOpstine = locations.filter(location => location.location_group.includes('sve opštine'));
  const shavedLocationsWithSveOpstine = locationsWithSveOpstine.map(location => {
    const shavedLocation = location.location_group.replace(' - sve opštine', '');
    return {
      ...location,
      location_group: shavedLocation
    };
  });

  const uniqueLocationsWithSveOpstine = new Set(shavedLocationsWithSveOpstine.map(location => location.location_group));

  const formattedLocations = locations.filter(
    location => uniqueLocationsWithSveOpstine.has(location.location_group.replace(' - sve opštine', '')) === false
  );

  uniqueLocationsWithSveOpstine.forEach(location => {
    formattedLocations.push({
      location_group: `${location} - sve opštine`,
      location_group_locative: null
    });
  });

  return formattedLocations.sort((a, b) => a.location_group.localeCompare(b.location_group));
}

export default LocationList;
