import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../constants/queryKeys";
import { callPublicAPI } from "../utils/api";
import UserSelectedCategoryList from "./UserSelectedCategoryList";
import UserProfileSectionTitle from "./UserProfileSectionTitle";

const UserSelectedCategoriesView = () => {
  const { data } = useQuery<string[]>({
    queryKey: [QUERY_KEYS.GET_USER_SELECTED_CATEGORY_IDS],
    queryFn: async () => {
      const searchParams = new URLSearchParams({
        limit: "100",
      });

      const response = await callPublicAPI<string[]>("GET", "/user-selected-categories", searchParams);
      return response.data;
    }
  });

  return (
    <section className="mb-5">
      <UserProfileSectionTitle>Omiljene kategorije</UserProfileSectionTitle>
      <p className="font-yettel-light mb-2">Oglasi iz izabranih kategorija ce se pojaviti prvi na listi oglasa naslovne strane.</p>

      {data && (
        <UserSelectedCategoryList selectedCategoryIds={data} />
      )}
    </section>
  )
}

export default UserSelectedCategoriesView;