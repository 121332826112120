import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import BiggestDiscountList from './BiggestDiscountList';
import CommercialBannerList from './CommercialBannerList';
import MostPopularList from './MostPopularList';
import NewestOffersList from './NewestOffersList';
import RecommendedOffersList from './RecommendedOffersList';
import ShoppingMallsList from './ShoppingMallsList';

const HomePage = () => {
  useEffect(() => {
    const yettelContent = document.querySelector('.yettel-content');
    yettelContent?.classList.add('yettel-content--no-top-padding-mobile');
    return () => {
      yettelContent?.classList.remove('yettel-content--no-top-padding-mobile');
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Yettel Shopping | Kuponi | Kupuj Najnovije i Uštedi | Najveći Popusti do 70%</title>
      </Helmet>

      <RecommendedOffersList />
      <BiggestDiscountList />

      <ShoppingMallsList />

      <MostPopularList />

      <CommercialBannerList />
      <NewestOffersList />
    </>
  );
};

export default HomePage;
