import { Cluster, Renderer } from "@googlemaps/markerclusterer";

class StoresMarkerClustererCustomRenderer implements Renderer {

  public render(
    { count, position }: Cluster
  ): google.maps.Marker {
    const svg = window.btoa(`
        <svg fill="#00233f" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
        <circle cx="120" cy="120" opacity=".8" r="70" />    
        </svg>`
    );

    return new google.maps.Marker({
      position,
      icon: {
        url: `data:image/svg+xml;base64,${svg}`,
        scaledSize: new google.maps.Size(75, 75),
      },
      label: {
        text: String(count),
        color: "rgba(255,255,255,0.9)",
        fontSize: "12px",
      },
      // adjust zIndex to be above other markers
      zIndex: count,
    });
  }
}

export default StoresMarkerClustererCustomRenderer;